import moment from 'moment';
import Tags from '../../../../Tags/Tags';
import { IGroup } from '../../../../../store/modules/Digital/Groups/Groups.types';
import useAppSelector from '../../../../../hooks/useAppSelector';
import './FiltersReview.scss';

interface IFiltersReviewProps {
  group: IGroup;
}

const FiltersReview = ({ group }: IFiltersReviewProps) => {
  const { simulation } = useAppSelector((state) => state);

  return (
    <>
      <div className="filters__wrapp">
        <p className="filters__label">Início:</p>
        <p className="filters__label-content">
          {moment(simulation?.DigitalDates?.startdate).format('DD/MM/YYYY')}
        </p>
        <p className="filters__label">Fim:</p>
        <p className="filters__label-content">
          {moment(simulation?.DigitalDates?.EndDate).format('DD/MM/YYYY')}
        </p>
      </div>
      <div className="filters__wrapp">
        <p className="filters__label">Localização:</p>
        <Tags options={group.data?.filter.data?.locations || []} />
      </div>
      <div className="filters__wrapp">
        <p className="filters__label">Gênero:</p>
        <p className="filters__label-content">
          {group.data?.filter.data?.gender?.label}
        </p>
        <p className="filters__label">Faixa Etária:</p>
        <p className="filters__label-content">
          <Tags options={group.data?.filter.data?.age || []} />
        </p>
      </div>
      <div className="filters__wrapp">
        <p className="filters__label">Interesses:</p>
        <Tags options={group.data?.filter.data?.interests || []} />
      </div>
    </>
  );
};

export default FiltersReview;
