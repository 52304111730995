import React, {
  useEffect,
  useState,
  useRef,
  memo,
  useMemo,
  useCallback,
  HTMLAttributes
} from 'react';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteChangeReason
} from '@mui/material/Autocomplete';
import { InputLabelProps } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useField } from '@unform/core';
import Checkbox, { CheckboxClasses } from '@mui/material/Checkbox';
import has from 'lodash/has';
import { cloneDeep } from 'lodash';
import ListItemText from '@mui/material/ListItemText';
import {
  attributeMaskOrUnmask,
  classMaskOrUnmask
} from '../../../helpers/Utils';

import './Listbox.scss';

type AutocompleteModifiedType = Omit<
  AutocompleteProps<any, true, true, any>,
  'renderInput' | 'onChange'
>;

interface Props extends AutocompleteModifiedType {
  dataClarityMask?: boolean;
  name: string;
  label: string;
  maxItems?: number;
  defaultValue?: any[] | any;
  fixedValue?: any;
  required?: boolean;
  visible?: boolean;
  loading?: boolean;
  returnObject?: boolean;
  labelProp?: string;
  valueProp?: string;
  disableValidateValInOpts?: boolean;
  checkboxClasses?: Partial<CheckboxClasses>;
  placeholderInput?: string;
  onChange?(
    newValue: any | any[] | null,
    reason: AutocompleteChangeReason
  ): void; // useCallback -> use hook to not generate circle rendering
  onClearError?(name: string): void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  inputLabelProps?: Partial<InputLabelProps>;
  maxDisplay?: number;
  disableClearable?: true | undefined;
}

const AutocompleteInput = ({
  name,
  label,
  maxItems,
  options,
  multiple,
  noOptionsText,
  defaultValue,
  renderInput,
  onChange,
  required,
  returnObject,
  labelProp = 'label',
  valueProp = 'value',
  loading,
  disableCloseOnSelect,
  onClearError,
  dataClarityMask,
  classes,
  visible = true,
  fixedValue,
  disableValidateValInOpts,
  checkboxClasses,
  placeholderInput,
  inputLabelProps,
  disableClearable,
  maxDisplay,
  externalError,
  mainValue,
  ...rest
}: Props & {
  // eslint-disable-next-line react/require-default-props
  externalError?: string | undefined;
  // eslint-disable-next-line react/require-default-props
  mainValue?: any | undefined;
}) => {
  const { fieldName, registerField, error, clearError } = useField(name);

  const classDefault = 'autocompleteDiffers';
  const autocompleteRef = useRef(null);

  const getInitialValue = () => {
    if (multiple) {
      if (defaultValue) return defaultValue;
      if (fixedValue) return [fixedValue];
      return [];
    }

    return defaultValue || fixedValue || null;
  };

  const [valuesSelecteds, setValuesSelecteds] = useState(getInitialValue());

  const [hasDefinedDefaultValue, setHasDefinedDefaultValue] = useState(false);

  const handlesRef = useRef({ onChange });

  const isDefaultValuesValid = useCallback(() => {
    if (!defaultValue) return false;
    if (Array.isArray(defaultValue)) {
      return defaultValue?.some(
        (el) =>
          options?.findIndex((op) => el?.[valueProp] === op?.[valueProp]) !== -1
      );
    }
    return options?.some((op) => op?.[valueProp] === defaultValue?.[valueProp]);
  }, [options, defaultValue, valueProp]);

  const getValidValue = useMemo(() => {
    if (disableValidateValInOpts) return valuesSelecteds;

    if (!options?.length) return multiple ? [] : null;
    if (!valuesSelecteds) return null;
    if (multiple && !Array.isArray(valuesSelecteds))
      throw new Error('Values must be an array');

    if (multiple) {
      return valuesSelecteds.filter((val: any) =>
        options.some((opt) =>
          has(opt, valueProp)
            ? (opt[valueProp] ?? opt) === val[valueProp]
            : opt === val
        )
      );
    }

    return options.some((opt) => opt[valueProp] === valuesSelecteds[valueProp])
      ? valuesSelecteds
      : null;
  }, [options, multiple, valueProp, valuesSelecteds, disableValidateValInOpts]);

  useEffect(() => {
    if (!getValidValue && handlesRef.current.onChange)
      handlesRef.current.onChange(null, 'clear');
  }, [getValidValue]);

  useEffect(() => {
    if (!isDefaultValuesValid()) return;

    if (!hasDefinedDefaultValue) {
      setValuesSelecteds(defaultValue);
      setHasDefinedDefaultValue(true);
    }
  }, [
    defaultValue,
    valuesSelecteds,
    isDefaultValuesValid,
    hasDefinedDefaultValue
  ]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: autocompleteRef.current,
      getValue: () => {
        if (!returnObject && Array.isArray(getValidValue))
          return getValidValue.map((el) => el[valueProp]);

        if (returnObject) return getValidValue;

        return getValidValue ? getValidValue[valueProp] : undefined;
      },
      setValue: (_, value: any) => {
        clearError();
        const newValue = value || defaultValue || null;
        setValuesSelecteds(newValue);

        if (onChange) onChange(newValue, !newValue ? 'clear' : 'blur');
      },
      clearValue: () => {
        setHasDefinedDefaultValue(false);

        if (multiple) {
          const resetedValue = fixedValue ? [fixedValue] : [];
          setValuesSelecteds(resetedValue);

          if (onChange) onChange(resetedValue, 'clear');
          return;
        }

        const resetedValue = fixedValue || null;

        setValuesSelecteds(resetedValue);
        if (onChange) onChange(resetedValue, 'clear');
      }
    });
  }, [
    fieldName,
    registerField,
    multiple,
    defaultValue,
    returnObject,
    valueProp,
    clearError,
    onChange,
    getValidValue,
    fixedValue
  ]);

  const handleClearError = () => {
    if (onClearError) onClearError(name);
  };

  const handleChange = (
    event: any,
    value: any | any[],
    reason: AutocompleteChangeReason
  ) => {
    // Check if mainValue has a value to trigger the "select all" behavior
    if (mainValue && value.length) {
      const all = value.filter((op: any) => op.label === mainValue);
      const isAllSelected =
        (value.length === 1 && value[0].label === mainValue) ||
        (value.map((op: any) => op.label).includes(mainValue) &&
          value[0].label !== mainValue);

      if (isAllSelected) {
        // If mainValue ("All") is selected, clear other options and keep only mainValue
        const allValue = all;
        setValuesSelecteds(allValue);
        if (onChange) onChange(allValue, reason);
        return;
      }
      // Remove mainValue from selections if other options are picked
      const filteredValue =
        value[0].label === mainValue ? [...value.slice(1)] : value;
      setValuesSelecteds(filteredValue);
      if (onChange) onChange(filteredValue, reason);
      return;
    }

    // Default behavior if mainValue is not set
    const copyValue = cloneDeep(value);

    if (multiple && !copyValue.length && fixedValue) {
      copyValue.push(fixedValue);
    } else if (multiple && copyValue.length && fixedValue) {
      const idx = copyValue.findIndex(
        (v: { [x: string]: any }) =>
          (v[valueProp] ?? v) === (fixedValue[valueProp] ?? fixedValue)
      );

      if (idx > 0 && copyValue.length >= 2) {
        copyValue.length = 0;
        copyValue.push(fixedValue);
      } else if (idx >= 0 && copyValue.length === 2) copyValue.splice(idx, 1);
    }

    setValuesSelecteds(copyValue);
    if (onChange) onChange(copyValue, reason);
  };

  const getRenderInput = (params: AutocompleteRenderInputParams) => {
    return renderInput ? (
      renderInput(params)
    ) : (
      <TextField
        {...params}
        error={!!error || !!externalError}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {!disableClearable ? params.InputProps.endAdornment : ''}
            </>
          ),
          ...attributeMaskOrUnmask(dataClarityMask)
        }}
        inputRef={autocompleteRef}
        label={label}
        InputLabelProps={{
          ...inputLabelProps
        }}
        placeholder={placeholderInput || ' '}
        required={required}
      />
    );
  };

  const renderMultipleOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: AutocompleteRenderOptionState
  ): React.ReactNode => {
    const component = (
      <>
        <Checkbox
          checked={selected}
          classes={checkboxClasses}
          className="mr-2"
        />
        {option[labelProp] ?? option}
      </>
    );

    return <li {...props}>{component}</li>;
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: any) => (
    <li {...props}>
      <ListItemText primary={option[labelProp] ?? option} />
    </li>
  );

  const getOptions = useMemo(
    () => (maxItems && valuesSelecteds?.length === maxItems ? [] : options),
    [maxItems, valuesSelecteds, options]
  );

  const isDisableCloseOnSelect = useMemo(() => {
    if (multiple && maxItems && valuesSelecteds?.length >= maxItems - 1) {
      return false;
    }

    return disableCloseOnSelect;
  }, [disableCloseOnSelect, multiple, maxItems, valuesSelecteds]);

  const isOptionEqualToValue = (option: any, value: any) => {
    return (option[valueProp] ?? option) === (value[valueProp] ?? value);
  };

  const getOptionLabel = (option: any) => {
    // if (miniComp) {
    //   return option[labelProp] ?? option;
    // }
    return option[labelProp] ?? `${option.substring(0, 3)}...`;
  };

  const getLimitTagsText = (val: any) => {
    return <div className={`${classDefault}__limitTag`}>+{val}</div>;
  };

  return (
    <>
      <Autocomplete
        classes={{
          ...classes,
          listbox: `${classMaskOrUnmask(dataClarityMask)} ${classes?.listbox}`
        }}
        limitTags={maxDisplay || -1}
        getLimitTagsText={getLimitTagsText}
        className={`${classDefault} ${!visible ? 'd-none' : undefined}`}
        defaultValue={defaultValue}
        disableCloseOnSelect={isDisableCloseOnSelect}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={multiple}
        noOptionsText={
          maxItems && valuesSelecteds?.length
            ? `Limite máximo de ${maxItems} itens.`
            : noOptionsText || 'Sem opções'
        }
        onChange={handleChange}
        onClose={handleClearError}
        onOpen={clearError}
        options={getOptions}
        renderInput={getRenderInput}
        renderOption={multiple ? renderMultipleOption : renderOption}
        value={getValidValue}
        {...attributeMaskOrUnmask(dataClarityMask)}
        {...rest}
      />
      <FormHelperText className="Mui-error">
        {error || externalError}
      </FormHelperText>
    </>
  );
};

AutocompleteInput.defaultProps = {
  checkboxClasses: { root: 'plim-gray3', checked: 'plim-gray5' },
  dataClarityMask: undefined,
  defaultValue: undefined,
  disableValidateValInOpts: false,
  fixedValue: undefined,
  labelProp: 'label',
  loading: false,
  maxItems: undefined,
  onChange: undefined,
  onClearError: undefined,
  placeholderInput: undefined,
  renderInput: undefined,
  required: false,
  returnObject: false,
  valueProp: 'value',
  visible: true,
  inputLabelProps: {},
  maxDisplay: null,
  disableClearable: false
};

export default memo(AutocompleteInput);
