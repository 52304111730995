import { AppThunk } from '../../..';
import { YupError } from '../../../../helpers/HandleErrors';
import { IGroup, GroupsTypes } from './Groups.types';

export const SavePartialFormData = (
  id: string,
  partialData?: Partial<IGroup>
) => ({
  type: GroupsTypes.SAVE_PARTIAL_GROUP_DATA,
  payload: {
    id,
    partialData
  }
});

export const SaveGroupsFormData =
  (groupData: Partial<IGroup> | undefined): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: GroupsTypes.SAVE_GROUP_DATA,
      payload: { ...groupData }
    });
  };

export const SaveGroups =
  (groups: Partial<IGroup[]> | undefined): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: GroupsTypes.SAVE_GROUPS,
      payload: {},
      groups: groups || []
    });
  };

export const SaveGroupsFormDataError = (id: number, erros?: YupError) => ({
  type: GroupsTypes.SAVE_GROUPS_FORM_DATA_ERROR,
  payload: { id, erros }
});

export const AddGroup = () => ({
  type: GroupsTypes.ADD_GROUP
});

export const CloneGroup = (id: number) => ({
  type: GroupsTypes.CLONE_GROUP,
  payload: { id }
});
export const ClearGroupData = (id: number) => ({
  type: GroupsTypes.CLEAR_GROUP_DATA,
  payload: { id }
});

export const RemoveGroups = (id: number) => ({
  type: GroupsTypes.REMOVE_GROUPS,
  payload: { id }
});

export const RemoveGroupsFormError = (id: number) => ({
  type: GroupsTypes.REMOVE_GROUPS_FORM_ERROR,
  payload: { id }
});

export const ResetGroups = () => ({
  type: GroupsTypes.RESET_GROUPS
});
