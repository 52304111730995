import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { IGroup } from '../../../../../store/modules/Digital/Groups/Groups.types';
import './Totals.scss';
import AppSVG from '../../../../SVG';
import money from '../../../../../assets/images/attach_money.svg';
import visibility from '../../../../../assets/images/visibility.svg';

interface ITotalsProps {
  group: IGroup;
}

const Totals = ({ group }: ITotalsProps) => {
  return (
    <Box component="div" className="totals">
      <Box component="div" className=" totals__investment">
        <Box className="totals__investment__svg">
          <AppSVG loader={<Skeleton height={24} width={24} />} src={money} />
        </Box>
        <Box className="totals__investment__label">Investimento total</Box>
        <Box className="totals__investment__value">
          {group.data?.cpm?.investimentValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </Box>
      </Box>
      <Box component="hr" className="totals__border" />
      <Box component="div" className="  totals__impressions">
        <Box className="totals__investment__svg">
          <AppSVG
            loader={<Skeleton height={24} width={24} />}
            src={visibility}
          />
        </Box>
        <Box className="totals__investment__label">Impressões</Box>
        <Box className="totals__investment__value">
          {group.data?.cpm?.impressions?.toLocaleString('pt-BR')}
        </Box>
      </Box>
    </Box>
  );
};

export default Totals;
