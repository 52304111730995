export enum EnumSimulationsTypesRequest {
  SIMULATIONS_TYPE_REQUEST = '@simulations-types/SIMULATIONS_TYPE_REQUEST',
  LOAD_SIMULATIONS_TYPE_SUCCESS = '@simulations-types/LOAD_SIMULATIONS_TYPE__SUCCESS',
  LOAD_SIMULATIONS_TYPE_ERROR = '@simulations-types/LOAD_SIMULATIONS_TYPE__FAILURE'
}

export enum EnumSimulationsTypes {
  DIGITAL = 2,
  LINEAR = 1
}

export interface ISimulationsTypes {
  id: number;
  type: string;
}

export interface SimulationsTypeState {
  readonly data: ISimulationsTypes[] | [];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
  readonly message: string;
}

export interface SimulationsTypeAction {
  type: EnumSimulationsTypesRequest;
  payload: {
    rows?: ISimulationsTypes[] | [];
    count?: number;
    message?: string;
  };
}

export interface ISimulationsTypeResponse {
  data: {
    rows: ISimulationsTypes[];
    count: number;
  };
  status: number;
  msg: string;
}
