import './InformationCard.scss';
import React from 'react';

interface InformationProps {
  icon: string;
  text: string;
  className?: string;
}

const Information: React.FC<InformationProps> = ({ icon, text, className }) => {
  return (
    <div className={`information ${className}`}>
      <img src={icon} alt="information" />
      <span>{text}</span>
    </div>
  );
};

Information.defaultProps = {
  className: ''
};

export default Information;
