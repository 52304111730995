import './KantarDescription.scss';

const KantarDescription = () => {
  return (
    <div
      className="kantar-description"
      style={{ fontSize: '16px', color: '#465457' }}
    >
      <p>
        Fonte: Kantar Ibope Media - Instar Analytics - Universos projetados com
        base no EDTV 2024
      </p>
      <a
        href="https://plim.g.globo/#/audience/help"
        rel="noreferrer"
        target="_blank"
        style={{ color: '#05A6FF', textDecoration: 'underline' }}
      >
        (vide página de Material de Apoio)
      </a>
    </div>
  );
};

export default KantarDescription;
