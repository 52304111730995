import { useEffect, useState } from 'react';

import Filter from '../../Filters/FiltersDAI';
import CampaignPeriod from '../../Filters/CampaignPeriod';
import { confirmAlertApp } from '../../ModalShell';
import { useAppSelector } from '../../../hooks';
import './DigitalSegment.scss';
import useNavigate from '../Simulation/useNavigate';

const TIMER_SECONDS = 5;
const TIMER_MILI_SECONDS = TIMER_SECONDS * 100;

const DigitalSegment = () => {
  const { impressions } = useAppSelector((state) => state.digital);
  const [counter, setCounter] = useState(TIMER_SECONDS);

  const { handleItemOnClick } = useNavigate({});

  const onClickOK = () => {
    handleItemOnClick();
  };

  const gamError = () => {
    confirmAlertApp({
      message:
        'Estamos tentando consultar o inventário disponível de acordo com as segmentações e não obtivemos resposta. Recomendamos que tente novamente.',
      onClickOK: () => onClickOK(),
      labelBtnCancel: 'cancelar',
      labelBtnOK: `${counter > 0 ? `${counter}s` : ''} Tentar novamente`,
      title: 'Ops! não conseguimos obter as impressões disponíveis',
      disableBtnOk: counter > 0,
      classes: { container: 'segment-error' }
    });
  };

  useEffect(() => {
    if (!impressions.loading && impressions.error) {
      counter > 0 &&
        setTimeout(() => setCounter(counter - 1), TIMER_MILI_SECONDS);

      gamError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impressions, counter]);
  return (
    <div>
      <CampaignPeriod />
      <Filter />
    </div>
  );
};

export default DigitalSegment;
