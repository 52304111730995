import { apiV1 } from '../shared/Api.service';

import { ISimulationsTypeResponse } from '../../store/modules/SimulationsType/SimulationsType.types';

const path = '/simulations-type';

async function getST(): Promise<ISimulationsTypeResponse> {
  const { data } = await apiV1.get<ISimulationsTypeResponse>(path);

  return data;
}

export default getST;
