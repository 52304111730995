import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import classesGTM from '../../../../../config/classesGTM';
import useAppSelector from '../../../../../hooks/useAppSelector';
import CheckboxInput from '../../../../Form/CheckboxInput';
import ModalShell from '../../../../ModalShell';
import './ModalExport.scss';
import { IGroup } from '../../../../../store/modules/Digital/Groups/Groups.types';
import { exportDigitalSimulation } from '../../../../../helpers/Export';

interface IModalExportProps {
  open: boolean;
  handleClickClose: () => void;
}

const ModalExport = ({ open = false, handleClickClose }: IModalExportProps) => {
  const { simulation } = useAppSelector((state) => state);
  const [digitalSimulation, setDigitalSimulation] = useState(simulation);
  const [error, setError] = useState(true);

  const [allGroups, setAllGroups] = useState(false);

  useEffect(() => {
    const exportSimulation = {
      ...simulation,
      groups: simulation.groups?.map((item) => ({ ...item, export: false }))
    };
    setDigitalSimulation(exportSimulation);
    setAllGroups(false);
  }, [open, simulation]);

  useEffect(() => {
    if (!digitalSimulation.groups?.find((item) => item.export)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [digitalSimulation]);

  const allGroupChange = () => {
    const exportSimulation = {
      ...simulation,
      groups: simulation.groups?.map((item) => ({
        ...item,
        export: allGroups
      }))
    };
    setDigitalSimulation(exportSimulation);
  };

  useEffect(() => {
    allGroupChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups]);

  const handleClickOk = () => {
    const onlyGroupsToExport = {
      ...digitalSimulation,
      groups: digitalSimulation.groups?.filter((item) => item.export)
    };
    exportDigitalSimulation(onlyGroupsToExport);
    handleClickClose();
  };

  const changeGroupCheck = (
    group: IGroup,
    newGroup: IGroup,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (group.uuid === newGroup.uuid)
      return { ...group, export: e.target.checked };

    return group;
  };

  const handleCheckChange = (
    group: IGroup,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSimulation = {
      ...digitalSimulation,
      groups: digitalSimulation.groups?.map((item) =>
        changeGroupCheck(item, group, e)
      )
    };
    setDigitalSimulation(newSimulation);
  };

  return (
    <ModalShell
      classTagManagerButtons={{
        btnOk: classesGTM.simulation.digital.exportDigitalModal
      }}
      labelBtnOK="Exportar"
      onClickOK={handleClickOk}
      onClickCancel={handleClickClose}
      labelBtnCancel="Voltar"
      open={open}
      noClose
      disableBtnOk={error}
      title="Selecione o(s) grupo(s) que deseja exportar pra o seu Planejamento."
    >
      <Box className="digitalExport">
        <CheckboxInput
          checked={allGroups}
          classes={{ root: 'plim-gray3', checked: 'plim-blue' }}
          label="Selecionar todos"
          labelClasses={{ label: 'digitalExport__checkbox' }}
          onChange={(e) => setAllGroups(e.target.checked)}
          size="small"
        />
        <Box component="hr" className="digitalExport__hr" />
        <Box className="groups">
          {digitalSimulation.groups?.map((item) => (
            <CheckboxInput
              key={item.uuid}
              checked={item.export}
              classes={{ root: 'plim-gray3', checked: 'plim-blue' }}
              label={item.name}
              labelClasses={{ label: 'digitalExport__checkbox' }}
              onChange={(e) => handleCheckChange(item, e)}
              size="small"
            />
          ))}
        </Box>
      </Box>
    </ModalShell>
  );
};

export default ModalExport;
