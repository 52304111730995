import { Paper, Box } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import './AudiencePayTvParticipationGenrePanel.scss';

interface IAudiencePayTvParticipationGenreNoValue {
  noFilterSelected?: boolean;
}

const AudiencePayTvParticipationGenreNoValue = ({
  noFilterSelected
}: IAudiencePayTvParticipationGenreNoValue) => {
  return (
    <Paper
      className="no-data-screen graphContainer"
      elevation={3}
      sx={{ margin: 0 }}
    >
      <Box className="no-data-container">
        <Typography variant="h6" className="title">
          Participação:
        </Typography>
        <Box className="divider" />
        {noFilterSelected && (
          <Typography className="message">
            Nenhum filtro foi selecionado
            <br />
            Configure os filtros de acordo com sua necessidade para montar o
            gráfico
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

AudiencePayTvParticipationGenreNoValue.defaultProps = {
  noFilterSelected: false
};

export default AudiencePayTvParticipationGenreNoValue;
