import { List, ListItemText, Divider, ListItemButton } from '@mui/material';
import './style.scss';
// eslint-disable-next-line import/no-cycle
import { IGroup } from '../../store/modules/Digital/Groups/Groups.types';

interface IGroupSettings {
  visible: boolean;
  refValue: React.RefObject<HTMLElement>;
  groups: IGroup[];
  handleClickRename: () => void;
  handleClickDuplicate: () => void;
  handleClickDelete: () => void;
}

const defaultClassName = 'groupSettings';

const GroupSettings = ({
  visible,
  refValue,
  groups,
  handleClickRename,
  handleClickDuplicate,
  handleClickDelete
}: IGroupSettings) => {
  return (
    <>
      {visible && (
        <List component="nav" className={defaultClassName} ref={refValue}>
          <ListItemButton>
            <ListItemText onClick={handleClickRename} primary="Renomear" />
          </ListItemButton>
          <Divider />
          {groups.length <= 2 && (
            <ListItemButton divider>
              <ListItemText onClick={handleClickDuplicate} primary="Duplicar" />
            </ListItemButton>
          )}
          {groups.length > 1 && (
            <ListItemButton>
              <ListItemText onClick={handleClickDelete} primary="Excluir" />
            </ListItemButton>
          )}
        </List>
      )}
    </>
  );
};
export default GroupSettings;
