import { Route, Routes, HashRouter as Router } from 'react-router-dom';
import Simulations from '../pages/Simulations';
import LoginWrapper from '../components/Auth/LoginWrapper';
import Unauthorized from '../components/Auth/Unauthorized';
import Users from '../pages/Users/Users';
import RolesAllowedRouter from './RolesAllowedRouter';
import { Roles } from '../store/modules/Users/Users.types';
import Simulation from '../components/Simulation';
import DigitalSimulation from '../components/Digital/Simulation';
import Home from '../pages/Home';
import Filters from '../components/Filters/Filters';
import Ranking from '../components/Ranking';
import Planning from '../components/Planning';
import paths from './paths';
import Summary from '../components/Summary';
import Audience from '../pages/Audience/Audience';
import App from '../App';
import { ControllerSceneryRoute } from '../components/Scenarios';
import Competitive from '../pages/Competitive';
import CompetitivePanel from '../components/Competitive/CompetitivePanels';
import AudienceMenu from '../components/Audience/Menu/Menu';
import AudiencePowerBi from '../components/Audience/AudiencePanels/PowerBIAudience';
import AudienceOpenTvRegularPlazasPanel from '../components/Audience/AudiencePanels/OpenTv/AudienceOpenTvRegularPlazasPanel';
import AudienceOpenTvContainer from '../components/Audience/AudiencePanels/OpenTv/AudienceOpenTvRegularPlazasContainer';
import AudiencePayTvPanel from '../components/Audience/AudiencePanels/PayTv/AudiencePayTvPanel';
import AudiencePayTvContainer from '../components/Audience/AudiencePanels/PayTv/AudiencePayTvContainer';
import DigitalSegment from '../components/Digital/Segment';
import DigitalPlanning from '../components/Digital/Planning';
import DigitalSummary from '../components/Digital/Summary';

const RoutesApp = () => {
  const routeLogged = (element: JSX.Element) => {
    return <LoginWrapper>{element}</LoginWrapper>;
  };
  const routeProtected = (element: JSX.Element, rolesAllowed: Roles[]) => (
    <LoginWrapper>
      <RolesAllowedRouter rolesAllowed={rolesAllowed}>
        {element}
      </RolesAllowedRouter>
    </LoginWrapper>
  );

  const {
    simulations,
    digitalSimulation,
    users,
    simulation,
    audience,
    competitive
  } = paths.home.childrens;

  return (
    <Router>
      <Routes>
        <Route element={<App />}>
          <Route element={<Unauthorized />} path={paths.unauthorized} />
          <Route element={routeLogged(<Home />)}>
            <Route
              element={routeProtected(<Audience />, [
                Roles.ADMIN,
                Roles.EXECUTIVE
              ])}
              path={audience.path}
            >
              <Route
                element={routeLogged(<AudienceMenu />)}
                path={audience.path}
              />
              <Route
                element={routeLogged(<AudienceOpenTvRegularPlazasPanel />)}
                path={`${audience.childrens.openTv.path}/${audience.childrens.openTv.childrens.regularPlazas.path}`}
              >
                <Route
                  element={routeLogged(<AudienceOpenTvContainer />)}
                  path=":tab"
                >
                  <Route
                    element={routeLogged(<AudienceOpenTvContainer />)}
                    path=":subtab"
                  />
                </Route>
              </Route>
              {!audience?.childrens?.payTv?.disabled ? (
                <Route
                  element={routeLogged(<AudiencePayTvPanel />)}
                  path={audience.childrens.payTv.path}
                >
                  <Route
                    element={routeLogged(<AudiencePayTvContainer />)}
                    path=":tab"
                  >
                    <Route
                      element={routeLogged(<AudiencePayTvContainer />)}
                      path=":subtab"
                    />
                  </Route>
                </Route>
              ) : null}
              <Route element={routeLogged(<AudiencePowerBi />)} path=":type" />
            </Route>
            <Route element={routeLogged(<Simulations />)} path={simulations} />
            <Route
              element={routeProtected(<Users />, [Roles.ADMIN])}
              path={users}
            />
            <Route
              element={routeProtected(<Competitive />, [
                Roles.ADMIN,
                Roles.EXECUTIVE
              ])}
              path={competitive.path}
            >
              <Route element={routeLogged(<CompetitivePanel />)} path=":tab" />
            </Route>
            <Route element={routeLogged(<Simulation />)} path={simulation.path}>
              <Route element={<ControllerSceneryRoute />} path=":id">
                <Route
                  element={routeLogged(<Filters />)}
                  path={simulation.childrens.filters}
                />
                <Route
                  element={routeLogged(<Ranking />)}
                  path={simulation.childrens.ranking}
                />
                <Route
                  element={routeLogged(<Planning />)}
                  path={simulation.childrens.planning}
                />
                <Route
                  element={routeLogged(<Summary />)}
                  path={simulation.childrens.summary}
                />
              </Route>
            </Route>
            <Route
              element={routeLogged(<DigitalSimulation />)}
              path={digitalSimulation.path}
            >
              <Route
                element={routeLogged(<DigitalSegment />)}
                path={digitalSimulation.childrens.segment}
              />
              <Route
                element={routeLogged(<DigitalPlanning />)}
                path={digitalSimulation.childrens.planning}
              />
              <Route
                element={routeLogged(<DigitalSummary />)}
                path={digitalSimulation.childrens.summary}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesApp;
