/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React from 'react';
import './Tags.scss';

interface Tag {
  label: string;
}

interface TagsProps {
  options: Tag[];
  maxDisplay?: number;
}

const Tags: React.FC<TagsProps> = ({ options, maxDisplay = 4 }) => {
  const visibleTags = options.slice(0, maxDisplay);
  const hiddenCount = options.length - visibleTags.length;

  return (
    <div className="tags">
      {visibleTags.map((tag, index) => (
        <span key={index} className="tag">
          {tag.label}
        </span>
      ))}
      {hiddenCount > 0 && (
        <span className="tag tag--extra">+{hiddenCount}</span>
      )}
    </div>
  );
};

export default Tags;
