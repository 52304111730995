export enum ImpressionsTypes {
  LOAD_IMPRESSIONS_REQUEST = '@impressions/LOAD_IMPRESSIONS_REQUEST',
  LOAD_IMPRESSIONS_SUCCESS = '@impressions/LOAD_IMPRESSIONS_SUCCESS',
  LOAD_IMPRESSIONS_ERROR = '@impressions/LOAD_IMPRESSIONS_ERROR',
  RESET_IMPRESSIONS = '@impressions/RESET_IMPRESSIONS'
}

export interface GamResponse {
  __values__: {
    availableUnits: number;
    targetingCriteriaBreakdowns: {
      __values__: {
        availableUnits: number;
        excluded: boolean;
        matchedUnits: number;
        targetingCriteriaId: number | null;
        targetingCriteriaName: string;
        targetingDimension: string;
      };
    }[];
  };
}

export interface ImpressionAction {
  type: ImpressionsTypes;
  payload: {
    rows: GamResponse[];
    count: number;
    message?: string;
  };
}

export interface ImpressionsState {
  readonly data: GamResponse[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface ImpressionsResponse {
  data: {
    rows: GamResponse[];
    count: number;
  };
  status: number;
  msg: string;
}
