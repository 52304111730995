import { apiV1 } from '../../../shared/Api.service';
import { ChannelResponse } from '../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.types';
import { ICommonProps } from '../../../../store/modules/AudienceModule/PayTv/Participation/Channels/Audience.channels.types';

const path = '/audience/pay-tv/participation/channels';

export default async function getParticipationChannels({
  programmingGenders
}: ICommonProps): Promise<ChannelResponse> {
  const { data } = await apiV1.get<ChannelResponse>(path, {
    params: {
      programmingGenders: Array.isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value
    }
  });
  return data;
}
