import { Box } from '@mui/material';
import Group from '../../Group/Group';
import Totals from './components/Totals/Totals';
import AvaliableImpressions from './components/AvaliableImpressions/AvaliableImpressions';
import FiltersReview from '../Planning/Cards/FiltersReview/FiltersReview';
import './DigitalSummary.scss';

const DigitalSummary = () => {
  return (
    <Group
      className={{
        accordion: 'groupAccordion',
        summary: 'groupAccordion__summary',
        group: 'fullWidth group'
      }}
      addGroupsOption={false}
    >
      {(group) => {
        return (
          <Box component="div" sx={{ display: 'flex' }}>
            <Box component="div" sx={{ width: '50%', marginRight: '32px' }}>
              <Box className="filters__wrapp summary">
                <Box component="p" className="filters__label ">
                  Criativo:
                </Box>
                <Box component="p" className="filters__label-content">
                  {group.data?.cpm?.secondaryCpm} segundos
                </Box>
                <Box component="p" className="filters__label">
                  CPM:
                </Box>
                <Box component="p" className="filters__label-content">
                  {group.data?.cpm?.cpm?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </Box>
              </Box>
              <FiltersReview group={group} />
              <Totals group={group} />
            </Box>
            <Box component="div" sx={{ width: '50%' }}>
              <AvaliableImpressions group={group} />
            </Box>
          </Box>
        );
      }}
    </Group>
  );
};

export default DigitalSummary;
