import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks';

import { IAudienceByParticipationContainerProps } from '../../../types';
import { LoadAudienceFilterPeriodsRequest } from '../../../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';

const useAudiencePayTvParticipationGeralFilterLoadData = ({
  target
}: Partial<IAudienceByParticipationContainerProps>) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);

  useEffect(() => {
    if (
      target &&
      audience?.payTv?.periods?.count === null &&
      !audience?.payTv?.periods?.error &&
      !audience?.payTv?.periods?.loading
    ) {
      dispatch(
        LoadAudienceFilterPeriodsRequest({
          target
        })
      );
    }
  }, [
    audience?.payTv?.periods?.count,
    audience?.payTv?.periods?.error,
    audience?.payTv?.periods?.loading,
    dispatch,
    target
  ]);

  const periodOptions = useMemo(() => {
    if (audience?.payTv?.periods.count) {
      return audience?.payTv?.periods.data
        .filter((timeRangeOption) => timeRangeOption.ds_periodo)
        .map((timeRangeOption) => ({
          label: timeRangeOption.ds_periodo,
          value: timeRangeOption.ds_periodo,
          tp_dado: timeRangeOption.tp_dado
        }))
        .sort((a, b) => {
          if (a?.value?.includes('SEM') && !b?.value?.includes('SEM')) {
            return 1;
          }

          if (!a?.value?.includes('SEM') && b?.value?.includes('SEM')) {
            return -1;
          }

          if (a?.value?.includes('TRI') && !b?.value?.includes('TRI')) {
            return 1;
          }

          if (!a?.value?.includes('TRI') && b?.value?.includes('TRI')) {
            return -1;
          }

          return a?.value?.localeCompare(b?.value);
        });
    }

    return [];
  }, [audience?.payTv?.periods?.count, audience?.payTv?.periods.data]);

  return {
    periodOptions
  };
};

export default useAudiencePayTvParticipationGeralFilterLoadData;
