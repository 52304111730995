import './SubHeaderDigitalSimulation.scss';
import React from 'react';

import edit from '../../../assets/images/edit.svg';

import { ButtonIcon } from '../../ButtonApp';

import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import SubTitle from '../../SubTitle/SubTitle';

interface SubHeaderSimulationProps {
  title: string;
  onClickEdit(): void;
}

const DaiSubtitles = ['Planejamento', 'Digital', 'DAI'];

const SubHeaderDigitalSimulation: React.FC<SubHeaderSimulationProps> = ({
  title,
  onClickEdit
}) => {
  return (
    <div className="flex-sb mb-2">
      <div className="title-version">
        <div className="flex-wrap">
          <h2 className="subheader__title" {...attributeMaskOrUnmask(true)}>
            {title}
          </h2>
          <ButtonIcon
            className="ml-2"
            icon={edit}
            id="btn_edit_simulation"
            onClick={onClickEdit}
          />
        </div>
      </div>
      <SubTitle subtitles={DaiSubtitles} />
    </div>
  );
};

export default SubHeaderDigitalSimulation;
