import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  GraphsResponse,
  ParticipationFilterGraphsAction,
  ParticipationFilterGraphsType
} from './Participation.graphs.types';
import getParticipationGraphs from '../../../../../../services/Audience/PayTv/Audience.paytv.participationGraphs.service';
import { IParticipationGraphsFilterParams } from '../../../../../../components/Audience/AudiencePanels/PayTv/Participation/types';

const LoadParticipationGraphsSuccess = (response: GraphsResponse) => {
  const payload: ParticipationFilterGraphsAction = {
    type: ParticipationFilterGraphsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadParticipationGraphsError = (error: string) => {
  const payload: ParticipationFilterGraphsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: ParticipationFilterGraphsType.ERROR
  };

  return payload;
};

const LoadingParticipationGraphsFilter = () => ({
  type: ParticipationFilterGraphsType.REQUEST,
  payload: {}
});

export const ClearParticipationGraphsData = () => ({
  type: ParticipationFilterGraphsType.CLEAR,
  payload: {}
});

export const LoadParticipationGraphsRequest =
  (props: IParticipationGraphsFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingParticipationGraphsFilter());
      const response = await getParticipationGraphs(props);

      dispatch(LoadParticipationGraphsSuccess(response));
    } catch (error) {
      dispatch(
        LoadParticipationGraphsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os valores para o gráfico.'
      );
    }
  };
