import Box from '@mui/material/Box/Box';
import './SubTitle.scss';

interface ISubTitleProps {
  subtitles: string[];
}

const SubTitle = ({ subtitles }: ISubTitleProps) => {
  const transformSubtitle = () => {
    return subtitles.join(' | ');
  };

  return <Box className="subTitle-header">{transformSubtitle()}</Box>;
};

export default SubTitle;
