import { RefObject } from 'react';
import { FormHandles } from '@unform/core';
import { Location } from '../Locations/Locations.types';
import { Age } from '../Ages/Ages.types';
import { Interest } from '../Interests/Interests.types';
import { Gender } from '../Genders/Genders.types';
import { YupError } from '../../../../helpers/HandleErrors';

export enum FiltersTypes {
  SET_FILTERS_FORMREF = '@digitalFilters/SET_FILTERS_FORMREF',
  SAVE_PARTIAL_FILTERS_FORM_DATA = '@digitalFilters/SAVE_PARTIAL_FILTERS_FORM_DATA',
  SAVE_FILTERS_FORM_DATA = '@digitalFilters/SAVE_FILTERS_FORM_DATA',
  SAVE_FILTERS_FORM_DATA_ERROR = '@digitalFilters/SAVE_FILTERS_FORM_DATA_ERROR',
  CLEAR_FILTERS_FORM_DATA = '@digitalFilters/CLEAR_FILTERS_FORM_DATA',
  REMOVE_FILTERS_SCENERY = '@digitalFilters/REMOVE_FILTERS_SCENERY',
  REMOVE_FILTERS_FORM_ERROR = '@digitalFilters/REMOVE_FILTERS_FORM_ERROR',
  RESET_FILTERS = '@digitalFilters/RESET_FILTERS'
}

export enum ClosedPeriodTypesEnum {
  MONTH = 'month',
  QUARTER = 'quarter',
  SEMESTER = 'semester',
  CLOSED_YEAR = 'closedYear'
}

export type ClosedPeriodTypes =
  | ClosedPeriodTypesEnum.MONTH
  | ClosedPeriodTypesEnum.QUARTER
  | ClosedPeriodTypesEnum.SEMESTER
  | ClosedPeriodTypesEnum.CLOSED_YEAR;

export type ClosedPeriodValue = {
  label: string;
  value: string;
};

export type ClosedPeriod = {
  year: Date | string;
  type: ClosedPeriodTypes;
  value?: ClosedPeriodValue;

  /** @deprecated use value field instead */
  month?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  quarter?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  semester?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  closedYear?: ClosedPeriodValue;
};

export interface FiltersData {
  locations?: Location[];
  gender?: Gender;
  age?: Age[];
  interests?: Interest[];
}

export interface Filter {
  ref?: RefObject<FormHandles>;
  data?: FiltersData;
  erros?: YupError;
  partialData?: Partial<FiltersData>;
  sceneryId?: string;
  error?: boolean;
}

export interface FiltersState {
  readonly forms: Filter[];
}

export interface FilterAction {
  type: FiltersTypes;
  payload: Filter;
}
