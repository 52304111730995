import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ModalShell from '../ModalShell';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  open: boolean;
  progressDownload: number;
  finished: boolean;
  error: boolean;
  onClose?(): void;
}

const Icon = (
  <svg
    fill="#FFFFFF"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"
      fill="#07bc0c"
    />
  </svg>
);

const IconError = (
  <svg
    height="16.906"
    viewBox="0 0 16.906 16.906"
    width="16.906"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="error" transform="translate(-8 -8)">
      <path
        d="M16.453,8a8.453,8.453,0,1,0,8.453,8.453A8.463,8.463,0,0,0,16.453,8Zm0,15.228a6.618,6.618,0,0,1-6.8-6.775,6.606,6.606,0,0,1,6.8-6.76,6.613,6.613,0,0,1,6.805,6.76A6.625,6.625,0,0,1,16.453,23.228Z"
        data-name="Path 14"
        fill="#e74c3c"
        id="Path_14"
      />
      <path
        d="M29.429,24.207a.7.7,0,0,0-1,0l-1.615,1.615L25.2,24.207a.7.7,0,1,0-1,1l1.615,1.615-1.615,1.615a.7.7,0,1,0,1,1l1.615-1.615,1.615,1.615a.7.7,0,0,0,1-1l-1.615-1.615L29.429,25.2A.7.7,0,0,0,29.429,24.207Z"
        data-name="Path 15"
        fill="#e74c3c"
        id="Path_15"
        transform="translate(-10.365 -10.365)"
      />
    </g>
  </svg>
);

const ModalLoadSimulation: React.FC<Props> = ({
  open = false,
  progressDownload,
  finished,
  error,
  onClose
}) => {
  return (
    <ModalShell
      disableActions
      disableBackdropClick
      disableEscapeKeyDown
      onClickCancel={onClose}
      open={open}
      title=""
    >
      <div {...attributeMaskOrUnmask(false)}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress value={progressDownload} variant="determinate" />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography color="text.secondary" variant="body2">
              {`${Math.round(progressDownload)}%`}
            </Typography>
          </Box>
        </Box>
        {!error ? (
          <div>
            {progressDownload === 100
              ? 'Download dos dados do planejamento finalizado'
              : 'Baixando dados do planejamento'}
          </div>
        ) : (
          <div>Não foi possivel realizar o download dos dados</div>
        )}
        <div className="d-flex mt-5 align-center">
          <Box sx={{ display: 'flex' }}>
            {finished && !error ? Icon : null}
            {!finished && !error ? (
              <CircularProgress color={error ? 'error' : 'primary'} size={20} />
            ) : null}
            {error ? IconError : null}
          </Box>
          {!error ? (
            <span className="ml-5">
              {finished ? 'Finalizado' : 'Configurando o planejamento'}
            </span>
          ) : (
            <span className="ml-5">
              Ops, houve um problema ao carregar o planejamento
            </span>
          )}
        </div>
      </div>
    </ModalShell>
  );
};

ModalLoadSimulation.defaultProps = { onClose: () => undefined };

export default ModalLoadSimulation;
