import { apiV1 } from '../../shared/Api.service';

import { IParticipationGraphsFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Participation/types';
import { GraphsResponse } from '../../../store/modules/AudienceModule/PayTv/Participation/Graphs/Participation.graphs.types';

const path = '/audience/pay-tv/participation';

const manipulateChannels = (channels: string[] | string | undefined) => {
  if (!channels) return [];
  if (Array.isArray(channels)) return channels;

  return [channels];
};

export default async function getParticipationGraphs({
  targets,
  programmingGenders,
  channels,
  periods,
  participationType
}: IParticipationGraphsFilterParams): Promise<GraphsResponse> {
  const { data } = await apiV1.post<GraphsResponse>(path, {
    targets: targets ? [targets] : [],
    programmingGenders: programmingGenders || [],
    channels: manipulateChannels(channels),
    periods: periods ? [periods] : [],
    participationType
  });
  return data;
}
