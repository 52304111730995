import React, { useEffect, useState } from 'react';
import { ResponsiveBar, BarTooltipProps, BarCustomLayerProps } from '@nivo/bar';
import { Card, Typography } from '@mui/material';

interface GenericData {
  label: string;
  value: number;
}

interface StackedHorizontalBarChartProps {
  graphData: GenericData[];
  graphName?: string;
}

// let othersDataset: GenericData[] = [];

const CustomTooltip: React.FC<{
  id: string | number;
  value: number;
  color: string;
  others: any;
}> = ({ id, value, color, others }) => {
  const [othersDataset, setOthersDataset] = useState<GenericData[]>([]);

  useEffect(() => {
    setOthersDataset(others);
  }, [others]);

  return (
    <div
      style={{
        position: 'relative',
        // top: position.y + (id === 'Outros' ? 0 : 150),
        // left: position.x + (id === 'Outros' ? -250 : 10),
        background: 'white',
        padding: '5px',
        borderRadius: '3px',
        zIndex: 9999,
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
      }}
    >
      {id === 'Outros' ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px',
              fontSize: '16px',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span
                style={{
                  display: 'block',
                  width: '16px',
                  height: '16px',
                  backgroundColor: color,
                  marginRight: '5px',
                  borderRadius: '50%'
                }}
              />
              {id}:
            </div>
            {value.toFixed(2)}%
          </div>
          {othersDataset.slice(0, 10).map((dt) => (
            <div
              key={dt.label}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '10px',
                columnGap: '10px',
                // styleName: Caption 1;
                fontSize: '14px'
              }}
            >
              <strong style={{ textTransform: 'capitalize' }}>
                {dt.label.toLowerCase()}:
              </strong>
              <span>{dt.value.toFixed(2)}%</span>
            </div>
          ))}
          {othersDataset.length > 10 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '10px',
                columnGap: '10px',
                // styleName: Caption 1;
                fontSize: '14px'
              }}
            >
              <strong>...</strong>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{ display: 'flex', alignItems: 'center', margin: '10px' }}
          >
            <span
              style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: color,
                marginRight: '5px',
                borderRadius: '50%'
              }}
            />
            <strong>{id}</strong>
          </div>
          <div style={{ display: 'flex', alignItems: 'start', margin: '10px' }}>
            {value.toFixed(2)}%
          </div>
        </>
      )}
    </div>
  );
};

const CustomLabelsLayer: React.FC<
  BarCustomLayerProps<{ category: string }>
> = ({ bars }) => (
  <>
    {bars.map((bar) => (
      <text
        key={bar.key}
        x={bar.x + bar.width / 2}
        y={bar.y - 10} // Position above the bar
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '12px'
        }}
      >
        {Number(bar.data.value ?? 0).toFixed(1)}% {/* Safe value resolution */}
      </text>
    ))}
  </>
);

const formatLegends = (data: GenericData[], itemsPerLegend: number) => {
  const slicesMax = Math.ceil(data.length / itemsPerLegend);

  const slices = Array.from({ length: slicesMax }, (_, index) => {
    return data.slice(index * itemsPerLegend, (index + 1) * itemsPerLegend);
  });

  const legends = slices.map((slice, index) => {
    return {
      anchor: 'bottom',
      direction: 'row',
      justify: false,
      translateX: 0,
      translateY: 60 + index * 40,
      itemsSpacing: 10,
      itemDirection: 'left-to-right',
      itemWidth: 130,
      itemHeight: 20,
      itemOpacity: 1,
      symbolSize: 14,
      symbolShape: 'circle',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1
          }
        }
      ],
      data: slice?.map((item) => ({
        ...item,
        label:
          item?.label?.length > 15
            ? `${item?.label?.slice(0, 15)}...`
            : item?.label
      }))
    };
  });

  const updatedLegends: any = [];

  legends.forEach((legend) => {
    updatedLegends.push(legend);
  });

  return updatedLegends;
};

const StackedHorizontalBarChart: React.FC<StackedHorizontalBarChartProps> = ({
  graphData,
  graphName
}) => {
  const sortedData = [...graphData].sort((a, b) => b.value - a.value);

  const topData: GenericData[] = [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const others: GenericData[] = [];

  sortedData.forEach((item, index) => {
    if (index >= 10 || item.value < 2) {
      others.push(item);
    } else {
      topData.push(item);
    }
  });

  const otherSum = others.reduce((sum, item) => sum + item.value, 0);

  const finalData = [
    ...topData,
    ...(otherSum > 0 ? [{ label: 'Outros', value: otherSum }] : [])
  ];

  const chartData = [
    {
      category: 'Percentage',
      ...Object.fromEntries(finalData.map((item) => [item.label, item.value]))
    }
  ];

  const colorPallete = [
    '#fc343a',
    '#8038fb',
    '#1ec370',
    '#55fdab',
    '#ff6d03',
    '#f35248',
    '#fd8a64',
    '#167ffd',
    '#c4adfd',
    '#e849a7',
    '#d3d3d3'
  ];

  const colors = finalData.map(
    (item, index) =>
      item.label === 'Outros'
        ? colorPallete[colorPallete.length - 1] // Last color for "Outros"
        : colorPallete[index % (colorPallete.length - 1)] // Cycle through palette
  );

  const dataWithColours = finalData.map(
    (item, index) => ({
      ...item,
      color:
        item.label === 'Outros'
          ? colorPallete[colorPallete.length - 1] // Last color for "Outros"
          : colorPallete[index % (colorPallete.length - 1)]
    }) // Cycle through palette}
  );

  const height = 200 + 40 * Math.ceil(finalData.length / 6);

  const renderTooltip = ({
    id,
    value,
    color
  }: BarTooltipProps<{ category: string }>) => {
    return (
      <CustomTooltip id={id} value={value} color={color} others={others} />
    );
  };

  return (
    <Card
      sx={{
        minWidth: '100%',
        minHeight: height,
        alignContent: 'center',
        padding: '5px 25px',
        overflow: 'visible'
      }}
    >
      <Typography variant="h6">{graphName || ''}</Typography>
      <div style={{ height: 200 }}>
        <ResponsiveBar
          data={chartData}
          keys={finalData.map((item) => item.label)}
          indexBy="category"
          layout="horizontal"
          margin={{
            top: 20,
            right: 30,
            left: 60,
            bottom: 48 * Math.ceil(dataWithColours.length / 6) + 40
          }}
          padding={0.3}
          colors={colors}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          label={() => ''}
          enableGridX={false}
          enableGridY={false}
          tooltip={renderTooltip}
          theme={{
            tooltip: {
              container: {
                background: '#333',
                color: '#fff',
                fontSize: '12px'
              }
            }
          }}
          layers={[
            CustomLabelsLayer, // Add custom labels layer
            'bars',
            'legends'
          ]}
          legends={formatLegends(dataWithColours, 6) as any}
        />
      </div>
    </Card>
  );
};

StackedHorizontalBarChart.defaultProps = {
  graphName: undefined
};

export default StackedHorizontalBarChart;
