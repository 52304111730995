import { OptionsList } from '../../../Form/types';

export enum AudiencePayTVTabEnum {
  channels = 'channels',
  programs = 'programs',
  participation = 'participation'
}

export enum AudiencePayTVSubTabEnum {
  comparative = 'comparative',
  radiography = 'radiography',
  ranking = 'ranking',
  summary = 'summary',
  geral = 'geral',
  globo = 'globo',
  genre = 'genre'
}

export type TAudiencePayTVTab = AudiencePayTVTabEnum;
export type TAudiencePayTVSubTab = AudiencePayTVSubTabEnum;

export interface ICommonsFilter {
  target?: OptionsList | undefined;
  timeRange?: OptionsList | undefined;
  timeRanges?: OptionsList[] | undefined;
  newFilter?: boolean;
  setNewFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}
