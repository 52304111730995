import './styles/global.scss';
import './styles/variables.scss';
import 'react-toastify/dist/ReactToastify.css';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import Routes from './routes';
import store from './store';
import loginLocalStorage from './components/Auth/LoginWrapper/Login.LocalStorage';

interface DataLayer {
  event: string;
  user?: {
    id: string;
    type: string;
    agencies: string[];
  };
  simulation?: {
    id?: string;
    agency: {
      mnemonic: string;
      name: string;
      oi: string;
      cnpj: string;
    };
    client: {
      mnemonic: string;
      name: string;
      oi: string;
      cnpj: string;
    };
  };
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

const theme = createTheme({
  ...ptBR,
  typography: { fontFamily: 'var(--font-default)' }
});

window.dataLayer = window.dataLayer || [];

const updateDataLayer = (data: DataLayer) => {
  const state = store.getState();

  const roleStore = loginLocalStorage().profile.get();

  const newData = {
    ...data,
    user: {
      id: state?.auth?.profile?.id,
      type: roleStore || state?.auth?.profile?.role,
      agencies:
        state?.agencies?.data?.map(
          (agency) => `${agency?.me_filial_agencia} - ${agency?.me_agencia}`
        ) || []
    },
    simulation: {
      id: state?.simulation?.id,
      agency: {
        mnemonic:
          state?.simulation?.me_filial_agencia ??
          state?.agencies?.data[0]?.me_filial_agencia,
        oi: state?.simulation?.oi_agencia
          ? String(state?.simulation?.oi_agencia)
          : String(state?.agencies?.data[0]?.oi_agencia) ?? undefined,
        cnpj:
          state?.simulation?.nu_cgc_cpf_agencia ??
          state?.agencies?.data[0]?.nu_cgc_cpf,
        name:
          state?.agencies?.data?.find(
            (agency) =>
              agency?.me_filial_agencia ===
              (state?.simulation?.me_filial_agencia ??
                state?.agencies?.data[0]?.me_filial_agencia)
          )?.nome_agencia ?? state?.agencies?.data[0]?.nome_agencia
      },
      client: {
        mnemonic: state?.simulation?.me_cliente,
        oi: state?.simulation?.oi_cliente
          ? String(state?.simulation?.oi_cliente)
          : undefined,
        cnpj: state?.simulation?.cpf_cnpj_cliente,
        name:
          state?.clients?.data?.all?.find(
            (client) => client?.me_cliente === state?.simulation?.me_cliente
          )?.nome_cliente ?? undefined
      },
      digital: {
        groups: state?.DigitalGroups?.groups
      }
    }
  };

  if (
    window.dataLayer.length > 0 &&
    window.dataLayer[window.dataLayer.length - 1]?.event ===
      'reduxStateChange' &&
    JSON.stringify(
      window.dataLayer[window.dataLayer.length - 1]?.simulation
    ) === JSON.stringify(newData?.simulation) &&
    JSON.stringify(window.dataLayer[window.dataLayer.length - 1]?.user) ===
      JSON.stringify(newData?.user)
  ) {
    return;
  }
  window.dataLayer.push(newData);
};

store.subscribe(() => {
  updateDataLayer({ event: 'reduxStateChange' });
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </ThemeProvider>
);
