export enum TermsTypes {
  LOAD_AGENCIES_REQUEST = '@terms/LOAD_AGENCIES_REQUEST',
  LOAD_AGENCIES_SUCCESS = '@terms/LOAD_AGENCIES_SUCCESS',
  LOAD_AGENCIES_ERROR = '@terms/LOAD_AGENCIES_ERROR'
}

export interface Term {
  id: number;
  term: string;
  version: string;
}

export interface TermsAction {
  type: TermsTypes;
  payload: {
    rows?: Term[] | [];
    count?: number;
    message?: string;
  };
}

export interface TermsResponse {
  data: {
    rows: Term[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface TermsState {
  readonly data: Term[] | [];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
  readonly message: string;
}
