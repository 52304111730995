import './DigitalSimulation.scss';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import ActionBar from '../ActionsButtons/ActionsButtons';
import SubHeaderDigitalSimulation from '../SubHeader';
import ContainerWrapper from '../../ContainerWrapper';
import SubHeader from '../../SubHeader';
import MenuNav from '../../Menu';
import useNavigate from './useNavigate';
import { ModalSimulation } from '../../Simulation';
import ModalExport from '../Summary/components/ModalExport/ModalExport';

const DigitalSimulationWrapper = () => {
  const { simulation } = useAppSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [openModalExport, setOpenModalExport] = useState(false);

  const handleClickExportButton = () => {
    setOpenModalExport(true);
  };

  const { items, buttons } = useNavigate({ handleClickExportButton });

  const handleClickInitSimulation = () => {
    setOpenModal(false);
  };

  return (
    <>
      <ModalExport
        open={openModalExport}
        handleClickClose={() => setOpenModalExport(false)}
      />
      <ModalSimulation
        dataModal={simulation}
        onClickCancel={() => setOpenModal(false)}
        onClickInitSimulation={handleClickInitSimulation}
        open={openModal}
      />
      <SubHeader>
        <SubHeaderDigitalSimulation
          onClickEdit={() => setOpenModal(true)}
          title={simulation?.name || 'Novo planejamento'}
        />
      </SubHeader>
      <ContainerWrapper>
        <div className="top-content" />
        <div className="content-divisor">
          <div className="table-content">
            <div className="d-flex flex-row justify-space-between align-center">
              <MenuNav
                classes={{
                  nav: 'sub-menu mb-4',
                  item: {
                    li: 'sub-menu__item',
                    selected: 'sub-menu--selected',
                    icon: 'sub-menu__icon',
                    button: 'sub-menu__item__button digital-menu'
                  }
                }}
                itemsMenu={items}
              />
              <ActionBar buttons={buttons} />
            </div>
            <Box sx={{ marginTop: '24px' }}>
              <Outlet />
            </Box>
          </div>
        </div>
      </ContainerWrapper>
    </>
  );
};

export default DigitalSimulationWrapper;
