export enum InterestsTypes {
  LOAD_INTERESTS_REQUEST = '@markets/LOAD_INTERESTS_REQUEST',
  LOAD_INTERESTS_SUCCESS = '@markets/LOAD_INTERESTS_SUCCESS',
  LOAD_INTERESTS_ERROR = '@markets/LOAD_INTERESTS_ERROR'
}

export type Interest = {
  value: number | string;
  label: string;
  grupo?: string;
  segmentation: string;
};

export interface genrericGamResponse {
  value: string;
  id: number;
}

export interface InterestAction {
  type: InterestsTypes;
  payload: {
    rows: Interest[];
    count: number;
    message?: string;
  };
}

export interface InterestsState {
  readonly data: Interest[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface InterestsResponseGam {
  data: {
    rows: Interest[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface InterestsResponse {
  data: {
    rows: Interest[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface InterestResponse {
  data: {
    row: Interest;
    count: number;
  };
  status: number;
  msg: string;
}
