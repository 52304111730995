import { CpmResponse } from '../../../store/modules/Digital/Cpm/Cpm.types';

import { apiV1 } from '../../shared/Api.service';

const path = '/secondary-cpms';

export default async function getCpms(props: any): Promise<CpmResponse> {
  const { data } = await apiV1.get<CpmResponse>(path, {
    params: { ...props }
  });

  return data;
}
