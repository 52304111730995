import { AppThunk } from '../..';
import {
  getAllCommitments,
  saveCommitment
} from '../../../services/MediaSimulator/Commitments.service';

import {
  CommitmentData,
  CommitmentsAction,
  CommitmentsResponse,
  CommitmentsTypes
} from './Commitments.types';

export const LoadCommitmentsSuccess = (
  response: CommitmentsResponse
): CommitmentsAction => {
  const payload: CommitmentsAction = {
    type: CommitmentsTypes.LOAD_COMMITMENTS_SUCCESS,
    payload: {
      count: response?.data?.count,
      rows: response?.data?.rows,
      message: response?.msg
    }
  };

  return payload;
};

const LoadCommitmentsError = (error: string): CommitmentsAction => {
  const payload: CommitmentsAction = {
    type: CommitmentsTypes.LOAD_COMMITMENTS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingCommitments = () => ({
  type: CommitmentsTypes.LOAD_COMMITMENTS_REQUEST,
  payload: {}
});

const SaveCommitmentRequest = () => ({
  type: CommitmentsTypes.SAVE_COMMITMENT_REQUEST,
  payload: {}
});

const SaveCommitmentsError = (error: string): CommitmentsAction => {
  const payload: CommitmentsAction = {
    type: CommitmentsTypes.SAVE_COMMITMENT_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const SaveCommitmentSuccess = (
  response: CommitmentsResponse
): CommitmentsAction => {
  const payload: CommitmentsAction = {
    type: CommitmentsTypes.SAVE_COMMITMENT_SUCCESS,
    payload: {
      count: response?.data?.count,
      rows: response?.data?.rows,
      message: response?.msg
    }
  };

  return payload;
};

export const LoadCommitmentsRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingCommitments());
    const response = await getAllCommitments();
    dispatch(LoadCommitmentsSuccess(response));
  } catch (error: any) {
    dispatch(
      LoadCommitmentsError(
        error instanceof Error ? error.message : error?.msg ?? ''
      )
    );
  }
};

export const SaveCommitment =
  (data: CommitmentData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(SaveCommitmentRequest());
      const response = await saveCommitment(data);
      dispatch(SaveCommitmentSuccess(response));
      return response;
    } catch (error: any) {
      const errorMessage =
        error instanceof Error ? error.message : error?.msg ?? '';
      dispatch(SaveCommitmentsError(errorMessage));
      throw error;
    }
  };
