import * as Yup from 'yup';
import { Roles } from '../store/modules/Users/Users.types';
import { AuthProfile } from '../store/modules/Auth/Auth.types';

const simulationsValidator = (profile: AuthProfile | null, isEdit: boolean) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, 'Informe um nome com no máximo 50 caracteres')
      .required('Informe o Título do Plano'),
    st: Yup.object().when('isNotAgencyOrEdit', {
      is: () => profile?.role !== Roles?.AGENCY && !isEdit,
      then: Yup.object().nullable().required('Informe a Plataforma'),
      otherwise: Yup.object().nullable()
    }),
    agency: Yup.object()
      .when('isAgency', {
        // perfil é agência
        is: () => profile?.role === Roles?.AGENCY,
        then: Yup.object()
          .shape({
            oi_agencia: Yup.number().required('Selecione uma agência')
          })
          .required('Selecione uma agência'),

        otherwise: Yup.object().nullable()
      })
      .label('Agência'),
    client: Yup.object().nullable().label('Anunciante'),
    cnpj: Yup.object().nullable().label('CNPJ')
  });
};

export default simulationsValidator;
