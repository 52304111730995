import { YupError } from '../../../../helpers/HandleErrors';
import { CpmData } from '../Cpm/Cpm.types';
import { Filter } from '../Filters/Filters.types';

export enum GroupsTypes {
  SAVE_PARTIAL_GROUP_DATA = '@groups/SAVE_PARTIAL_GROUPS_FORM_DATA',
  SAVE_GROUP_DATA = '@groups/SAVE_GROUPS_FORM_DATA',
  SAVE_GROUPS_FORM_DATA_ERROR = '@groups/SAVE_GROUPS_FORM_DATA_ERROR',
  SAVE_GROUPS = '@groups/SAVE_GROUPS',
  CLEAR_GROUP_DATA = '@groups/CLEAR_GROUPS_FORM_DATA',
  ADD_GROUP = '@groups/ADD_GROUP',
  CLONE_GROUP = '@groups/CLONE_GROUP',
  REMOVE_GROUPS = '@groups/REMOVE_GROUPS',
  REMOVE_GROUPS_FORM_ERROR = '@groups/REMOVE_GROUPS_FORM_ERROR',
  RESET_GROUPS = '@groups/RESET_GROUPS'
}

export interface GroupData {
  filter: Filter;
  cpm?: CpmData;
  availableImpressions?: number;
}

export interface IGroup {
  data?: GroupData;
  erros?: YupError;
  partialData?: Partial<GroupData>;
  validated?: boolean;
  submited?: boolean;
  planningValidated?: boolean;
  planningSubmited?: boolean;
  id: number;
  name: string;
  error?: boolean;
  uuid: string;
  changed?: boolean;
  export?: boolean;
}

export interface GroupsState {
  readonly groups: IGroup[];
}

export interface GroupAction {
  type: GroupsTypes;
  payload: IGroup;
  groups?: IGroup[];
}
