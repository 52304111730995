import {
  GendersResponse,
  GendersResponseGam
} from '../../../store/modules/Digital/Genders/Genders.types';
import { apiV1 } from '../../shared/Api.service';

const path = '/digital/gender-segment';

export default async function getGenders(props: any): Promise<GendersResponse> {
  const { data } = await apiV1.get<GendersResponseGam>(path, {
    params: { ...props }
  });
  return {
    data: {
      rows: data?.data?.rows?.map((item) => {
        return { value: item.id, label: item.value };
      }),
      count: data.data.count
    },
    status: data.status,
    msg: data.msg
  };
}
