import { Reducer } from 'redux';
import {
  ParticipationFilterGraphsAction,
  ParticipationFilterGraphsState,
  ParticipationFilterGraphsType
} from './Participation.graphs.types';

const channelInitialState: ParticipationFilterGraphsState = {
  error: false,
  loading: false,
  data: [],
  count: null,
  requests: 0
};

const reducer: Reducer<
  ParticipationFilterGraphsState,
  ParticipationFilterGraphsAction
> = (state = channelInitialState, action): ParticipationFilterGraphsState => {
  switch (action.type) {
    case ParticipationFilterGraphsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count,
        requests: state.requests + 1
      };
    }

    case ParticipationFilterGraphsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null,
        requests: state.requests + 1
      };
    }

    case ParticipationFilterGraphsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case ParticipationFilterGraphsType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
