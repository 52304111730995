import { IGroup } from '../../../store/modules/Digital/Groups/Groups.types';
import { ImpressionsResponse } from '../../../store/modules/Digital/Impressions/Impressions.types';

import { apiV1 } from '../../shared/Api.service';

const path = '/digital/forecast-data';

interface ImpressionsRequest {
  simulacoes: {
    simulacao: {
      periodoCampanha: {
        de: string;
        ate: string;
      };
      geotarget: {
        id: number;
        value: string;
      }[];
      customTargets: {
        order: number;
        targets: {
          gamId: number;
          value: string;
          category: string;
          order: number;
        }[];
      }[];
    };
  }[];
}

export default async function getImpressions(
  startDate: string,
  endDate: string,
  groups: IGroup[]
): Promise<ImpressionsResponse> {
  const simulacoes: ImpressionsRequest['simulacoes'] = [];
  groups.forEach((group) => {
    const interestTarget = group.data?.filter?.data?.interests?.map(
      (interest, index) => {
        if (!interest.label || !interest.value) {
          throw new Error('Invalid interest');
        }
        return {
          gamId: Number(interest?.value),
          value: interest?.label ?? '',
          category: 'interesse',
          order: index + 1
        };
      }
    );
    const agesTarget = group.data?.filter?.data?.age?.map((age, index) => {
      if (!age.label || !age.value) {
        throw new Error('Invalid age');
      }
      return {
        gamId: Number(age?.value),
        value: age?.label ?? '',
        category: 'faixa etaria',
        order: index + 1 + (interestTarget?.length ?? 0)
      };
    });
    const genderTarget = {
      order: (agesTarget?.length ?? 0) + 1 + (interestTarget?.length ?? 0),
      category: 'genero',
      gamId: Number(group.data?.filter?.data?.gender?.value),
      value: group.data?.filter?.data?.gender?.label ?? ''
    };

    simulacoes.push({
      simulacao: {
        periodoCampanha: {
          de: startDate,
          ate: endDate
        },
        geotarget:
          group.data?.filter?.data?.locations?.map((location) => {
            if (!location.label || !location.value) {
              throw new Error('Invalid location');
            }

            return {
              id: Number(location.value),
              value: location.label
            };
          }) || [],
        customTargets: [
          {
            order: 1,
            targets: [
              ...(interestTarget ?? []),
              ...(agesTarget ?? []),
              genderTarget
            ]
          }
        ]
      }
    });
  });

  const response = await apiV1.post(path, {
    simulacoes: [...simulacoes]
  });

  return response.data;
}
