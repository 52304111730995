enum PayTvColors {
  'A&E' = '#A0522D',
  'AMC' = '#8B4513',
  'ANIMAL PLANET' = '#556B2F',
  'AXN' = '#B22222',
  'BAND NEWS' = '#4682B4',
  'BANDSPORTS' = '#DC143C',
  'CARTOON NETWORK' = '#DAA520',
  'CINEMAX' = '#D2691E',
  'CNN BRASIL' = '#2F4F4F',
  'COMEDY CENTRAL' = '#FFD700',
  'DISCOVERY CHANNEL' = '#000080',
  'DISCOVERY HOME & HEALTH' = '#808000',
  'DISCOVERY ID' = '#696969',
  'DISCOVERY KIDS' = '#8B008B',
  'DISCOVERY TURBO' = '#FF4500',
  'DISNEY CHANNEL' = '#6495ED',
  'E! ENTERTAINMENT' = '#FF69B4',
  'ESPN' = '#CD5C5C',
  'ESPN2' = '#2E8B57',
  'ESPN 3' = '#483D8B',
  'ESPN4' = '#FF6347',
  'FILM & ARTS' = '#8A2BE2',
  'FOOD NETWORK' = '#D8BFD8',
  'FOX SPORTS2' = '#A52A2A',
  'FX' = '#7FFF00',
  'GLOBONEWS' = '#00CED1',
  'GLOOB' = '#1E90FF',
  'GNT' = '#FFE4B5',
  'H2' = '#5F9EA0',
  'HBO' = '#9400D3',
  'HBO2' = '#FF00FF',
  'HGTV' = '#32CD32',
  'HISTORY' = '#B8860B',
  'JOVEM PAN NEWS' = '#B03060',
  'LIFETIME' = '#E9967A',
  'MEGAPIX' = '#8B0000',
  'MTV' = '#008B8B',
  'MULTISHOW' = '#BA55D3',
  'NATIONAL GEOGRAPHIC' = '#FFA500',
  'NICK JR' = '#00FA9A',
  'NICKELODEON' = '#FF4500',
  'PARAMOUNT NETWORK' = '#4682B4',
  'PREMIERE 2' = '#FFB6C1',
  'PREMIERE 3' = '#CD853F',
  'PREMIERE 4' = '#708090',
  'PREMIERE 5' = '#D4AF37',
  'PREMIERE 6' = '#8F7A4E',
  'PREMIERE 7' = '#4169E1',
  'PREMIERE 8' = '#FF1493',
  'PREMIERE CLUBES' = '#BC8F8F',
  'PROGRAMADORA A&E' = '#2F6A47',
  'PROGRAMADORA AMC' = '#6B4F28',
  'PROGRAMADORA BOX BRASIL' = '#7B68EE',
  'PROGRAMADORA CNN BRASIL' = '#4F9A9E',
  'PROGRAMADORA GLOBO' = '#E89A7E',
  'PROGRAMADORA GRUPO DISNEY' = '#D32F2F',
  'PROGRAMADORA HBO' = '#004D4D',
  'PROGRAMADORA JOVEM PAN NEWS' = '#9932CC',
  'PROGRAMADORA GRUPO NEWCO' = '#00FF7F',
  'PROGRAMADORA SONY' = '#FF8C00',
  'ESPN3' = '#483D8B',
  'FOX_SPORTS 2' = '#A52A2A',
  'PREMIERE 8 MOSAICO' = '#FF1493',
  'ADULT SWIM' = '#FF7F50',
  'AGROMAIS' = '#6495ED',
  'ARTE 1' = '#FFF8DC',
  'CANAL EMPREENDER' = '#8FCF6E',
  'CINECANAL' = '#00FFFF',
  'CARTOONITO' = '#00008B',
  'SABOR & ARTE' = '#6EAE10',
  'SONY' = '#E9CDF5',
  'SONY MOVIES' = '#A9A9A9',
  'SPACE' = '#006400',
  'SPORTV' = '#BDB76B',
  'SPORTV 2' = '#B275AA',
  'SPORTV 3' = '#26B68B',
  'STAR CHANNEL' = '#936BA8',
  'STUDIO UNIVERSAL' = '#8AF835',
  'TCM' = '#4F5897',
  'TELECINE ACTION' = '#474D12',
  'TELECINE CULT' = '#8FBC8F',
  'TELECINE FUN' = '#142C65',
  'TELECINE PIPOCA' = '#4A2C1E',
  'TELECINE PREMIUM' = '#399E77',
  'TELECINE TOUCH' = '#3BFEBE',
  'TERRA VIVA' = '#9C8123',
  'TLC' = '#00BFFF',
  'TNT NOVELAS' = '#38D396',
  'TNT SERIES' = '#29F591',
  'VIVA' = '#629FA1',
  'WARNER' = '#FFFAF0',
  'TRACE BRAZUCA' = '#228B22',
  'TRAVEL BOX BRAZIL' = '#DCDCDC',
  'UNIVERSAL TV' = '#F8F8FF',
  'USA' = '#BFE754',
  'Markket' = '#054F5D',
  'TNT' = '#551984'
}

export default PayTvColors;
