import LocalStorage, { LocalStorageProps } from '../../../helpers/LocalStorage';

export interface LoginStore {
  authenticated: LocalStorageProps<boolean>;
  authenticateMethod: LocalStorageProps<string>;
  lastRoute: LocalStorageProps<string>;
  profile: LocalStorageProps<string>;
  codeVerifier: LocalStorageProps<string>;
  expires: LocalStorageProps<string>;
}

export default function loginLocalStorage(): LoginStore {
  return {
    authenticated: LocalStorage<boolean>(`authenticated`),
    authenticateMethod: LocalStorage<string>(`authenticate-method`),
    lastRoute: LocalStorage<string>(`last-route`),
    profile: LocalStorage<string>(`profile`),
    codeVerifier: LocalStorage<string>(`code-verifier`),
    expires: LocalStorage<string>(`expires`)
  };
}
