import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AudiencePayTVSubTabEnum, AudiencePayTVTabEnum } from '../types';
import AudiencePayTvParticipationContainer from './AudiencePayTvParticipationContainer';
import AudiencePayTvParticipationGeralPanel from './Geral/AudiencePayTvParticipationGeralPanel';
import AudiencePayTvParticipationGenrePanel from './Genre/AudiencePayTvParticipationGenrePanel';
import AudiencePayTvParticipationGloboPanel from './Globo/AudiencePayTvParticipationGloboPanel';
import KantarDescription from '../../../KantarDescription';

const AudiencebyPayTvParticipation: React.FC = () => {
  const params = useParams() as {
    tab: AudiencePayTVTabEnum.participation;
    subtab:
      | AudiencePayTVSubTabEnum.genre
      | AudiencePayTVSubTabEnum.geral
      | AudiencePayTVSubTabEnum.globo;
  };

  const subtab = useMemo(() => {
    if (AudiencePayTVTabEnum.participation) {
      return params.subtab || AudiencePayTVSubTabEnum.geral;
    }

    return params.subtab;
  }, [params.subtab]);

  const getTab = () => {
    if (subtab === AudiencePayTVSubTabEnum.genre)
      return <AudiencePayTvParticipationGenrePanel />;
    if (subtab === AudiencePayTVSubTabEnum.globo)
      return <AudiencePayTvParticipationGloboPanel />;

    return <AudiencePayTvParticipationGeralPanel />;
  };

  return (
    <AudiencePayTvParticipationContainer subtab={params?.subtab}>
      {getTab()}
      <KantarDescription />
    </AudiencePayTvParticipationContainer>
  );
};

export default AudiencebyPayTvParticipation;
