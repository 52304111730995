import { TypeTV } from '../../../services/shared/Api.service';

export enum TargetsTypes {
  LOAD_TARGETS_REQUEST = '@targets/LOAD_TARGETS_REQUEST',
  LOAD_TARGETS_SUCCESS = '@targets/LOAD_TARGETS_SUCCESS',
  LOAD_TARGETS_ERROR = '@targets/LOAD_TARGETS_ERROR'
}

export type Target = {
  name: string;
  kantarName: string;
};
export interface TargetAction {
  type: TargetsTypes;
  payload: {
    rows: Target[];
    count: number;
    message?: string;
    typeTV: TypeTV.OPEN | TypeTV.CLOSED;
  };
}

export interface TargetsState {
  readonly closedTv: {
    readonly typeTV: TypeTV.CLOSED;
    readonly data: Target[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly count: number;
  };
  readonly openedTv: {
    readonly typeTV: TypeTV.OPEN;
    readonly data: Target[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly count: number;
  };
}

export interface TargetsResponse {
  data: {
    rows: Target[];
    count: number;
  };
  status: number;
  msg: string;
}
