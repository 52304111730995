import { AppThunk } from '../..';
import getTerms from '../../../services/MediaSimulator/Terms.service';

import { TermsAction, TermsResponse, TermsTypes } from './Terms.types';

export const LoadTermsSuccess = (response: TermsResponse): TermsAction => {
  const payload: TermsAction = {
    type: TermsTypes.LOAD_AGENCIES_SUCCESS,
    payload: {
      count: response?.data?.count,
      rows: response?.data?.rows,
      message: response?.msg
    }
  };

  return payload;
};

const LoadTermsError = (error: string): TermsAction => {
  const payload: TermsAction = {
    type: TermsTypes.LOAD_AGENCIES_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingTerms = () => ({
  type: TermsTypes.LOAD_AGENCIES_REQUEST,
  payload: {}
});

export const LoadTermsRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingTerms());
    const response = await getTerms();
    dispatch(LoadTermsSuccess(response));
  } catch (error: any) {
    dispatch(
      LoadTermsError(error instanceof Error ? error.message : error?.msg ?? '')
    );
  }
};
