export enum LocationsTypes {
  LOAD_LOCATIONS_REQUEST = '@markets/LOAD_LOCATIONS_REQUEST',
  LOAD_LOCATIONS_SUCCESS = '@markets/LOAD_LOCATIONS_SUCCESS',
  LOAD_LOCATIONS_ERROR = '@markets/LOAD_LOCATIONS_ERROR'
}

export type Location = {
  value: number | string;
  label: string;
};

export interface LocationAction {
  type: LocationsTypes;
  payload: {
    rows: Location[];
    count: number;
    message?: string;
  };
}

export interface LocationsState {
  readonly data: Location[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}
export interface genrericGamResponse {
  value: string;
  id: number;
}

export interface LocationsResponseGam {
  data: {
    rows: genrericGamResponse[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface LocationsResponse {
  data: {
    rows: Location[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface LocationResponse {
  data: {
    row: genrericGamResponse;
    count: number;
  };
  status: number;
  msg: string;
}
