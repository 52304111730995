import './index.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import setValueProp from 'lodash/set';
import getValueProp from 'lodash/get';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import DatePickerInput from '../../Form/DatePickerInput';
import FormContainer from '../../Form/FormContainer';
import { RemoveFiltersFormError } from '../../../store/modules/Digital/CampaignPeriod/CampaignPeriod.actions';
import { SetSimulation } from '../../../store/modules/Simulation/Simulation.actions';
import validateFields from './CampaignPeriod.validations';
import { FiltersDateData } from '../../../store/modules/Digital/CampaignPeriod/CampaignPeriod.types';

const CampaignPeriod = () => {
  const dispatch = useAppDispatch();
  const { simulation } = useAppSelector((state) => state);
  const [filterChange, setFilterChanged] = useState<boolean>(false);

  const actualFormRef = useRef<FormHandles>(null);

  const tomorrow = useMemo(() => {
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000; // Convert to UTC
    const gmtMinus3Time = utc + -3 * 60 * 60000; // Apply GMT-3 offset
    const gmtMinus3Date = new Date(gmtMinus3Time);
    gmtMinus3Date.setDate(gmtMinus3Date.getDate() + 1); // Increment day to get tomorrow
    gmtMinus3Date.setHours(0, 0, 0, 0); // Set time to midnight
    return gmtMinus3Date;
  }, []);

  const [dateFromCampaign, setDateFromCampaign] = useState<Date | null>(
    simulation.DigitalDates?.startdate
      ? new Date(simulation.DigitalDates?.startdate)
      : tomorrow
  );
  const [dateToCampaign, setDateToCampaign] = useState<Date | null>(
    simulation.DigitalDates?.EndDate
      ? new Date(simulation.DigitalDates?.EndDate)
      : null
  );

  const minDateBuy = tomorrow;

  const handleClearError = (names: string | string[]) => {
    const arrNames = [];
    if (typeof names === 'string') {
      arrNames.push(names);
    }
    if (Array.isArray(names)) {
      arrNames.push(...names);
    }
    arrNames.forEach((name) => {
      let formErros = actualFormRef.current?.getErrors();
      if (isEmpty(formErros)) {
        dispatch(RemoveFiltersFormError(simulation.currentSceneryId));
        return;
      }
      if (formErros && getValueProp(formErros, name)) {
        formErros = setValueProp(formErros, name, '');
      }

      if (formErros) {
        const isValid = Object.keys(formErros).reduce((acc, key, _, arr) => {
          const isValueEmpty = isEmpty(getValueProp(formErros, key));

          if (arr.length === 1 && isValueEmpty) {
            return true;
          }
          return acc && isValueEmpty;
        }, isEmpty(actualFormRef.current?.getFieldError(name)));

        if (isValid)
          dispatch(RemoveFiltersFormError(simulation.currentSceneryId));
      }
    });
  };

  const handleFormSubmit = async (formData: FiltersDateData) => {
    actualFormRef?.current?.setErrors({});

    try {
      await validateFields(formData);

      if (
        !!simulation.DigitalDates?.startdate &&
        !!simulation.DigitalDates.EndDate
      ) {
        dispatch(
          SetSimulation({
            DigitalDates: {
              startdate: simulation.DigitalDates?.startdate,
              EndDate: simulation.DigitalDates?.EndDate
            },
            changed: filterChange
          })
        );
      }
    } catch (validationErrors: any) {
      if (validationErrors instanceof Yup.ValidationError) {
        const errors = validationErrors.inner.reduce((acc: any, err: any) => {
          if (err.path) {
            acc[err.path] = err.message;
          }
          return acc;
        }, {} as Record<string, string>);

        actualFormRef.current?.setErrors(errors);
      }
    }
  };

  const handleFromCampaignChange = (newDate: Date | null) => {
    setFilterChanged(true);

    setDateFromCampaign(newDate);
  };

  const handleToCampaignChange = (newDate: Date | null) => {
    setFilterChanged(true);
    setDateToCampaign(newDate);
  };

  useEffect(() => {
    dispatch(
      SetSimulation({
        submited: false,
        DigitalDates: {
          startdate: dateFromCampaign?.getTime(),
          EndDate: dateToCampaign?.getTime()
        },
        changed: true
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromCampaign, dateToCampaign]);

  useEffect(() => {
    if (simulation.submited) actualFormRef.current?.submitForm();
  }, [simulation.submited]);

  return (
    <div className="form-divisor">
      <div className="form-side">
        <div className="input-wrapper">
          <label className="label-title" htmlFor="label-title">
            Período da Campanha
          </label>
          <div className="input-wrapper__divisor">
            <FormContainer
              className="campaignDate"
              error
              formRef={actualFormRef}
              fullWidth
              id={`simulation-form-${simulation.currentScenery}`}
              onSubmit={handleFormSubmit}
            >
              <DatePickerInput
                label="Início"
                defaultValue={dateFromCampaign}
                minDate={minDateBuy}
                name="dates.campaign.from"
                onChange={handleFromCampaignChange}
                onClearError={handleClearError}
                shrink
                required
              />
              <div className="space" />
              <DatePickerInput
                label="Fim"
                defaultValue={dateToCampaign}
                minDate={dateFromCampaign || undefined}
                name="dates.campaign.to"
                onChange={handleToCampaignChange}
                onClearError={handleClearError}
                shrink
              />
            </FormContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPeriod;
