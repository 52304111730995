import { toast } from 'react-toastify';
import { AgeAction, AgesResponse, AgesTypes } from './Ages.types';
import getAges from '../../../../services/MediaSimulator/Digital/Ages.service';
import { AppThunk } from '../../..';

const LoadAgeSuccess = (response: AgesResponse) => {
  const payload: AgeAction = {
    type: AgesTypes.LOAD_AGES_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadAgeError = (error: string) => {
  const payload: AgeAction = {
    type: AgesTypes.LOAD_AGES_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingAges = () => ({
  type: AgesTypes.LOAD_AGES_REQUEST
});

const LoadAgeRequest =
  (props: any): AppThunk =>
  async (dispatch: (arg0: { type: any }) => void) => {
    try {
      dispatch(LoadingAges());
      const response = await getAges({ ...props });
      dispatch(LoadAgeSuccess(response));
    } catch (error) {
      dispatch(LoadAgeError(error instanceof Error ? error.message : ''));
      toast.error('Ops, houve um problema ao tentar carregar as Idades.');
    }
  };

export default LoadAgeRequest;
