import './ActionsButtons.scss';
import React from 'react';

import ButtonApp from '../../ButtonApp';

interface ActionsButtonsProps {
  buttons: {
    id: number;
    classes?: {
      button?: string;
      loading?: string;
      tagManager?: string;
    };
    title: string;
    info?: string;
    disabled?: boolean;
    visible: boolean;
    loading?: boolean;
    alwaysOpenInfo?: boolean;
    infoVisibleOnHover?: boolean;
    onClick(): void;
  }[];
}

const ActionsBar: React.FC<ActionsButtonsProps> = ({
  buttons
}: ActionsButtonsProps) => {
  return buttons.length ? (
    <div className="d-flex flex-row">
      {buttons.map((button) => (
        <div key={button.id} className="btn-wrapper">
          <ButtonApp
            {...button}
            classes={{
              ...button.classes,
              button: `btn--med btn--transparent ${
                button.classes?.button || ''
              }`
            }}
          />
        </div>
      ))}
    </div>
  ) : null;
};

export default ActionsBar;
