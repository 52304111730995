import './SelectInput.scss';
import React, {
  useEffect,
  useRef,
  memo,
  useState,
  useLayoutEffect
} from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import { useUnform } from '../../../hooks';

interface Props extends SelectProps<any> {
  name: string;
  dataClarityMask?: boolean;
  options: Readonly<
    {
      label: string;
      value: any;
    }[]
  >;
  onClearError?(name: string): void;
}

const SelectInput = ({
  name,
  label,
  defaultValue,
  options,
  onChange,
  required,
  onClearError,
  dataClarityMask,
  externalError,
  placeholder = '',
  ...rest
}: Props & {
  // eslint-disable-next-line react/require-default-props
  externalError?: string | undefined;
  // eslint-disable-next-line react/require-default-props
  placeholder?: string | undefined;
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const unformProps = useUnform(name);

  const [valueSelected, setValueSelected] = useState(defaultValue || '');

  // eslint-disable-next-line no-bitwise
  const error = unformProps?.error || externalError;

  useEffect(() => {
    if (unformProps) {
      unformProps.registerField({
        name: unformProps.fieldName,
        ref: selectRef.current,
        getValue: () => valueSelected,
        clearValue: () => setValueSelected(''),
        setValue: (_, value: any) => {
          setValueSelected(value || defaultValue || '');
        }
      });
    }
  }, [unformProps, valueSelected, defaultValue]);

  useLayoutEffect(() => {
    if (required && selectRef.current) {
      const el = selectRef.current.querySelector<HTMLElement>(
        'fieldset > legend > span'
      );
      if (el) el.innerHTML += '&nbsp;*';
    }
  }, [name, required]);

  const handleFocus = () => {
    if (unformProps) {
      unformProps.clearError();
    }
  };

  const handleBlur = () => {
    if (onClearError) onClearError(name);
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        error={!!error}
        id={`label-select-${name.replace(/\./g, '-')}`}
        required={required}
      >
        {placeholder.length ? placeholder : label}
      </InputLabel>
      <Select
        ref={selectRef}
        defaultValue={defaultValue}
        error={!!error}
        id={`select-${name.replace(/\./g, '-')}`}
        label={label}
        labelId={`label-select-${name.replace(/\./g, '-')}`}
        onBlur={handleBlur}
        onChange={(event: SelectChangeEvent, child: React.ReactNode) => {
          setValueSelected(event.target.value);
          if (onChange) onChange(event, child);
        }}
        onFocus={handleFocus}
        value={valueSelected}
        {...attributeMaskOrUnmask(dataClarityMask)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200
            }
          }
        }}
        {...rest}
      >
        {options.length ? (
          options.map((opt) => (
            <MenuItem
              key={opt.value}
              value={opt.value}
              {...attributeMaskOrUnmask(dataClarityMask)}
            >
              {opt.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key="Sem opções"
            value="Sem opções"
            disabled
            {...attributeMaskOrUnmask(dataClarityMask)}
          >
            Sem opções
          </MenuItem>
        )}
      </Select>
      <FormHelperText className="red">{error}</FormHelperText>
    </FormControl>
  );
};

SelectInput.defaultProps = {
  dataClarityMask: undefined,
  onClearError: undefined
};

export default memo(SelectInput);
