import { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import AudienceByPayTvChannels from './Channels/AudiencePayTvChannelsPanel';
import AudienceByPayTvPrograms from './Programs/AudiencePayTvProgramsPanel';
import AudiencebyPayTvParticipation from './Participation/AudiencePayTvParticipationPanel';

import {
  AudiencePayTVSubTabEnum,
  AudiencePayTVTabEnum,
  TAudiencePayTVSubTab,
  TAudiencePayTVTab
} from './types';

export default function AudiencePayTvContainer() {
  const navigate = useNavigate();
  const params = useParams() as {
    tab: TAudiencePayTVTab;
    subtab: TAudiencePayTVSubTab;
  };

  useEffect(() => {
    if (!params.subtab) {
      if (!params.tab) {
        navigate(AudiencePayTVSubTabEnum.comparative);
      } else if (params.tab === AudiencePayTVTabEnum.channels) {
        navigate(AudiencePayTVSubTabEnum.comparative);
      } else if (params.tab === AudiencePayTVTabEnum.programs) {
        navigate(AudiencePayTVSubTabEnum.ranking);
      } else if (params.tab === AudiencePayTVTabEnum.participation) {
        navigate(AudiencePayTVSubTabEnum.geral);
      }
    }
  }, [params.tab, params.subtab, navigate]);

  switch (params.tab) {
    case AudiencePayTVTabEnum.channels:
      return <AudienceByPayTvChannels />;
    case AudiencePayTVTabEnum.programs:
      return <AudienceByPayTvPrograms />;
    case AudiencePayTVTabEnum.participation:
      return <AudiencebyPayTvParticipation />;
    default:
      return <AudienceByPayTvChannels />;
  }
}
