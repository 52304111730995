import { toast } from 'react-toastify';
import {
  LocationAction,
  LocationsResponse,
  LocationsTypes
} from './Locations.types';
import getLocations from '../../../../services/MediaSimulator/Digital/Locations.service';
import { AppThunk } from '../../..';

const LoadLocationSuccess = (response: LocationsResponse) => {
  const payload: LocationAction = {
    type: LocationsTypes.LOAD_LOCATIONS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadLocationError = (error: string) => {
  const payload: LocationAction = {
    type: LocationsTypes.LOAD_LOCATIONS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingLocations = () => ({
  type: LocationsTypes.LOAD_LOCATIONS_REQUEST
});

const LoadLocationRequest =
  (): AppThunk => async (dispatch: (arg0: { type: any }) => void) => {
    try {
      dispatch(LoadingLocations());
      const response = await getLocations();
      dispatch(LoadLocationSuccess(response));
    } catch (error) {
      dispatch(LoadLocationError(error instanceof Error ? error.message : ''));
      toast.error('Ops, houve um problema ao tentar carregar as praças.');
    }
  };

export default LoadLocationRequest;
