import { TypeTV, apiV1 } from '../shared/Api.service';
import { TargetsResponse } from '../../store/modules/Targets/Targets.types';

const path = '/targets';

export default async function getTargets(
  typeTV: TypeTV
): Promise<TargetsResponse> {
  const { data } = await apiV1.get<TargetsResponse>(path, {
    params: { typeTV }
  });
  return data;
}
