import { apiV1 } from '../../../shared/Api.service';
import {
  ICommonProps,
  ProgrammingGenderResponse
} from '../../../../store/modules/AudienceModule/PayTv/Participation/ProgrammingGenders/Audience.programmingGenders.types';

const path = '/audience/pay-tv/participation/programming-gender';

export default async function getParticipationProgrammingGender({
  channels
}: ICommonProps): Promise<ProgrammingGenderResponse> {
  const { data } = await apiV1.get<ProgrammingGenderResponse>(path, {
    params: {
      channels: channels?.map((channel) => channel.value) ?? undefined
    }
  });

  return data;
}
