import React from 'react';
import { Box, Grid } from '@mui/material';

import paths from '../../../../../routes/paths';
import SubMenuTabs from '../../../Tabs/OpenTv/SubMenuTabs';
import IconComparativeChannels from '../../../../../assets/images/channels-comparative-icon.svg';
import IconSummary from '../../../../../assets/images/summary.svg';
import classesGTM from '../../../../../config/classesGTM';
import { AudiencePayTVSubTabEnum } from '../types';
import AudiencePayTvParticipationGenreFilter from './Genre/AudiencePayTvParticipationGenreFilter';
import AudiencePayTvParticipationGeralFilter from './Geral/AudiencePayTvParticipationGeralFilter';
import AudiencePayTvParticipationGloboFilter from './Globo/AudiencePayTvParticipationGloboFilter';

const { childrens, path } = paths.home.childrens.audience;

const AudiencePayTvParticipationContainer: React.FC<{
  children: React.ReactNode;
  subtab: AudiencePayTVSubTabEnum;
}> = ({ children, subtab }) => {
  const getTab = () => {
    if (subtab === AudiencePayTVSubTabEnum.genre)
      return <AudiencePayTvParticipationGenreFilter />;
    if (subtab === AudiencePayTVSubTabEnum.globo)
      return <AudiencePayTvParticipationGloboFilter />;

    return <AudiencePayTvParticipationGeralFilter />;
  };

  return (
    <>
      <SubMenuTabs
        items={[
          {
            icon: IconComparativeChannels,
            itemId: 1,
            title: 'Participação Geral',
            path: `${path}/${childrens.payTv.path}/${childrens.payTv.childrens.participation.path}/${childrens.payTv.childrens.participation.childrens.geral.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.openTV.channels.comparative
            }
          },
          {
            icon: IconSummary,
            itemId: 2,
            title: 'Participação Globo',
            path: `${path}/${childrens.payTv.path}/${childrens.payTv.childrens.participation.path}/${childrens.payTv.childrens.participation.childrens.globo.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.openTV.channels.radiography
            }
          },
          {
            icon: IconSummary,
            itemId: 3,
            title: 'Emissoras por Gênero',
            path: `${path}/${childrens.payTv.path}/${childrens.payTv.childrens.participation.path}/${childrens.payTv.childrens.participation.childrens.genre.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.openTV.channels.radiography
            }
          }
        ]}
      />
      <Grid container height="100%" padding={5} paddingTop={0} spacing={0}>
        <Grid
          className="competitive__container"
          item
          direction="row"
          width="100%"
          border="none"
        >
          {getTab()}
        </Grid>
        <Box
          component="hr"
          sx={{
            display: 'block',
            height: '1px',
            border: 0,
            borderTop: '1px solid #ccc',
            margin: '24px 0 0 0',
            padding: 0,
            width: '100%'
          }}
        />
        <Grid container direction="column" item width="calc(100%)">
          <Grid height="calc(100% - 60px)" item width="100%" paddingTop={5}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AudiencePayTvParticipationContainer;
