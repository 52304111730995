import './AudiencePayTvParticipationGeralFilters.scss';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormHandles } from '@unform/core';
import { Box, Grid, ListItemText, Typography } from '@mui/material';

import FormContainer from '../../../../../../Form/FormContainer';
import { ParticipationGraphsEnum, SelectFilter, TypePeriod } from '../../types';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import { OptionsList } from '../../../../../../Form/types';
import {
  checkboxClasses,
  chipProps,
  FIXED_VALUE,
  listboxSigleProps
} from '../../../../../../Competitive/CompetitiveFilter/constants';
import AutocompleteInput from '../../../../../../Form/AutocompleteInput';
import ButtonApp from '../../../../../../ButtonApp';
import classesGTM from '../../../../../../../config';
import { LoadAudienceFilterParticipationChannelsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Participation/Channels/Audience.channels.actions';
import { LoadPayTvAudienceFilterParticipationProgrammingGendersRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Participation/ProgrammingGenders/Audience.programmingGenders.actions';
import {
  ClearParticipationGraphsData,
  LoadParticipationGraphsRequest
} from '../../../../../../../store/modules/AudienceModule/PayTv/Participation/Graphs/Participation.graphs.actions';
import useAudiencePayTvParticipationGeralFilterLoadData from '../../Geral/Filter/hooks/useAudiencePayTvParticipationGeralFilterLoadData';
import AlertInfoCard from '../../../../../Cards/AlertInfoCard';
import LoadAudienceFilterTargetsRequest from '../../../../../../../store/modules/AudienceModule/PayTv/Targets/Audience.targets.actions';

const AudienceByPayTvParticipationGeralFilter: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();

  const inputsConfig = useMemo(
    () => ({
      period: {
        name: 'period',
        defaultValue: '2024'
      },
      target: {
        name: 'target',
        defaultValue: 'Total Domicílios'
      }
    }),
    []
  );

  const { audience } = useAppSelector((state) => state);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ready, setReady] = useState<boolean>(false);
  const [selectionFilter, setSelectionFilter] = useState<SelectFilter>();
  const [selection, setSelection] = useState<OptionsList[]>([]);
  const [subimitDisabled, setSubimitDisabled] = useState<boolean>(true);
  const [target, setTarget] = useState<OptionsList>();

  const [selectionOptions, setSelectionOptions] = useState<
    OptionsList[] | undefined
  >([]);
  const [period, setPeriod] = useState<TypePeriod>();

  const { periodOptions } = useAudiencePayTvParticipationGeralFilterLoadData({
    period,
    target
  });

  const targetsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.targets.count) {
      return audience?.payTv?.targets.data
        .filter((targetOption) => targetOption.name)
        .map((targetOption) => ({
          label: targetOption.name,
          value: targetOption.name
        }));
    }

    return [];
  }, [audience?.payTv?.targets]);

  useEffect(() => {
    dispatch(LoadAudienceFilterParticipationChannelsRequest({}));
    dispatch(LoadPayTvAudienceFilterParticipationProgrammingGendersRequest({}));
    dispatch(LoadAudienceFilterTargetsRequest());

    return () => {
      dispatch(ClearParticipationGraphsData());
    };
  }, [dispatch]);

  useEffect(() => {
    setPeriod(periodOptions?.[0]);
  }, [periodOptions, setPeriod]);

  useEffect(() => {
    if (!target) {
      setTarget(
        targetsOptions.find(
          (option) => option.value === inputsConfig.target.defaultValue
        )
      );
    }
  }, [inputsConfig.target, targetsOptions, target, setTarget]);

  const selectFilterOptions: SelectFilter[] = useMemo<SelectFilter[]>(() => {
    if (
      audience?.payTv?.participation?.channels?.count &&
      audience?.payTv?.participation?.programingGenders.count
    ) {
      const channels = audience?.payTv?.participation?.channels?.data;
      const programingGenders =
        audience?.payTv?.participation?.programingGenders?.data;

      const options: SelectFilter[] = channels
        .map((channel) => {
          return {
            value: channel.nm_emissora,
            label: channel.nm_emissora,
            group: 'Emissora',
            segmentation: 'Emissora'
          };
        })
        .concat(
          programingGenders.map((prog) => {
            return {
              value: prog.nm_genero,
              label: prog.nm_genero,
              group: 'Gênero',
              segmentation: 'Gênero'
            };
          })
        );
      return options;
    }

    return [];
  }, [
    audience?.payTv?.participation?.channels?.count,
    audience?.payTv?.participation?.channels?.data,
    audience?.payTv?.participation?.programingGenders.count,
    audience?.payTv?.participation?.programingGenders?.data
  ]);

  useEffect(() => {
    if (selectionFilter?.segmentation === 'Emissora') {
      dispatch(
        LoadPayTvAudienceFilterParticipationProgrammingGendersRequest({
          channels: [
            {
              value: selectionFilter.value.toString(),
              label: selectionFilter.label
            }
          ]
        })
      );
    } else if (selectionFilter?.segmentation === 'Gênero') {
      dispatch(
        LoadAudienceFilterParticipationChannelsRequest({
          programmingGenders: {
            value: selectionFilter.value.toString(),
            label: selectionFilter.label
          }
        })
      );
    }
  }, [selectionFilter, dispatch]);

  const channelsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.participation.channels.count) {
      return audience?.payTv?.participation.channels.data
        .filter((channelOption) => channelOption.nm_emissora)
        .map((channelOption) => ({
          label: channelOption.nm_emissora,
          value: channelOption.nm_emissora
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  }, [
    audience?.payTv?.participation.channels.count,
    audience?.payTv?.participation.channels.data
  ]);

  const programmingGendersOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.participation.programingGenders.count) {
      return audience?.payTv?.participation.programingGenders.data
        .filter((channelOption) => channelOption.nm_genero)
        .map((channelOption) => ({
          label: channelOption.nm_genero,
          value: channelOption.nm_genero
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  }, [audience?.payTv?.participation.programingGenders]);

  const loadData = useCallback(() => {
    if (selectionFilter?.segmentation === 'Emissora') {
      dispatch(
        LoadParticipationGraphsRequest({
          channels: selectionFilter?.value as string | undefined,
          programmingGenders: selection.map((item) => item.value),
          participationType: [ParticipationGraphsEnum.participationChannel],
          targets: target?.value,
          periods: period?.value
        })
      );
    } else {
      dispatch(
        LoadParticipationGraphsRequest({
          channels: selection.map((item) => item.value),
          programmingGenders: [selectionFilter?.value] as string[] | undefined,
          participationType: [ParticipationGraphsEnum.participationGender],
          targets: target?.value,
          periods: period?.value
        })
      );
    }
  }, [
    dispatch,
    period?.value,
    selection,
    selectionFilter?.segmentation,
    selectionFilter?.value,
    target?.value
  ]);

  useEffect(() => {
    if (selectionFilter?.segmentation === 'Emissora') {
      setSelectionOptions(programmingGendersOptions);
    } else if (selectionFilter?.segmentation === 'Gênero') {
      setSelectionOptions(channelsOptions);
    }
  }, [selectionFilter, channelsOptions, programmingGendersOptions]);

  const handleSelecFilterChange = (value: SelectFilter) => {
    setSelectionFilter(value);
    setSelection([FIXED_VALUE]);
    setSelectionOptions([]);
    formRef.current?.setFieldValue('Selecionar', [FIXED_VALUE]);
  };

  const handleSelectionChange = (value: OptionsList[]) => {
    setSelection(value);
  };

  const handleSendClick = () => {
    loadData();
    return;
  };

  const handleTargetChange = (option: TypePeriod) => {
    if (option) {
      setTarget(option);
    }
  };

  const handlePeriodChange = (option: TypePeriod) => {
    if (option) {
      setPeriod(option);
    }
  };

  const filterSegmentation = (options: any[], { inputValue }: any) => {
    return options.filter(
      (opt) =>
        opt?.label?.toLowerCase().search(inputValue?.toLowerCase()) !== -1 ||
        opt?.grupo?.toLowerCase().search(inputValue?.toLowerCase()) !== -1
    );
  };

  useEffect(() => {
    setSubimitDisabled(
      audience.payTv.participation.channels.loading ||
        audience.payTv.participation.programingGenders.loading ||
        audience.payTv.channels.loading ||
        audience.payTv.programmingGenders.loading ||
        !selectionFilter ||
        !selection?.length
    );
  }, [
    audience.payTv.participation.channels.loading,
    audience.payTv.participation.programingGenders.loading,
    audience.payTv.channels.loading,
    audience.payTv.programmingGenders.loading,
    selectionFilter,
    selection
  ]);

  const isCurrentYearSelected = () => {
    return `${new Date().getFullYear()}` === `${period?.label}`;
  };

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Typography variant="h6">Filtros</Typography>
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'nowrap'
        }}
      >
        <AutocompleteInput
          data-testid="selectFilter"
          dataClarityMask
          disableCloseOnSelect
          label="Selecionar o Filtro"
          loading={
            audience.payTv.participation.channels.loading &&
            audience.payTv.participation.programingGenders.loading
          }
          name="SelectFilter"
          maxDisplay={2}
          onChange={handleSelecFilterChange}
          groupBy={(opt) => opt.segmentation}
          filterOptions={filterSegmentation}
          getOptionLabel={(option) =>
            `${option.segmentation.toUpperCase()} - ${option.label}`
          }
          renderOption={(props, option) => (
            <li {...props}>
              <ListItemText primary={option.label} secondary={option.grupo} />
            </li>
          )}
          options={selectFilterOptions || []}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(selection)}
          label="Selecionar"
          name="Selecionar"
          data-testid="Select"
          disableCloseOnSelect
          maxDisplay={2}
          fixedValue={FIXED_VALUE}
          defaultValue={selection}
          disabled={
            audience?.payTv?.participation.channels.loading ||
            audience.payTv.participation.programingGenders.loading ||
            !(!!selectionOptions?.length && selectionOptions?.length >= 1)
          }
          loading={
            audience?.payTv?.participation.channels.loading ||
            audience.payTv.participation.programingGenders.loading
          }
          ListboxProps={listboxSigleProps}
          options={selectionOptions || []}
          disableValidateValInOpts
          multiple
          required
          dataClarityMask
          onChange={handleSelectionChange}
          sx={{
            width: '25%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Target"
          name={inputsConfig.target?.name}
          data-testid="target"
          disabled={audience?.payTv?.targets.loading}
          loading={audience?.payTv?.targets.loading}
          ListboxProps={listboxSigleProps}
          options={targetsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={targetsOptions.find(
            (option) => option.value === inputsConfig.target.defaultValue
          )}
          onChange={handleTargetChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Período"
          name={inputsConfig.period?.name}
          data-testid="period"
          disabled={
            audience?.payTv?.periods.loading ||
            audience?.payTv?.periods.count === 0
          }
          loading={audience?.payTv?.periods.loading}
          ListboxProps={listboxSigleProps}
          options={periodOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={periodOptions[0]}
          onChange={handlePeriodChange}
          sx={{
            width: '20%'
          }}
        />
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          marginTop={1}
          sx={{
            marginLeft: 'auto'
          }}
        >
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--primary',
              loading: 'competitive-filter__buttons--loading',
              tagManager: classesGTM.audience.filter
            }}
            // loading={false}
            disabled={subimitDisabled}
            onClick={handleSendClick}
            title="Filtrar"
          />
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--secondary',
              tagManager: classesGTM.audience.clearFilter
            }}
            disabled={false}
            onClick={() => formRef.current?.reset() || setReady(false)}
            title="Limpar Filtros"
          />
        </Grid>
      </FormContainer>
      {isCurrentYearSelected() && (
        <Box sx={{ marginTop: '60px' }}>
          <AlertInfoCard text="O período selecionado exibe dados acumulados do ano até o último mês completo." />
        </Box>
      )}
      {audience.payTv.participation.graphs.data.length &&
      !audience.payTv.participation.graphs.data[0].data?.length ? (
        <Box sx={{ marginTop: '60px' }}>
          <AlertInfoCard
            text=" Esta seleção de filtros não possui dados suficientes para gerar o gráfico.
      Tente outra combinação."
          />
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AudienceByPayTvParticipationGeralFilter;
