export interface ParticipationFilterGraphs {
  tp_categoria: string;
  nm_emissora: string;
  nm_genero: string;
  nm_programadora: string;
  ds_periodo: string;
  nr_ano: number;
  nm_target: string;
  pc_audiencia: string;
  percentual_audiencia: string;
  participationType: string;
}

export interface IParticipationsGraphs {
  data: ParticipationFilterGraphs[];
  participationType: string;
}

export interface ParticipationFilterGraphsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: IParticipationsGraphs[];
  readonly count: number | null;
  readonly requests: number;
}

export interface GraphsResponse {
  data: {
    rows: IParticipationsGraphs[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum ParticipationFilterGraphsType {
  REQUEST = '@audience/openTv/participation/graphs/load',
  SUCESS = '@audience/openTv/participation/graphs/success',
  ERROR = '@audience/openTv/participation/graphs/error',
  CLEAR = '@audience/openTv/participation/graphs/clear'
}

export interface ParticipationFilterGraphsAction {
  type: ParticipationFilterGraphsType;
  payload: Omit<GraphsResponse, 'status'>;
}
