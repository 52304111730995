import * as Yup from 'yup';
import { FiltersDateData } from '../../../store/modules/Digital/CampaignPeriod/CampaignPeriod.types';

const campaignPeriodSchema = Yup.object().shape({
  dates: Yup.object()
    .shape({
      campaign: Yup.object().shape({
        from: Yup.date()
          .typeError('Informe uma data válida.')
          .required('Data "de" da campanha é obrigatória.'),
        to: Yup.date()
          .typeError('Informe uma data válida.')
          .required('Data "até" da campanha é obrigatória.')
      })
    })
    .required()
});

const validateFields = (formData: Partial<FiltersDateData>) => {
  return campaignPeriodSchema.validate(formData, {
    abortEarly: false
  });
};

export default validateFields;
