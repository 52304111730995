import { Reducer } from 'redux';
import { GendersState, GendersTypes, GenderAction } from './Genders.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const targetInitialState: GendersState = { ...defaultData };

const reducer: Reducer<GendersState, GenderAction> = (
  state = targetInitialState,
  action
): GendersState => {
  switch (action.type) {
    case GendersTypes.LOAD_GENDERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        error: false,
        loading: false
      };
    }
    case GendersTypes.LOAD_GENDERS_ERROR: {
      return {
        ...state,
        data: [],
        error: true,
        loading: false
      };
    }
    case GendersTypes.LOAD_GENDERS_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
