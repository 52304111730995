import { RefObject } from 'react';
import { FormHandles } from '@unform/core';
import { YupError } from '../../../../helpers/HandleErrors';

export enum FiltersTypes {
  SET_FILTERS_FORMREF = '@digitalCampaignPeriod/SET_FILTERS_FORMREF',
  SAVE_PARTIAL_FILTERS_FORM_DATA = '@digitalCampaignPeriod/SAVE_PARTIAL_FILTERS_FORM_DATA',
  SAVE_FILTERS_FORM_DATA = '@digitalCampaignPeriod/SAVE_FILTERS_FORM_DATA',
  SAVE_FILTERS_FORM_DATA_ERROR = '@digitalCampaignPeriod/SAVE_FILTERS_FORM_DATA_ERROR',
  CLEAR_FILTERS_FORM_DATA = '@digitalCampaignPeriod/CLEAR_FILTERS_FORM_DATA',
  REMOVE_FILTERS_SCENERY = '@digitalCampaignPeriod/REMOVE_FILTERS_SCENERY',
  REMOVE_FILTERS_FORM_ERROR = '@digitalCampaignPeriod/REMOVE_FILTERS_FORM_ERROR',
  RESET_FILTERS = '@digitalCampaignPeriod/RESET_FILTERS'
}

export enum ClosedPeriodTypesEnum {
  MONTH = 'month',
  QUARTER = 'quarter',
  SEMESTER = 'semester',
  CLOSED_YEAR = 'closedYear'
}

export type ClosedPeriodTypes =
  | ClosedPeriodTypesEnum.MONTH
  | ClosedPeriodTypesEnum.QUARTER
  | ClosedPeriodTypesEnum.SEMESTER
  | ClosedPeriodTypesEnum.CLOSED_YEAR;

export type ClosedPeriodValue = {
  label: string;
  value: string;
};

export type ClosedPeriod = {
  year: Date | string;
  type: ClosedPeriodTypes;
  value?: ClosedPeriodValue;

  /** @deprecated use value field instead */
  month?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  quarter?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  semester?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  closedYear?: ClosedPeriodValue;
};

export interface FiltersDateData {
  startdate?: Date | number | null | undefined;
  EndDate?: Date | number | null | undefined;
}

export interface Filter {
  ref?: RefObject<FormHandles>;
  data?: FiltersDateData;
  erros?: YupError;
  partialData?: Partial<FiltersDateData>;
  sceneryId?: string;
  error?: boolean;
}

export interface FiltersState {
  readonly forms: Filter[];
}

export interface FilterAction {
  type: FiltersTypes;
  payload: Filter;
}
