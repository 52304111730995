import { useCallback, useEffect, useRef, useState } from 'react';
import GroupSettings from '../GroupSettings';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  SaveGroupsFormData,
  RemoveGroups,
  CloneGroup
} from '../../../store/modules/Digital/Groups/Groups.actions';
import { IGroup } from '../../../store/modules/Digital/Groups/Groups.types';

const useGroupSettings = () => {
  const { DigitalGroups } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const [on, setOn] = useState<number | null>(null);
  const [isRenaming, setIsRenaming] = useState<number | null>(null);
  const [newGroupName, setNewGroupName] = useState<string>('');

  const ref = useRef<HTMLElement>(null);
  const toggle = (id: number) => setOn(id === on ? null : id);

  const handleClickOutside = (evt: any) => {
    if (!ref?.current?.contains(evt.target)) {
      setOn(null);
    }
  };

  const handleRenameChange = (value: string) => {
    setNewGroupName(value);
  };

  const handleRenameSubmit = (currentGroup: IGroup) => {
    dispatch(
      SaveGroupsFormData({
        ...currentGroup,
        name: newGroupName
      })
    );
    setIsRenaming(null);
    setNewGroupName('');
  };

  const handleRenameCancel = () => {
    setIsRenaming(null);
    setNewGroupName('');
  };

  const handleKeyPress = (e: React.KeyboardEvent, currentGroup: IGroup) => {
    if (e.key === 'Enter') {
      handleRenameSubmit(currentGroup);
    } else if (e.key === 'Escape') {
      handleRenameCancel();
    }
  };

  const handleClickRename = useCallback((currentGroup: IGroup) => {
    setIsRenaming(currentGroup.id);
    setNewGroupName(currentGroup.name);
  }, []);

  const handleClickDuplicate = useCallback(
    (currentGroup: IGroup) => {
      dispatch(CloneGroup(currentGroup.id));
    },
    [dispatch]
  );

  const handleClickDelete = useCallback(
    (currentGroup: IGroup) => {
      if (DigitalGroups.groups.length > 1) {
        dispatch(RemoveGroups(currentGroup.id));
      }
    },
    [DigitalGroups.groups, dispatch]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const Settings = useCallback(
    (currentGroup: IGroup) => {
      return (
        <GroupSettings
          visible={on === currentGroup.id}
          groups={DigitalGroups.groups}
          refValue={ref}
          handleClickRename={() => handleClickRename(currentGroup)}
          handleClickDuplicate={() => handleClickDuplicate(currentGroup)}
          handleClickDelete={() => handleClickDelete(currentGroup)}
        />
      );
    },
    [
      on,
      DigitalGroups.groups,
      handleClickRename,
      handleClickDuplicate,
      handleClickDelete
    ]
  );

  return {
    on,
    isRenaming,
    handleKeyPress,
    handleRenameChange,
    handleRenameCancel,
    toggle,
    Settings
  };
};

export default useGroupSettings;
