import { toast } from 'react-toastify';
import {
  InterestAction,
  InterestsResponse,
  InterestsTypes
} from './Interests.types';
import getInterests from '../../../../services/MediaSimulator/Digital/Interests.service';
import { AppThunk } from '../../..';

const LoadInterestSuccess = (response: InterestsResponse) => {
  const payload: InterestAction = {
    type: InterestsTypes.LOAD_INTERESTS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadInterestError = (error: string) => {
  const payload: InterestAction = {
    type: InterestsTypes.LOAD_INTERESTS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingInterests = () => ({
  type: InterestsTypes.LOAD_INTERESTS_REQUEST
});

const LoadInterestRequest =
  (props: any): AppThunk =>
  async (dispatch: (arg0: { type: any }) => void) => {
    try {
      dispatch(LoadingInterests());
      const response = await getInterests({ ...props });
      dispatch(LoadInterestSuccess(response));
    } catch (error) {
      dispatch(LoadInterestError(error instanceof Error ? error.message : ''));
      toast.error('Ops, houve um problema ao tentar carregar as Idades.');
    }
  };

export default LoadInterestRequest;
