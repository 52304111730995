import { Reducer } from 'redux';
import { TermsAction, TermsState, TermsTypes } from './Terms.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0,
  message: ''
};

const targetInitialState: TermsState = {
  ...defaultData
};

const reducer: Reducer<TermsState, TermsAction> = (
  state = targetInitialState,
  action
): TermsState => {
  switch (action?.type) {
    case TermsTypes.LOAD_AGENCIES_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.rows || [],
        count: action?.payload?.count || 0,
        error: false,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case TermsTypes.LOAD_AGENCIES_ERROR: {
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case TermsTypes.LOAD_AGENCIES_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
