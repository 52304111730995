export enum AgesTypes {
  LOAD_AGES_REQUEST = '@markets/LOAD_AGES_REQUEST',
  LOAD_AGES_SUCCESS = '@markets/LOAD_AGES_SUCCESS',
  LOAD_AGES_ERROR = '@markets/LOAD_AGES_ERROR'
}

export type Age = {
  value: number | string;
  label: string;
};

export interface AgeAction {
  type: AgesTypes;
  payload: {
    rows: Age[];
    count: number;
    message?: string;
  };
}

export interface AgesState {
  readonly data: Age[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface genrericGamResponse {
  value: string;
  id: number;
}

export interface AgesResponseGam {
  data: {
    rows: genrericGamResponse[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface AgesResponse {
  data: {
    rows: Age[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface AgeResponse {
  data: {
    row: genrericGamResponse;
    count: number;
  };
  status: number;
  msg: string;
}
