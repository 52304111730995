import { Reducer } from 'redux';
import {
  LocationsState,
  LocationsTypes,
  LocationAction
} from './Locations.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const targetInitialState: LocationsState = { ...defaultData };

const reducer: Reducer<LocationsState, LocationAction> = (
  state = targetInitialState,
  action
): LocationsState => {
  switch (action.type) {
    case LocationsTypes.LOAD_LOCATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        error: false,
        loading: false
      };
    }
    case LocationsTypes.LOAD_LOCATIONS_ERROR: {
      return {
        ...state,
        data: [],
        error: true,
        loading: false
      };
    }
    case LocationsTypes.LOAD_LOCATIONS_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
