/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import {
  AudienceFilterProgrammingGendersAction,
  AudienceFiltersProgrammingGendersType,
  ICommonProps,
  ProgrammingGenderResponse
} from './Audience.programmingGenders.types';
import { AppThunk } from '../../../../..';
import getParticipationProgrammingGender from '../../../../../../services/Audience/PayTv/Participation/Audience.payTV.participation.programin-gender';

const LoadAudienceFilterProgrammingGendersSuccess = (
  response: ProgrammingGenderResponse
) => {
  const payload: AudienceFilterProgrammingGendersAction = {
    type: AudienceFiltersProgrammingGendersType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterProgrammingGendersError = (error: string) => {
  const payload: AudienceFilterProgrammingGendersAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersProgrammingGendersType.ERROR
  };

  return payload;
};

const LoadingAudienceProgrammingGendersFilter = () => ({
  type: AudienceFiltersProgrammingGendersType.REQUEST,
  payload: {}
});

export const ClearAudienceProgrammingGendersData = () => ({
  type: AudienceFiltersProgrammingGendersType.CLEAR,
  payload: {}
});

export const LoadPayTvAudienceFilterParticipationProgrammingGendersRequest =
  ({ channels }: ICommonProps): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceProgrammingGendersFilter());
      const response = await getParticipationProgrammingGender({ channels });

      dispatch(LoadAudienceFilterProgrammingGendersSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterProgrammingGendersError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de programação.'
      );
    }
  };
