import { Reducer } from 'redux';
import {
  EnumSimulationsTypesRequest,
  SimulationsTypeAction,
  SimulationsTypeState
} from './SimulationsType.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0,
  message: ''
};

const targetInitialState: SimulationsTypeState = {
  ...defaultData
};

const reducer: Reducer<SimulationsTypeState, SimulationsTypeAction> = (
  state = targetInitialState,
  action
): SimulationsTypeState => {
  switch (action?.type) {
    case EnumSimulationsTypesRequest.LOAD_SIMULATIONS_TYPE_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.rows || [],
        count: action?.payload?.count || 0,
        error: false,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case EnumSimulationsTypesRequest.LOAD_SIMULATIONS_TYPE_ERROR: {
      return {
        ...state,
        data: [],
        error: false,
        loading: true,
        message: action?.payload?.message || ''
      };
    }
    case EnumSimulationsTypesRequest.SIMULATIONS_TYPE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
