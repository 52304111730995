import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../../../../hooks';
import StackedHorizontalBarChart from '../../../../Charts/StackedHorizontalBarChart';
import { ICommonsFilter } from '../../types';
import { IParticipationGraphsValues, ParticipationGraphsEnum } from '../types';
import Loading from '../../../../../Loading';

const AudiencePayTvParticipationGeralPanel: React.FC<ICommonsFilter> = () => {
  const { audience } = useAppSelector((state) => state);
  const [graphsValues, setGraphsValues] = useState<IParticipationGraphsValues>({
    gen: [],
    channels: []
  });

  useEffect(() => {
    const graphsData = audience.payTv.participation.graphs.data;
    const genGraph = graphsData
      .find(
        (item) => item.participationType === ParticipationGraphsEnum.generalGen
      )
      ?.data.map((item) => ({
        label: item.nm_genero,
        value: parseFloat(item.percentual_audiencia)
      }));

    const channelsGraphs = graphsData
      .find(
        (item) => item.participationType === ParticipationGraphsEnum.programmer
      )
      ?.data.map((item) => ({
        label: item.nm_programadora,
        value: parseFloat(item.percentual_audiencia)
      }));

    setGraphsValues({
      gen: genGraph,
      channels: channelsGraphs
    });
  }, [audience]);

  return (
    <>
      {(audience.payTv.participation.graphs.loading && (
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      )) || (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '40px' }}>
          {graphsValues.gen && (
            <StackedHorizontalBarChart
              graphData={graphsValues.gen}
              graphName="Gêneros TV por assinatura"
            />
          )}
          {graphsValues.channels && (
            <StackedHorizontalBarChart
              graphData={graphsValues.channels}
              graphName="Programadoras"
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AudiencePayTvParticipationGeralPanel;
