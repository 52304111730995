export enum GendersTypes {
  LOAD_GENDERS_REQUEST = '@markets/LOAD_GENDERS_REQUEST',
  LOAD_GENDERS_SUCCESS = '@markets/LOAD_GENDERS_SUCCESS',
  LOAD_GENDERS_ERROR = '@markets/LOAD_GENDERS_ERROR'
}

export type Gender = {
  value: number | string;
  label: string;
};

export interface GenderAction {
  type: GendersTypes;
  payload: {
    rows: Gender[];
    count: number;
    message?: string;
  };
}

export interface GendersState {
  readonly data: Gender[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface genrericGamResponse {
  value: string;
  id: number;
}

export interface GendersResponseGam {
  data: {
    rows: genrericGamResponse[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface GendersResponse {
  data: {
    rows: Gender[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface GenderResponse {
  data: {
    row: genrericGamResponse;
    count: number;
  };
  status: number;
  msg: string;
}
