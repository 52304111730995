import Box from '@mui/material/Box';
import { Skeleton } from '@mui/material';

import { IGroup } from '../../../../../store/modules/Digital/Groups/Groups.types';
import './AvaliableImpressions.scss';
import AppSVG from '../../../../SVG';
import info from '../../../../../assets/images/info.svg';

interface IAvaliableImpressionsProps {
  group: IGroup;
}

const AvaliableImpressions = ({ group }: IAvaliableImpressionsProps) => {
  const impressions = group.data?.cpm?.impressions;
  const impressionsAvaliables = group.data?.availableImpressions;
  const calcLineWidth = (calc1?: number, calc2?: number) => {
    if (!calc1 || !calc2) return '0%';

    return `${(calc2 * 100) / calc1}%`;
  };

  return (
    <Box component="div" className="avaliableImpressions">
      <Box component="div" className="avaliableImpressions__label">
        Planejamento de impressões
      </Box>
      <Box component="div" className="avaliableImpressions__graphs">
        <Box component="div" className="avaliableImpressions__graphs__line">
          <Box
            component="div"
            className="avaliableImpressions__graphs__line avaliableImpressions__graphs__line__blue"
            sx={{ width: calcLineWidth(impressionsAvaliables, impressions) }}
          />
        </Box>
        <Box component="div" className="avaliableImpressions__graphs__values">
          <Box
            component="div"
            className="avaliableImpressions__graphs__values__infos"
          >
            <Box
              component="div"
              className="avaliableImpressions__graphs__values__infos__color avaliableImpressions__graphs__values__infos__color__blue"
            />
            {impressions?.toLocaleString('pt-BR')}
          </Box>
          <Box
            component="div"
            className="avaliableImpressions__graphs__values__infos__label"
          >
            Impressões
          </Box>
        </Box>
        <Box component="div" className="avaliableImpressions__graphs__values">
          <Box
            component="div"
            className="avaliableImpressions__graphs__values__infos"
          >
            <Box
              component="div"
              className="avaliableImpressions__graphs__values__infos__color avaliableImpressions__graphs__values__infos__color__purple"
            />
            {impressionsAvaliables?.toLocaleString('pt-BR')}
          </Box>
          <Box
            component="div"
            className="avaliableImpressions__graphs__values__infos__label"
          >
            Impressões disponíveis
          </Box>
        </Box>
      </Box>
      <Box component="div" className="avaliableImpressions__disclaimer">
        <AppSVG loader={<Skeleton height={24} width={24} />} src={info} />
        <Box
          component="div"
          className="avaliableImpressions__disclaimer__label"
        >
          Total de impressões disponíveis de acordo com a segmentação escolhida
        </Box>
      </Box>
      <Box component="div" className="avaliableImpressions__estimatedPanning">
        Este é um planejamento estimado e não garante reserva no inventário.
      </Box>
    </Box>
  );
};

export default AvaliableImpressions;
