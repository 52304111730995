import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import AudienceSubHeader from '../../SubHeader/AudienceSubHeader';
import SubHeader from '../../../SubHeader';
import paths from '../../../../routes/paths';
import classesGTM from '../../../../config/classesGTM';

const { childrens } =
  paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas;

const AudienceOpenTvRegularPlazasPanel = () => {
  const title = 'TV Aberta | Praças Regulares';
  const menuItems = useMemo(
    () => [
      {
        index: 0,
        label: 'Emissoras',
        path: childrens.channels.path,
        className: classesGTM.tabs.audienceOpenTvChannels,
        id: 'audience-open-tv-channels'
      },
      {
        index: 1,
        label: 'Programas',
        path: childrens.programs.path,
        className: classesGTM.tabs.audienceOpenTvPrograms,
        id: 'audience-open-tv-programs'
      }
    ],
    []
  );

  return (
    <Box height="calc(100% - 72px)" sx={{ flexGrow: 1 }}>
      <SubHeader>
        <AudienceSubHeader title={title} menuItems={menuItems} />
      </SubHeader>
      <Outlet />
    </Box>
  );
};

export default AudienceOpenTvRegularPlazasPanel;
