import { toast } from 'react-toastify';
import getCpms from '../../../../services/MediaSimulator/Digital/cpm.service';
import { CpmAction, CpmResponse, CpmsTypes } from './Cpm.types';
import { AppThunk } from '../../..';

const LoadCpmsSuccess = (response: CpmResponse) => {
  const payload: CpmAction = {
    type: CpmsTypes.LOAD_CPMS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadCpmError = (error: string) => {
  const payload: CpmAction = {
    type: CpmsTypes.LOAD_CPMS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingCpms = () => ({
  type: CpmsTypes.LOAD_CPMS_REQUEST
});

const LoadCpmRequest =
  (props: any): AppThunk =>
  async (dispatch: (arg0: { type: any }) => void) => {
    try {
      dispatch(LoadingCpms());
      const response = await getCpms({ ...props });
      dispatch(LoadCpmsSuccess(response));
    } catch (error) {
      dispatch(LoadCpmError(error instanceof Error ? error.message : ''));
      toast.error('Ops, houve um problema ao tentar carregar os cpms.');
    }
  };

export default LoadCpmRequest;
