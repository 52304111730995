import {
  AgesResponse,
  AgesResponseGam
} from '../../../store/modules/Digital/Ages/Ages.types';
import { apiV1 } from '../../shared/Api.service';

const path = '/digital/age-segment';

export default async function getAges(props: any): Promise<AgesResponse> {
  const { data } = await apiV1.get<AgesResponseGam>(path, {
    params: { ...props }
  });
  return {
    data: {
      rows: data?.data?.rows?.map((item) => {
        return { value: item.id, label: item.value };
      }),
      count: data.data.count
    },
    status: data.status,
    msg: data.msg
  };
}
