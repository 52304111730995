/* eslint-disable camelcase */
import {
  GetAllSimulationsQueryParams,
  SimulationResponse,
  SimulationsQueryParams,
  SimulationsResponse
} from '../../store/modules/Simulations/Simulations.types';
import { apiV1, ApiErrorResponse } from '../shared/Api.service';
import { SimulationData } from '../../store/modules/Simulation/Simulation.types';

const path = '/simulations';

export async function getAllSimulations({
  search,
  limit = 10,
  offset = 0,
  orderDir,
  orderBy
}: SimulationsQueryParams): Promise<SimulationsResponse> {
  const params: GetAllSimulationsQueryParams = {
    limit,
    offset
  };

  if (search) {
    params.search = search;
  }

  if (orderBy) {
    params.order = `${orderBy}:${orderDir}`;
  }

  const { data } = await apiV1.get<SimulationsResponse>(path, { params });

  return data;
}

export async function saveSimulation(
  simulation: SimulationData,
  callbackUpload: (progress: ProgressEvent) => void,
  isDuplicate = false
): Promise<SimulationResponse> {
  const {
    uuid,
    name,
    cpf_cnpj_cliente,
    me_cliente,
    oi_cliente,
    me_filial_agencia,
    nu_cgc_cpf_agencia,
    oi_agencia,
    scenarios,
    simulation_type_id
  } = simulation;

  const { data } = await apiV1.post<SimulationResponse>(
    `${path}/${isDuplicate ? '' : '?isAutosave=true'}`,
    {
      uuid,
      name,
      cpf_cnpj_cliente,
      me_cliente,
      oi_cliente,
      me_filial_agencia,
      nu_cgc_cpf_agencia,
      oi_agencia,
      simulationTypeId: simulation_type_id,
      simulationData: { scenarios }
    },
    {
      onUploadProgress: callbackUpload
    }
  );

  return data;
}

export async function saveSimulationDigital(
  simulation: SimulationData,
  callbackUpload: (progress: ProgressEvent) => void,
  isDraft = false
): Promise<SimulationResponse> {
  const {
    uuid,
    name,
    DigitalDates,
    cpf_cnpj_cliente,
    me_cliente,
    oi_cliente,
    me_filial_agencia,
    nu_cgc_cpf_agencia,
    oi_agencia,
    groups,
    simulation_type_id
  } = simulation;

  const { data } = await apiV1.post<SimulationResponse>(
    `${path}/${isDraft ? '?isAutosave=true' : ''}`,
    {
      uuid,
      name,
      cpf_cnpj_cliente,
      me_cliente,
      oi_cliente,
      me_filial_agencia,
      nu_cgc_cpf_agencia,
      oi_agencia,
      simulationTypeId: simulation_type_id,
      simulationData: { DigitalDates, groups }
    },
    {
      onUploadProgress: callbackUpload
    }
  );

  return data;
}

export async function updateSimulation(
  updatedData: SimulationData,
  id: number,
  callbackUpload?: (progress: ProgressEvent) => void,
  autosave = false
): Promise<SimulationResponse> {
  const {
    uuid,
    name,
    cpf_cnpj_cliente,
    me_cliente,
    oi_cliente,
    me_filial_agencia,
    nu_cgc_cpf_agencia,
    oi_agencia,
    scenarios
  } = updatedData;

  const { data } = await apiV1.put<SimulationResponse>(
    `${path}/${autosave ? 'autosave/' : ''}${id}`,
    {
      uuid,
      name,
      cpf_cnpj_cliente,
      me_cliente,
      oi_cliente,
      me_filial_agencia,
      nu_cgc_cpf_agencia,
      oi_agencia,
      simulationData: { scenarios }
    },
    {
      onUploadProgress: callbackUpload
    }
  );

  return data;
}

export async function updateSimulationDigital(
  updatedData: SimulationData,
  id: number,
  callbackUpload?: (progress: ProgressEvent) => void,
  autosave = false
): Promise<SimulationResponse> {
  const {
    uuid,
    name,
    DigitalDates,
    cpf_cnpj_cliente,
    me_cliente,
    oi_cliente,
    me_filial_agencia,
    nu_cgc_cpf_agencia,
    oi_agencia,
    groups
  } = updatedData;
  const { data } = await apiV1.put<SimulationResponse>(
    `${path}/${autosave ? 'autosave/' : ''}${id}`,
    {
      uuid,
      name,
      cpf_cnpj_cliente,
      me_cliente,
      oi_cliente,
      me_filial_agencia,
      nu_cgc_cpf_agencia,
      oi_agencia,
      simulationData: { DigitalDates, groups }
    },
    {
      onUploadProgress: callbackUpload
    }
  );

  return data;
}

export async function deleteSimulation(
  id: number,
  isDraft = false
): Promise<SimulationResponse | ApiErrorResponse> {
  const { data } = await apiV1.delete<SimulationResponse>(
    `${path}/${isDraft ? '?onlyDrafts=true' : ''}`,
    {
      data: {
        ids: [id]
      }
    }
  );

  return data;
}
