import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import AudienceSubHeader from '../../SubHeader/AudienceSubHeader';
import SubHeader from '../../../SubHeader';
import paths from '../../../../routes/paths';
import classesGTM from '../../../../config/classesGTM';

const { childrens } =
  paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas;

const AudiencePayTvPanel = () => {
  const title = 'TV por Assinatura';
  const menuItems = useMemo(
    () => [
      {
        index: 0,
        label: 'Emissoras',
        path: childrens.channels.path,
        className: classesGTM.tabs.audiencePayTvChannels,
        id: 'audience-open-tv-channels'
      },
      {
        index: 1,
        label: 'Programas',
        path: childrens.programs.path,
        className: classesGTM.tabs.audiencePayTvPrograms,
        id: 'audience-open-tv-programs'
      },
      {
        index: 2,
        label: 'Participação',
        path: childrens.participation.path,
        className: classesGTM.tabs.audiencePayTVParticipation,
        id: 'audience-pay-tv-participation'
      }
    ],
    []
  );

  return (
    <Box height="calc(100% - 72px)" sx={{ flexGrow: 1 }}>
      <SubHeader>
        <AudienceSubHeader title={title} menuItems={menuItems} />
      </SubHeader>
      <Outlet />
    </Box>
  );
};

export default AudiencePayTvPanel;
