/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/rules-of-hooks */
import './DigitalPlanning.scss';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate as Navigate } from 'react-router-dom';

import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Group from '../../Group/Group';
import AvailableImpressionsCard from './Cards/AvailableImpressions/AvailableImpressionsCard';
import InvestimentTable from '../InvestimentTable';
import LoadCpmRequest from '../../../store/modules/Digital/Cpm/Cpm.actions';
import {
  SaveGroups,
  SaveGroupsFormData
} from '../../../store/modules/Digital/Groups/Groups.actions';
import { IGroup } from '../../../store/modules/Digital/Groups/Groups.types';
import InvestimentInput from '../InvestimentInput';
import { LoadImpressionRequest } from '../../../store/modules/Digital/Impressions/Impressions.actions';
import Loading from '../../Loading';
import paths from '../../../routes/paths';
import FiltersReview from './Cards/FiltersReview/FiltersReview';

const DigitalPlanning = () => {
  const { digital, simulation } = useAppSelector((state) => state);
  const { impressions } = useAppSelector((state) => state.digital);
  const dispatch = useAppDispatch();

  const { path, childrens } = paths.home.childrens.digitalSimulation;

  const navigate = Navigate();

  useEffect(() => {
    dispatch(LoadCpmRequest({}));
  }, [dispatch]);

  const digitalGroups = useAppSelector((state) => state.DigitalGroups);

  const cpms = useMemo(() => digital.cpms, [digital]);
  const maxCpm = cpms?.data?.length > 0 ? cpms.data[0]?.cpm ?? 0 : 0;
  const minCpm =
    cpms?.data?.length > 0 ? cpms.data[cpms.data.length - 1]?.cpm ?? 0 : 0;

  const groupRefs = useRef(new Map());
  const scrollToIncompleteGroup = (groupId: number) => {
    const groupRef = groupRefs.current.get(groupId);
    if (groupRef) {
      groupRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    const invalidGroup = digitalGroups.groups.find(
      (group) =>
        group.planningValidated === false && group.planningSubmited === true
    );

    if (invalidGroup) {
      setTimeout(() => {
        scrollToIncompleteGroup(invalidGroup.id);
      }, 500);
    }
  }, [digitalGroups]);

  useEffect(() => {
    if (
      digitalGroups.groups.length &&
      digitalGroups.groups.some(
        (group) => !group?.data?.availableImpressions
      ) &&
      !impressions?.loading &&
      !impressions?.error &&
      impressions?.data?.length === 0 &&
      simulation.DigitalDates?.startdate &&
      simulation.DigitalDates?.EndDate
    ) {
      dispatch(
        LoadImpressionRequest({
          startDate: `${moment(simulation.DigitalDates?.startdate).format(
            'DD/MM/YYYY'
          )}`,
          endDate: `${moment(simulation.DigitalDates?.EndDate).format(
            'DD/MM/YYYY'
          )}`,
          groups: digitalGroups.groups
        })
      );
    }
  }, [
    digitalGroups,
    dispatch,
    impressions,
    simulation.DigitalDates?.EndDate,
    simulation.DigitalDates?.startdate
  ]);

  useEffect(() => {
    if (impressions.error) navigate(`${path}/${childrens.segment}`);
  }, [childrens.segment, impressions.error, navigate, path]);

  useEffect(() => {
    const groups: IGroup[] = [];

    if (
      impressions?.data?.length > 0 &&
      digitalGroups.groups.some((group) => !group?.data?.availableImpressions)
    ) {
      digitalGroups.groups.forEach((group) => {
        if (group?.data?.filter?.data) {
          const availableUnits =
            impressions.data[group.id - 1]?.__values__.availableUnits;

          const Ngroup: IGroup = {
            ...group,
            data: {
              ...group.data,
              availableImpressions: availableUnits
            }
          };

          groups.push(Ngroup);
        }

        if (groups.length === digitalGroups.groups.length) {
          dispatch(SaveGroups(groups));
        }
      });
    }
  }, [impressions, dispatch, digitalGroups.groups]);

  const [investimentValues, setInvestimentValues] = useState(
    digitalGroups.groups.map((group: IGroup) => ({
      groupId: group.id,
      value: group.data?.cpm?.investimentValue || 0
    }))
  );

  const handleOnChangeValue = (
    investimentValue: number,
    secondaryCpm?: number,
    impressionsNum?: number,
    cpm?: number,
    groupId?: number
  ) => {
    const group = digitalGroups.groups.find(
      (groups: IGroup) => groups.id === groupId
    );

    if (group) {
      const Ngroup: IGroup = {
        ...group,
        validated: true,
        data: {
          filter: group.data?.filter || {},
          cpm: {
            secondaryCpm,
            impressions: impressionsNum,
            cpm,
            investimentValue
          }
        }
      };
      dispatch(SaveGroupsFormData(Ngroup));
    }
  };

  const handleOnChangeInvestiment = (
    investimentValue: number,
    group: IGroup
  ) => {
    setInvestimentValues((prevValues) =>
      prevValues.map((item) =>
        item.groupId === group.id ? { ...item, value: investimentValue } : item
      )
    );

    handleOnChangeValue(
      investimentValue,
      group.data?.cpm?.secondaryCpm,
      group.data?.cpm?.impressions,
      group.data?.cpm?.cpm,
      group.id
    );
  };

  return (
    <div className="digital-planning">
      {impressions?.loading ? (
        <Loading classes={{ container: 'digital-planning__loading' }} />
      ) : (
        <Group
          className={{
            accordion: 'groupAccordion',
            summary: 'groupAccordion__summary',
            group: 'fullWidth group'
          }}
          addGroupsOption={false}
        >
          {(group) => {
            return (
              <div
                key={group.id}
                ref={(el) => groupRefs.current.set(group.id, el)}
                className="groupAccordion__content"
              >
                <div className="groupAccordion__left-content">
                  <FiltersReview group={group} />
                  <AvailableImpressionsCard
                    availableImpressions={String(
                      group?.data?.availableImpressions?.toLocaleString('pt-BR')
                    )}
                    key={group.id}
                  />
                  <h5 className="paragraph__alert">
                    Este é um planejamento estimado e não garante reserva no
                    inventário.
                  </h5>
                </div>
                <div className="groupAccordion__right-content">
                  <InvestimentInput
                    className="investiment_input"
                    investiment={
                      investimentValues.find(
                        (item) => item.groupId === group.id
                      )?.value ?? 0
                    }
                    cpm={group.data?.cpm}
                    cpmMaxValue={maxCpm}
                    cpmMinValue={minCpm}
                    groupId={group.id}
                    // availableImpressions={group.availableImpressions}
                    availableImpressions={
                      group?.data?.availableImpressions ?? 0
                    }
                    name={`investment[${group.id}]`}
                    onChangeValue={(e) => handleOnChangeInvestiment(e, group)}
                    error={
                      (group.planningValidated === false &&
                        group.planningSubmited) ||
                      false
                    }
                  />
                  <InvestimentTable
                    cpmData={cpms.data}
                    groupCpm={group.data?.cpm}
                    error={
                      (group.planningValidated === false &&
                        group.planningSubmited) ||
                      false
                    }
                    cpmMaxValue={maxCpm}
                    cpmMinValue={minCpm}
                    availableImpressions={
                      group?.data?.availableImpressions ?? 0
                    }
                    investimentValue={
                      investimentValues.find(
                        (item) => item.groupId === group.id
                      )?.value ?? 0
                    }
                    sencondarySelected={group.data?.cpm?.secondaryCpm ?? 0}
                    groupId={group.id}
                    onChangeValue={handleOnChangeValue}
                  />
                </div>
              </div>
            );
          }}
        </Group>
      )}
    </div>
  );
};

export default DigitalPlanning;
