import {
  LocationsResponse,
  LocationsResponseGam
} from '../../../store/modules/Digital/Locations/Locations.types';
import { apiV1 } from '../../shared/Api.service';

const path = '/digital/geolocation-segment';

export default async function getLocations(): Promise<LocationsResponse> {
  const { data } = await apiV1.get<LocationsResponseGam>(path, {
    params: {}
  });
  return {
    data: {
      rows: data?.data?.rows?.map((item) => {
        return { value: item.id, label: item.value };
      }),
      count: data.data.count
    },
    status: data.status,
    msg: data.msg
  };
}
