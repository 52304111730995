import { useState, useEffect } from 'react';

import { ICommonsFilter } from '../../types';
import './AudiencePayTvParticipationGenrePanel.scss';
import Loading from '../../../../../Loading';
import StackedHorizontalBarChart from '../../../../Charts/StackedHorizontalBarChart';
import { useAppSelector } from '../../../../../../hooks';
import { IParticipationGraphsValues, ParticipationGraphsEnum } from '../types';
import AudiencePayTvParticipationGenreNoValue from './AudiencePayTvParticipationGenreNoValue';

const AudiencePayTvParticipationGenrePanel: React.FC<ICommonsFilter> = () => {
  const { audience } = useAppSelector((state) => state);
  const [graphsValues, setGraphsValues] = useState<IParticipationGraphsValues>({
    gen: []
  });

  useEffect(() => {
    const graphsData = audience.payTv.participation.graphs.data;
    const graphType = graphsData.map((item) => item.participationType)[0];
    const genGraph = graphsData
      .find(
        (item) =>
          item.participationType ===
            ParticipationGraphsEnum.participationChannel ||
          item.participationType === ParticipationGraphsEnum.participationGender
      )
      ?.data.map((item) => {
        return {
          label:
            graphType === ParticipationGraphsEnum.participationGender
              ? item.nm_emissora
              : item.nm_genero,
          value: parseFloat(item.percentual_audiencia)
        };
      });

    setGraphsValues({
      gen: genGraph
    });
  }, [audience]);

  return (
    <>
      {audience.payTv.participation.graphs.loading && (
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      )}
      {audience.payTv.participation.graphs.data.length &&
      !audience.payTv.participation.graphs.data[0].data?.length ? (
        <AudiencePayTvParticipationGenreNoValue />
      ) : (
        <div>
          {!audience.payTv.participation.graphs.loading && !graphsValues.gen ? (
            <AudiencePayTvParticipationGenreNoValue noFilterSelected />
          ) : (
            <div>
              {graphsValues.gen && (
                <StackedHorizontalBarChart
                  graphData={graphsValues.gen}
                  graphName="Participação:"
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AudiencePayTvParticipationGenrePanel;
