/* eslint-disable no-debugger */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate as Navigate } from 'react-router-dom';

import classesGTM from '../../../config';
import paths from '../../../routes/paths';

import IconLanderboard from '../../../assets/images/landerboard-gradient.svg';
import IconFilter from '../../../assets/images/filters.svg';
import IconSummary from '../../../assets/images/summary.svg';

import {
  SaveSceneryPath,
  SetSimulation
} from '../../../store/modules/Simulation/Simulation.actions';
import { ItemProps } from '../../Audience/Menu/types';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { SaveGroups } from '../../../store/modules/Digital/Groups/Groups.actions';
import {
  SaveSimulation,
  UpdateSimulation
} from '../../../store/modules/Simulations/Simulations.actions';
import { ResetImpressions } from '../../../store/modules/Digital/Impressions/Impressions.actions';
import { IGroup } from '../../../store/modules/Digital/Groups/Groups.types';

interface Button {
  id: number;
  title: string;
  disabled: boolean;
  classes: {
    button: string;
    tagManager?: string;
  };
  visible: boolean;
  onClick(): void;
}

interface IGtmClassesButtons {
  back?: string;
  next?: string;
}

interface IUseNavigateProps {
  handleClickExportButton?: () => void;
}
const useNavigate = ({ handleClickExportButton }: IUseNavigateProps) => {
  const dispatch = useAppDispatch();
  const { path: home } = paths.home;
  const { path, childrens } = paths.home.childrens.digitalSimulation;
  const navigate = Navigate();
  const location = useLocation();

  const { DigitalGroups, simulation } = useAppSelector((state) => state);
  const { impressions } = useAppSelector((state) => state.digital);
  const [originalSimulation, setOriginalSimulation] = useState(simulation);
  const [isSummaryDisabled, setIsSummaryDisabled] = useState<boolean>(
    !!DigitalGroups?.groups?.find((g) => g.changed) || !!simulation?.changed
  );
  const navigateToHome = useCallback(() => {
    navigate(home);
  }, [navigate, home]);

  const generateMenuItems = useCallback(
    (
      itemId: number,
      icon: string,
      title: string,
      childPath: string,
      tagManagerClass: string,
      disabled = false,
      onClick?: () => void
    ) => ({
      itemId,
      icon,
      title,
      path: `${path}/${childPath}`,
      onClick: () => {
        if (onClick) onClick();
        dispatch(SaveSceneryPath(`${path}/${childPath}`));
      },
      selected: location.pathname === `${path}/${childPath}`,
      visibled: true,
      disabled,
      classes: { tagManager: tagManagerClass }
    }),
    [dispatch, path, location]
  );

  const isPlanningDisabled = useCallback(() => {
    let disabled = false;

    if (!simulation?.groups) disabled = true;

    if (impressions.error) disabled = true;

    if (simulation?.groups?.length === 0) disabled = true;

    if (
      !simulation?.DigitalDates?.EndDate ||
      !simulation?.DigitalDates?.startdate
    )
      disabled = true;

    const hasGroupNotSubmited = simulation?.groups?.some(
      (group) => !group?.submited
    );

    const hasGroupNotValidated = simulation?.groups?.some(
      (group) => !group?.validated
    );

    if (hasGroupNotSubmited || hasGroupNotValidated) disabled = true;

    const hasNoData = simulation?.groups?.some((group) => !group?.data);

    const hasNoDataFitlter = simulation?.groups?.some(
      (group) => !group?.data?.filter?.data
    );

    const hasEmptyLocation = simulation?.groups?.some(
      (group) =>
        !group?.data?.filter?.data?.locations ||
        group?.data?.filter?.data?.locations?.length < 1
    );

    const hasEmptyGender = simulation?.groups?.some(
      (group) => !group?.data?.filter?.data?.gender
    );

    const hasEmptyAge = simulation?.groups?.some(
      (group) =>
        !group?.data?.filter?.data?.age ||
        group?.data?.filter?.data?.age?.length < 1
    );

    if (
      hasNoData ||
      hasNoDataFitlter ||
      hasEmptyLocation ||
      hasEmptyGender ||
      hasEmptyAge
    )
      disabled = true;

    return disabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulation]);

  const isResumeDisabled = useCallback(() => {
    let disabled = false;

    if (!simulation?.groups) disabled = true;

    if (simulation?.groups?.length === 0) disabled = true;

    if (isSummaryDisabled || simulation?.changed) disabled = true;

    if (
      !simulation?.DigitalDates?.EndDate ||
      !simulation?.DigitalDates?.startdate
    )
      disabled = true;

    const hasGroupPlanningWithoutSelectedCpm = simulation?.groups?.some(
      (group) => !group?.data?.cpm?.cpm
    );

    if (hasGroupPlanningWithoutSelectedCpm) disabled = true;

    /*     const hasAvailableImpressions = simulation?.groups?.some(
      (group) => group?.data?.availableImpressions
    ); */

    //  if (!hasAvailableImpressions) disabled = true;

    return disabled;
  }, [simulation, isSummaryDisabled]);

  useEffect(() => {
    setIsSummaryDisabled(!!DigitalGroups?.groups?.find((g) => g.changed));
  }, [DigitalGroups]);

  const handleItemOnClickPlanning = useCallback(() => {
    let validated = true;
    const updatedGroups = DigitalGroups.groups.map((group) => {
      const cpm = group.data?.cpm;
      const isInvalid =
        !cpm ||
        !(cpm?.investimentValue > 0) ||
        !(cpm.secondaryCpm && cpm.secondaryCpm > 0) ||
        !(cpm.cpm && cpm.cpm > 0);

      if (isInvalid) {
        validated = false;
      }

      return {
        ...group,
        planningValidated: !isInvalid,
        planningSubmited: true,
        submited: true,
        changed: false
      };
    });

    dispatch(SaveGroups([...updatedGroups]));
    dispatch(
      SetSimulation({
        groups: updatedGroups,
        changed: false
      })
    );

    const newSimulation = {
      ...simulation,
      groups: updatedGroups
    };

    if (validated) {
      const originalSimulationStringify = JSON.stringify({
        ...originalSimulation,
        dataUrl: null,
        fileName: null,
        lastPath: null,
        submited: null,
        updatedAt: null
      });

      const newSimulationStringify = JSON.stringify({
        ...newSimulation,
        dataUrl: null,
        fileName: null,
        lastPath: null,
        submited: null,
        updatedAt: null
      });

      if (
        originalSimulationStringify === newSimulationStringify &&
        !simulation?.error
      ) {
        return true;
      }

      if (simulation?.id) {
        dispatch(UpdateSimulation(newSimulation, simulation?.id, false, true));
        setOriginalSimulation(newSimulation);
      } else {
        dispatch(SaveSimulation(newSimulation, false, true));
        setOriginalSimulation(newSimulation);
      }
    }

    return validated;
  }, [dispatch, DigitalGroups.groups, originalSimulation, simulation]);

  const handleItemOnClick = useCallback(() => {
    const validDates =
      !!simulation.DigitalDates?.EndDate &&
      !!simulation.DigitalDates?.startdate;
    const valid = !DigitalGroups.groups.some(
      (group) => group?.validated === false
    );
    const newGroups = DigitalGroups.groups.map((group) => ({
      ...group,
      submited: true
    }));

    dispatch(SaveGroups([...newGroups]));
    dispatch(
      SetSimulation({
        groups: newGroups,
        submited: true
      })
    );

    dispatch(ResetImpressions());
    if (valid && validDates) {
      const newSimulation = {
        ...simulation,
        groups: newGroups
      };

      const originalSimulationStringify = JSON.stringify({
        ...originalSimulation,
        dataUrl: null,
        fileName: null,
        lastPath: null,
        submited: null,
        updatedAt: null
      });

      const newSimulationStringify = JSON.stringify({
        ...newSimulation,
        dataUrl: null,
        fileName: null,
        lastPath: null,
        submited: null,
        updatedAt: null
      });

      if (
        originalSimulationStringify === newSimulationStringify &&
        !simulation?.error
      ) {
        navigate(`${path}/${childrens.planning}`);
      }

      if (simulation?.id) {
        dispatch(UpdateSimulation(newSimulation, simulation?.id, false, true));
        setOriginalSimulation(newSimulation);
      } else {
        dispatch(SaveSimulation(newSimulation, false, true));
        setOriginalSimulation(newSimulation);
      }

      navigate(`${path}/${childrens.planning}`);
    }
    // eslint-disable-next-line consistent-return
    return;
  }, [
    simulation,
    DigitalGroups.groups,
    dispatch,
    originalSimulation,
    navigate,
    path,
    childrens.planning
  ]);

  type EventType = 'location' | 'age' | 'interest' | 'genre';

  const sendGAEvent = useCallback(
    ({ event, value }: { event: EventType; value: string }) => {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: `${event}Selected`,
        [event]: value
      });
    },
    []
  );

  const sendEventsByType = useCallback(
    (type: EventType, items?: Array<{ label: string }>) => {
      items?.forEach((item) => {
        if (item?.label) sendGAEvent({ event: type, value: item.label });
      });
    },
    [sendGAEvent]
  );

  const sendGAEventsWithUserSelection = useCallback(
    (group: IGroup) => {
      const filterData = group?.data?.filter?.data;

      const eventMap: Record<EventType, Array<{ label: string }> | undefined> =
        {
          location: filterData?.locations,
          age: filterData?.age,
          interest: filterData?.interests,
          genre: filterData?.gender ? [filterData.gender] : undefined
        };

      Object.entries(eventMap).forEach(([eventType, items]) => {
        sendEventsByType(eventType as EventType, items);
      });
    },
    [sendEventsByType]
  );

  const items = useMemo(
    (): Readonly<ItemProps>[] => [
      generateMenuItems(
        0,
        IconFilter,
        'Segmentação',
        childrens.segment,
        classesGTM.tabs.segmentDigital,
        false,
        () => {
          handleItemOnClick();

          DigitalGroups?.groups?.forEach((group) => {
            sendGAEventsWithUserSelection(group);
          });

          dispatch(SaveSceneryPath(`${path}/${childrens.segment}`));
        }
      ),
      generateMenuItems(
        1,
        IconLanderboard,
        'Planejamento',
        childrens.planning,
        classesGTM.tabs.planningDigital,
        isPlanningDisabled(),
        () => {
          dispatch(SaveSceneryPath(`${path}/${childrens.planning}`));
          const availableToNextStep = handleItemOnClickPlanning();

          if (availableToNextStep) {
            setIsSummaryDisabled(false);

            navigate(`${path}/${childrens.summary}`);
          }
        }
      ),
      generateMenuItems(
        2,
        IconSummary,
        'Resumo',
        childrens.summary,
        classesGTM.tabs.summaryDigital,
        isResumeDisabled(),
        () => {
          dispatch(SaveSceneryPath(`${path}/${childrens.summary}`));
          navigate(`${path}/${childrens.summary}`);
        }
      )
    ],
    [
      generateMenuItems,
      childrens.segment,
      childrens.planning,
      childrens.summary,
      DigitalGroups?.groups,
      sendGAEventsWithUserSelection,
      isPlanningDisabled,
      isResumeDisabled,
      handleItemOnClick,
      dispatch,
      path,
      handleItemOnClickPlanning,
      navigate
    ]
  );

  // useEffect(() => {
  //   const item = items.find((el) => el.path === location.pathname);

  //   if (item?.disabled) {
  //     navigate(`${path}/${childrens.segment}`);
  //   }
  // }, [items, location.pathname, navigate, path, childrens.segment]);

  const selectedItem = useCallback(
    () => items.find((el) => el.selected),
    [items]
  );

  const handleClickButton = useCallback(
    (next: boolean) => {
      const selected = selectedItem();
      if (selected) {
        const index = items.indexOf(selected);
        const item = next ? items[index] : items[index - 1];

        if (next && item?.onClick) {
          item.onClick();
        } else {
          navigate(item.path);
        }
      }
    },
    [items, selectedItem, navigate]
  );

  /*   // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleValidation = (executable: any) => {
    const validDates =
      !!simulation.DigitalDates?.EndDate && !!simulation.DigitalDates.startdate;
    const valid = DigitalGroups.groups
      .map((group) => {
        return group.validated;
      })
      .includes(true);
    const newGroups = DigitalGroups.groups.map((group) => {
      return { ...group, submited: true };
    });
    dispatch(SaveGroups([...newGroups]));
    dispatch(
      SetSimulation({
        ...simulation,
        groups: newGroups,
        submited: true
      })
    );
    if (valid && validDates) {
      if (simulation.id) {
        dispatch(
          UpdateSimulation(
            {
              ...simulation,
              groups: newGroups,
              submited: true
            },
            simulation.id,
            true
          )
        );
      }
      executable();
    }
  }; */

  const generateButtons = useCallback((): Button[] => {
    const selected = selectedItem();

    if (selected) {
      const index = items.indexOf(selected);
      const isFirstItem = index === 0;
      const isLastItem = index === items.length - 1;

      let gtmClasses: IGtmClassesButtons = {
        next: '',
        back: ''
      };

      if (isFirstItem)
        gtmClasses = {
          next: classesGTM.simulation.digital.nextSegmentation
        };
      else if (isLastItem)
        gtmClasses = {
          back: classesGTM.simulation.digital.backSummary
        };
      else
        gtmClasses = {
          back: classesGTM.simulation.digital.backPlanning,
          next: classesGTM.simulation.digital.nextPlanning
        };

      return [
        {
          id: 1,
          title: 'Sair',
          disabled: !isFirstItem,
          classes: {
            button: 'btn-back',
            tagManager: classesGTM.simulation.digital.exitDigital
          },
          visible: isFirstItem,
          onClick: navigateToHome
        },
        {
          id: 2,
          title: 'Voltar',
          disabled: isFirstItem,
          classes: {
            button: 'btn-back',
            tagManager: gtmClasses.back
          },
          visible: !isFirstItem,
          onClick: () => handleClickButton(false)
        },
        {
          id: 3,
          title: 'Continuar',
          disabled: isLastItem,
          classes: {
            button: 'btn--blue',
            tagManager: gtmClasses.next
          },
          visible: !isLastItem,
          onClick: () => {
            handleClickButton(true);
          }
        },
        {
          id: 4,
          title: 'Exportar',
          disabled: !isLastItem,
          classes: {
            button: 'btn--blue',
            tagManager: classesGTM.simulation.digital.exportDigital
          },
          visible: isLastItem,
          onClick: () => handleClickExportButton && handleClickExportButton()
        }
      ];
    }

    return [
      {
        id: 1,
        title: 'Sair',
        disabled: false,
        classes: {
          button: 'btn-back',
          tagManager: classesGTM.simulation.digital.exitDigital
        },
        visible: true,
        onClick: navigateToHome
      }
    ];
  }, [
    selectedItem,
    navigateToHome,
    items,
    handleClickButton,
    handleClickExportButton
  ]);

  const buttons = useMemo(() => generateButtons(), [generateButtons]);

  return { items, buttons, handleItemOnClick, handleClickExportButton };
};

export default useNavigate;
