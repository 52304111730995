import React, { useCallback, useEffect, useRef } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import Loading from '../../Loading';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import {
  GetProfile,
  RedirectToLogin,
  Authenticate,
  ResetProfile,
  AuthenticateWithKeycloak
} from '../../../store/modules/Auth/Auth.actions';
import paths from '../../../routes/paths';
import { eventEmitter, Events } from '../../../helpers/EventEmitter';
import loginLocalStorage from './Login.LocalStorage';
import sendNewSessionEvent from '../../../helpers/SendNewSessionToGA';

const LoginWrapper: React.FC<any> = ({ children }) => {
  const { loading, error, profile, loggedIn, authenticated } = useAppSelector(
    (state) => state.auth
  );
  const dispatch = useAppDispatch();
  const location = useLocation();

  const loginStore = useRef(loginLocalStorage());

  const [searchParams, setSearchParams] = useSearchParams(
    window.location.search
  );

  const checkAuthentication = useCallback(() => {
    return loginStore.current.authenticated.get();
  }, []);

  useEffect(() => {
    eventEmitter.subscribe(Events.LOGIN_RELOAD, () => {
      loginStore.current.lastRoute.set(location.pathname);

      if (loginStore.current.authenticateMethod.get() === 'keycloak') {
        dispatch(AuthenticateWithKeycloak());
      } else {
        dispatch(RedirectToLogin());
      }
    });
    return () => eventEmitter.unsubscribe(Events.LOGIN_RELOAD);
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (!checkAuthentication()) {
      dispatch(ResetProfile());
    }
  }, [dispatch, checkAuthentication]);

  useEffect(() => {
    const roleStore = loginStore.current.profile.get();

    if (searchParams.has('login')) {
      sendNewSessionEvent(roleStore!);
      searchParams.delete('login');
      setSearchParams(searchParams);
    }

    if (
      location.pathname === '/' &&
      searchParams.has('code') &&
      searchParams.has('session_state')
    ) {
      if (loginStore.current.codeVerifier.get()) {
        dispatch(Authenticate(window.location.search));
        return;
      }
      loginStore.current.authenticated.set(false);
    }

    if (!checkAuthentication()) {
      loginStore.current.lastRoute.set(location.pathname);

      if (
        (searchParams.has('utm_source') &&
          searchParams.get('utm_source') === 'globoads') ||
        (loginStore.current.authenticateMethod.get() === 'keycloak' &&
          !searchParams.has('utm_source'))
      ) {
        dispatch(AuthenticateWithKeycloak());
      } else {
        dispatch(RedirectToLogin());
      }
    }
  }, [
    setSearchParams,
    dispatch,
    searchParams,
    location.pathname,
    checkAuthentication
  ]);

  useEffect(() => {
    if ((checkAuthentication() && !profile) || (authenticated && !profile)) {
      loginStore.current.lastRoute.set(location.pathname);
      dispatch(GetProfile());
    }
  }, [
    dispatch,
    profile,
    location.pathname,
    authenticated,
    checkAuthentication
  ]);

  if (error) {
    return <Navigate to={paths.unauthorized} />;
  }

  if (profile && !loading && loggedIn && checkAuthentication()) {
    return <>{children}</>;
  }

  return <Loading />;
};

export default LoginWrapper;
