import { Reducer } from 'redux';
import { TypeTV } from '../../../services/shared/Api.service';
import { TargetsState, TargetsTypes, TargetAction } from './Targets.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const targetInitialState: TargetsState = {
  closedTv: { ...defaultData, typeTV: TypeTV.CLOSED },
  openedTv: { ...defaultData, typeTV: TypeTV.OPEN }
};

const reducer: Reducer<TargetsState, TargetAction> = (
  state = targetInitialState,
  action
): TargetsState => {
  switch (action.type) {
    case TargetsTypes.LOAD_TARGETS_SUCCESS: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            data: action.payload.rows,
            count: action.payload.count,
            error: false,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          data: action.payload.rows,
          count: action.payload.count,
          error: false,
          loading: false
        }
      };
    }
    case TargetsTypes.LOAD_TARGETS_ERROR: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            error: true,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          data: action.payload.rows,
          error: true,
          loading: false
        }
      };
    }
    case TargetsTypes.LOAD_TARGETS_REQUEST: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            data: [],
            error: false,
            loading: true
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          data: [],
          error: false,
          loading: true
        }
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
