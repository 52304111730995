import IconSimulations from '../../assets/images/simulations.svg';
import IconAnalytics from '../../assets/images/analytics.svg';
import IconCompetitive from '../../assets/images/competitive.svg';
import { ItemProps } from '../Menu/Nav/Item/types';
import paths from '../../routes/paths';
import classesGTM from '../../config';
import { useAppSelector } from '../../hooks';
import { Roles } from '../../store/modules/Users/Users.types';

const { simulations, audience, competitive } = paths.home.childrens;

const Items = () => {
  const { profile } = useAppSelector((state) => state.auth);

  const hasPermition = (
    roles: string[] = [Roles.MASTER, Roles.ADMIN, Roles.EXECUTIVE, Roles.AGENCY]
  ) => roles.includes(profile?.role || '');

  const itemsProps: ItemProps[] = [
    {
      itemId: 1,
      icon: IconSimulations,
      title: 'Planejamentos',
      path: simulations,
      selected: true,
      disabled: false,
      visibled: hasPermition([Roles.ADMIN, Roles.EXECUTIVE, Roles.MASTER]),
      classes: { tagManager: classesGTM.home.simulations }
    },
    {
      itemId: 2,
      icon: IconAnalytics,
      title: 'Audiência',
      path: '/audience',
      childrensPath: [
        audience.childrens.cenario_digital_brasileiro.path,
        audience.childrens.clusters.path,
        audience.childrens.notebook_plazas.path,
        audience.childrens.regular_plazas.path,
        audience.childrens.pay_tv.path,
        audience.childrens.payTv.path,
        audience.childrens.help.path,
        audience.childrens.openTv.path
      ],
      disabled: false,
      visibled: hasPermition([Roles.ADMIN, Roles.EXECUTIVE, Roles.MASTER]),
      classes: { tagManager: classesGTM.home.audience }
    },
    {
      itemId: 3,
      icon: IconCompetitive,
      title: 'Análise Competitiva',
      path: competitive.path,
      childrensPath: [
        competitive.childrens.comparative.path,
        competitive.childrens.consolidated.path
      ],
      disabled: false,
      visibled: hasPermition([Roles.ADMIN, Roles.EXECUTIVE, Roles.MASTER]),
      classes: { tagManager: classesGTM.home.competitive }
    }
  ];
  return itemsProps;
};

export default Items;
