import {
  InterestsResponse,
  InterestsResponseGam
} from '../../../store/modules/Digital/Interests/Interests.types';
import { apiV1 } from '../../shared/Api.service';

const path = '/digital/interest-segment';

export default async function getInterests(
  props: any
): Promise<InterestsResponse> {
  const { data } = await apiV1.get<InterestsResponseGam>(path, {
    params: { ...props }
  });
  return data;
}
