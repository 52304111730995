/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FilterOptionsState,
  Grid,
  ListItemText
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SelectInput from '../../Form/SelectInput';
import AutocompleteInput from '../../Form/AutocompleteInput';
import FormContainer from '../../Form/FormContainer';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { FiltersData } from '../../../store/modules/Digital/Filters/Filters.types';
import { Location } from '../../../store/modules/Digital/Locations/Locations.types';
import { Age } from '../../../store/modules/Digital/Ages/Ages.types';
import { Interest } from '../../../store/modules/Digital/Interests/Interests.types';
import { SaveGroupsFormData } from '../../../store/modules/Digital/Groups/Groups.actions';
import { Gender } from '../../../store/modules/Digital/Genders/Genders.types';
import { IGroup } from '../../../store/modules/Digital/Groups/Groups.types';

const SegmentationFilters = ({ group }: { group: IGroup }) => {
  const actualFormRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();

  const { digital } = useAppSelector((state) => state);

  const location = useMemo(() => digital.locations, [digital]);
  const ages = useMemo(() => digital.ages, [digital]);
  const interest = useMemo(() => digital.interests, [digital]);
  const gender = useMemo(() => digital.genders, [digital]);

  const [locationOptions, setLocationOptions] = useState<
    Location[] | undefined
  >();
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [agesOptions, setAgesOptions] = useState<Age[] | undefined>();
  const [interestOptions, setInterestOptions] = useState<
    Interest[] | undefined
  >();
  const [interestsLoading, setInterestsLoading] = useState<boolean>(false);
  const [genderOptions, setGenderOptions] = useState<Gender[] | undefined>();

  const [selectedLocation, setSelectedLocation] = useState<Location[]>(
    group?.data?.filter?.data?.locations || []
  );
  const [selectedAge, setSelectedAge] = useState<Age[]>(
    group?.data?.filter?.data?.age || []
  );
  const [selectedGender, setSelectedGender] = useState<Gender | undefined>(
    group?.data?.filter?.data?.gender
  );
  const [selectedInterest, setSelectedInterest] = useState<Interest[]>(
    group?.data?.filter?.data?.interests || []
  );
  const [selectedFilters, setSelectedFilters] = useState<FiltersData>();

  const [validated, setValidated] = useState<boolean>(!!group.validated);

  const [filterChange, setFilterChanged] = useState<boolean>(false);

  useEffect(() => {
    setLocationOptions(location.data);
    setLocationLoading(location.loading);
  }, [location]);

  useEffect(() => {
    setAgesOptions(ages.data);
  }, [ages]);

  useEffect(() => {
    setInterestOptions(interest.data);
    setInterestsLoading(interest.loading);
  }, [interest]);

  useEffect(() => {
    setGenderOptions(gender.data);
  }, [gender]);

  const handleFormSubmit = () => {
    if (
      (!!selectedLocation?.length || selectedLocation?.length !== 0) &&
      !!selectedGender &&
      (!!selectedAge?.length || selectedAge?.length !== 0)
    ) {
      const submitedGroup: IGroup = {
        ...group,
        validated: true,
        data: {
          ...group.data,
          availableImpressions: undefined,
          filter: {
            data: {
              locations: selectedLocation,
              gender: selectedGender,
              age: selectedAge,
              interests: selectedInterest
            }
          }
        },
        changed: filterChange
      };

      dispatch(SaveGroupsFormData(submitedGroup));
    } else {
      dispatch(SaveGroupsFormData({ ...group, validated: false }));
    }
  };

  const handleLocalizationChange = useCallback(
    (value: Location[]) => {
      setSelectedLocation(value);
      setSelectedFilters({ locations: value, ...selectedFilters });
      setFilterChanged(true);

      const newGroup: any = {
        ...group,
        data: {
          ...group.data,
          filter: {
            ...group.data?.filter,
            data: { ...group.data?.filter.data, locations: value }
          }
        },
        changed: filterChange
      };

      dispatch(SaveGroupsFormData(newGroup));
    },
    [selectedLocation, setSelectedLocation]
  );
  const handleGenreChange = useCallback(
    (value: number) => {
      if (value) {
        const gen = genderOptions?.find(
          (op: { value: any }) => op.value === value
        );
        setSelectedGender(gen);
        setFilterChanged(true);
        setSelectedFilters({ gender: gen, ...selectedFilters });

        dispatch(
          SaveGroupsFormData({
            ...group,
            data: {
              ...group.data,
              filter: {
                ...group.data?.filter,
                data: { ...group.data?.filter.data, gender: gen }
              }
            },
            changed: filterChange
          })
        );
      }
    },
    [genderOptions, selectedGender, selectedFilters]
  );
  const handleAgeChange = useCallback(
    (value: Age[]) => {
      setSelectedAge(value);
      setSelectedFilters({ age: value, ...selectedFilters });
      setFilterChanged(true);

      dispatch(
        SaveGroupsFormData({
          ...group,
          data: {
            ...group.data,
            filter: {
              ...group.data?.filter,
              data: { ...group.data?.filter.data, age: value }
            }
          },
          changed: filterChange
        })
      );
    },
    [selectedAge, setSelectedAge]
  );

  const handleInterestChange = useCallback(
    (value: Interest[]) => {
      setSelectedInterest(value);
      setSelectedFilters({ interests: value, ...selectedFilters });
      setFilterChanged(true);

      const groupData = {
        ...group,
        data: {
          ...group.data,
          filter: {
            ...group.data?.filter,
            data: { ...group.data?.filter.data, interests: value }
          }
        }
      };

      dispatch(SaveGroupsFormData(groupData));
    },
    [selectedInterest, setSelectedInterest]
  );

  useEffect(() => {
    const valid =
      (!!selectedLocation?.length || selectedLocation?.length !== 0) &&
      !!selectedGender &&
      (!!selectedAge?.length || selectedAge?.length !== 0);

    setValidated(valid);
    handleFormSubmit();
  }, [selectedLocation, selectedGender, selectedAge, selectedInterest]);

  const filterSegmentation = (
    options: any[],
    { inputValue }: FilterOptionsState<any>
  ) => {
    return options.filter(
      (opt) =>
        opt.label.toLowerCase().search(inputValue.toLowerCase()) !== -1 ||
        opt.grupo.toLowerCase().search(inputValue.toLowerCase()) !== -1
    );
  };

  return (
    <Grid container direction="column">
      <h3>Segmentação</h3>
      <FormContainer
        error
        formRef={actualFormRef}
        fullWidth
        id="simulation-form"
        onSubmit={() => handleFormSubmit()}
        sx={{ mt: '16px' }}
      >
        <Grid container direction="row">
          <Grid item xs zeroMinWidth>
            <AutocompleteInput
              data-testid="localizacao"
              dataClarityMask
              disableCloseOnSelect
              label="Selecione a localização"
              loading={locationLoading}
              multiple
              name="localizacao"
              maxDisplay={2}
              onChange={handleLocalizationChange}
              defaultValue={selectedLocation}
              options={locationOptions || []}
              required
              mainValue={
                locationOptions?.length ? locationOptions[0].label : undefined
              }
              externalError={
                !validated && group.submited && !selectedLocation.length
                  ? 'Selecione ao menos 1 opção de localização'
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: '16px' }} container direction="row">
          <Grid item xs zeroMinWidth>
            <SelectInput
              label="Gênero"
              name="genre"
              onChange={(e) => handleGenreChange(e.target.value)}
              defaultValue={group?.data?.filter?.data?.gender?.value}
              options={genderOptions || []}
              required
              externalError={
                !validated && group.submited && !selectedGender
                  ? 'Selecionar opção de gênero'
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: '16px' }} container direction="row">
          <Grid item xs zeroMinWidth>
            <AutocompleteInput
              data-testid="age"
              dataClarityMask
              disableCloseOnSelect
              loading={locationLoading}
              multiple
              label="Faixa etária"
              name="age"
              maxDisplay={2}
              onChange={handleAgeChange}
              defaultValue={selectedAge}
              options={agesOptions || []}
              required
              mainValue={agesOptions?.length ? agesOptions[0].label : undefined}
              externalError={
                !validated && group.submited && !selectedAge.length
                  ? 'Selecionar opção de idade'
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid sx={{ mt: '16px' }} container direction="row">
          <Grid item xs zeroMinWidth>
            <AutocompleteInput
              data-testid="interest"
              dataClarityMask
              disableCloseOnSelect
              label="Selecione os interesses do público"
              loading={interestsLoading}
              multiple
              name="interest"
              maxDisplay={2}
              onChange={handleInterestChange}
              defaultValue={group?.data?.filter?.data?.interests}
              groupBy={(opt) => opt.segmentation}
              filterOptions={filterSegmentation}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} style={{ marginRight: 8 }} />
                  <ListItemText
                    primary={option.label}
                    secondary={option.grupo}
                  />
                </li>
              )}
              options={interestOptions || []}
            />
          </Grid>
        </Grid>
      </FormContainer>
    </Grid>
  );
};

export default SegmentationFilters;
