import { AppThunk } from '../..';
import getST from '../../../services/MediaSimulator/SimulationsType.service';
import {
  EnumSimulationsTypesRequest,
  ISimulationsTypeResponse,
  SimulationsTypeAction
} from './SimulationsType.types';

export const LoadSTSuccess = (
  response: ISimulationsTypeResponse
): SimulationsTypeAction => {
  const payload: SimulationsTypeAction = {
    type: EnumSimulationsTypesRequest.LOAD_SIMULATIONS_TYPE_SUCCESS,
    payload: {
      count: response?.data?.count,
      rows: response?.data?.rows,
      message: response?.msg
    }
  };

  return payload;
};

const LoadSTError = (error: string): SimulationsTypeAction => {
  const payload: SimulationsTypeAction = {
    type: EnumSimulationsTypesRequest.LOAD_SIMULATIONS_TYPE_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingST = () => ({
  type: EnumSimulationsTypesRequest.SIMULATIONS_TYPE_REQUEST,
  payload: {}
});

export const LoadSTRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingST());
    const response = await getST();
    dispatch(LoadSTSuccess(response));
  } catch (error: any) {
    dispatch(
      LoadSTError(error instanceof Error ? error.message : error?.msg ?? '')
    );
  }
};
