import './AvailableImpressionsCard.scss';
import React from 'react';

import { Card } from '@mui/material';

import Information from './Information/InformationCard';
import IconInformationPurple from '../../../../../assets/images/information-badge-purple.svg';

interface AvailableImpressionsCardProps {
  availableImpressions?: string;
}

const AvailableImpressionsCard: React.FC<AvailableImpressionsCardProps> = ({
  availableImpressions
}) => {
  return (
    <Card className="available-impressions-card">
      <div className="available-impressions-card__top-content">
        <div className="available-impressions-card__title">
          Impressões disponíveis
        </div>
        <div className="available-impressions-card__value">
          {availableImpressions}
        </div>
      </div>
      <Information
        icon={IconInformationPurple}
        text="Impressões disponíveis com base na segmentação selecionada."
      />
    </Card>
  );
};

AvailableImpressionsCard.defaultProps = {
  availableImpressions: undefined
};

export default AvailableImpressionsCard;
