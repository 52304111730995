import { OptionsList } from '../../../../Form/types';
import { ICommonsFilter } from '../types';

export interface TypePeriod extends OptionsList {
  tp_dado: string;
  nr_ano?: number;
}

export type SelectFilter = {
  value: number | string;
  label: string;
  grupo?: string;
  segmentation: string;
};

export enum ParticipationGraphsEnum {
  generalGen = 'generalGen',
  programmer = 'programmer',
  channelsPaytv = 'channelsPaytv',
  globoGen = 'globoGen',
  participationGender = 'participationGender',
  participationChannel = 'participationChannel'
}

export type TParticipationGraphs = typeof ParticipationGraphsEnum;

export interface IParticipationGraphsGenericData {
  label: string;
  value: number;
}

export interface IParticipationGraphsValues {
  gen?: IParticipationGraphsGenericData[];
  channels?: IParticipationGraphsGenericData[];
}

export interface IParticipationGraphsFilterParams {
  targets?: string[] | string;
  programmingGenders?: string[];
  channels?: string[] | string;
  periods?: string[] | string;
  participationType: string[];
}

export interface IAudienceByParticipationContainerProps extends ICommonsFilter {
  setTarget: React.Dispatch<React.SetStateAction<OptionsList | undefined>>;
  setTimeRange?: React.Dispatch<React.SetStateAction<OptionsList | undefined>>;
  setPeriod: React.Dispatch<React.SetStateAction<TypePeriod | undefined>>;
  period: TypePeriod | undefined;
  children?: React.ReactNode;
}

export enum AudiencePayTVParticipationSubTabEnum {
  geral = 'geral',
  globo = 'globo',
  genre = 'genre'
}

export type TAudiencePayTVParticipationSubTab =
  AudiencePayTVParticipationSubTabEnum;
