import './AudiencePayTvParticipationGeralFilters.scss';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormHandles } from '@unform/core';
import { Box, Grid, Typography } from '@mui/material';

import FormContainer from '../../../../../../Form/FormContainer';
import { ParticipationGraphsEnum, TypePeriod } from '../../types';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import useAudiencePayTvParticipationGeralFilterLoadData from './hooks/useAudiencePayTvParticipationGeralFilterLoadData';
import { OptionsList } from '../../../../../../Form/types';
import { listboxSigleProps } from '../../../../../../Competitive/CompetitiveFilter/constants';
import AutocompleteInput from '../../../../../../Form/AutocompleteInput';
import LoadAudienceFilterTargetsRequest from '../../../../../../../store/modules/AudienceModule/PayTv/Targets/Audience.targets.actions';
import ButtonApp from '../../../../../../ButtonApp';
import classesGTM from '../../../../../../../config';
import {
  ClearParticipationGraphsData,
  LoadParticipationGraphsRequest
} from '../../../../../../../store/modules/AudienceModule/PayTv/Participation/Graphs/Participation.graphs.actions';
import AlertInfoCard from '../../../../../Cards/AlertInfoCard';

const AudienceByPayTvParticipationGeralFilter: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ready, setReady] = useState<boolean>(false);
  const [period, setPeriod] = useState<TypePeriod>();
  const [target, setTarget] = useState<OptionsList>();
  const [newFilter, setNewFilter] = useState<boolean>(false);

  const inputsConfig = useMemo(
    () => ({
      target: {
        name: 'target',
        defaultValue: 'Total Domicílios'
      },
      period: {
        name: 'period',
        defaultValue: '2024'
      }
    }),
    []
  );

  const loadData = useCallback(
    (t?: string, p?: string) => {
      dispatch(
        LoadParticipationGraphsRequest({
          targets: t || target?.value,
          periods: p || period?.value,
          participationType: [
            ParticipationGraphsEnum.generalGen,
            ParticipationGraphsEnum.programmer
          ]
        })
      );
    },
    [dispatch, period?.value, target?.value]
  );

  useEffect(() => {
    dispatch(LoadAudienceFilterTargetsRequest());

    return () => {
      dispatch(ClearParticipationGraphsData());
    };
  }, [dispatch]);

  useEffect(() => {
    loadData(
      inputsConfig.target.defaultValue,
      inputsConfig.period.defaultValue
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const targetsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.targets.count) {
      return audience?.payTv?.targets.data
        .filter((targetOption) => targetOption.name)
        .map((targetOption) => ({
          label: targetOption.name,
          value: targetOption.name
        }));
    }

    return [];
  }, [audience?.payTv?.targets.data, audience?.payTv?.targets.count]);

  useEffect(() => {
    if (!target) {
      setTarget(
        targetsOptions.find(
          (option) => option.value === inputsConfig.target.defaultValue
        )
      );
    }
  }, [inputsConfig.target, targetsOptions, target, setTarget]);

  const { periodOptions } = useAudiencePayTvParticipationGeralFilterLoadData({
    period,
    target
  });

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  const handleTargetChange = (option: TypePeriod) => {
    if (option) {
      setTarget(option);
    }
  };

  const handlePeriodChange = (option: TypePeriod) => {
    if (option) {
      setPeriod(option);
    }
  };

  const handleSendClick = () => {
    loadData();
    return;
  };

  useEffect(() => {
    setPeriod(periodOptions?.[0]);
  }, [periodOptions, setPeriod]);

  useEffect(() => {
    if (newFilter) {
      clearInputs([inputsConfig.target.name, inputsConfig.period.name]);
      setTarget(undefined);
      setPeriod(undefined);

      if (setNewFilter) setNewFilter(false);
    }
  }, [newFilter, setNewFilter, setPeriod, inputsConfig]);

  const isCurrentYearSelected = () => {
    return `${new Date().getFullYear()}` === `${period?.label}`;
  };

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
      sx={{
        width: '100%',
        paddingLeft: '1%',
        paddingRight: '1%',
        marginBottom: '-30px'
      }}
    >
      <Typography variant="h6">Filtros</Typography>
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'nowrap'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Target"
          name={inputsConfig.target?.name}
          data-testid="target"
          disabled={audience?.payTv?.targets.loading}
          loading={audience?.payTv?.targets.loading}
          ListboxProps={listboxSigleProps}
          options={targetsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={targetsOptions.find(
            (option) => option.value === inputsConfig.target.defaultValue
          )}
          onChange={handleTargetChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Período"
          name={inputsConfig.period?.name}
          data-testid="period"
          disabled={
            audience?.payTv?.periods.loading ||
            audience?.payTv?.periods.count === 0
          }
          loading={audience?.payTv?.periods.loading}
          ListboxProps={listboxSigleProps}
          options={periodOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={periodOptions[0]}
          onChange={handlePeriodChange}
          sx={{
            width: '20%'
          }}
        />
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          marginTop={1}
          sx={{
            marginLeft: 'auto'
          }}
        >
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--primary',
              loading: 'competitive-filter__buttons--loading',
              tagManager: classesGTM.audience.filter
            }}
            loading={false}
            onClick={handleSendClick}
            title="Filtrar"
          />
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--secondary',
              tagManager: classesGTM.audience.clearFilter
            }}
            disabled={false}
            onClick={() => formRef.current?.reset() || setReady(false)}
            title="Limpar Filtros"
          />
        </Grid>
      </FormContainer>
      {isCurrentYearSelected() && (
        <Box sx={{ marginTop: '60px', width: '100%' }}>
          <AlertInfoCard text="O período selecionado exibe dados acumulados do ano até o último mês completo." />
        </Box>
      )}
    </Grid>
  );
};

export default AudienceByPayTvParticipationGeralFilter;
