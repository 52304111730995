import { toast } from 'react-toastify';
import {
  ImpressionAction,
  ImpressionsResponse,
  ImpressionsTypes
} from './Impressions.types';
import { IGroup } from '../Groups/Groups.types';
import { AppThunk } from '../../..';
import getImpressions from '../../../../services/MediaSimulator/Digital/Impressions.service';

const LoadImpressionSuccess = (response: ImpressionsResponse) => {
  const payload: ImpressionAction = {
    type: ImpressionsTypes.LOAD_IMPRESSIONS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadImpressionError = (error: string) => {
  const payload: ImpressionAction = {
    type: ImpressionsTypes.LOAD_IMPRESSIONS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingImpressions = () => ({
  type: ImpressionsTypes.LOAD_IMPRESSIONS_REQUEST
});

export const ResetImpressions = () => ({
  type: ImpressionsTypes.RESET_IMPRESSIONS
});

export const LoadImpressionRequest =
  ({
    startDate,
    endDate,
    groups
  }: {
    startDate: string;
    endDate: string;
    groups: IGroup[];
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingImpressions());
      const response = await getImpressions(startDate, endDate, groups);

      dispatch(LoadImpressionSuccess(response));
    } catch (error) {
      dispatch(
        LoadImpressionError(error instanceof Error ? error.message : '')
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar as impressões disponíveis'
      );
    }
  };
