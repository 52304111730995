import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks';
import ExpandMore from '../../assets/images/expand-more.svg';
import Dots from '../../assets/images/dots.svg';
import LoadAgeRequest from '../../store/modules/Digital/Ages/Ages.actions';
import LoadLocationRequest from '../../store/modules/Digital/Locations/Locations.actions';
import LoadInterestRequest from '../../store/modules/Digital/Interests/Interests.actions';
import LoadGenderRequest from '../../store/modules/Digital/Genders/Genders.actions';

import './style.scss';
import useGroupSettings from './hooks/useGroupSettings';
import SegmentationFilters from '../Filters/DAI/segmentation';
import { IGroup } from '../../store/modules/Digital/Groups/Groups.types';
import PlusIcon from '../../assets/images/plus.svg';
import {
  AddGroup,
  SaveGroupsFormData
} from '../../store/modules/Digital/Groups/Groups.actions';
import { SetSimulation } from '../../store/modules/Simulation/Simulation.actions';
import classesGTM from '../../config/classesGTM';

interface ClassNames {
  accordion?: string;
  summary?: string;
  group?: string;
}
interface AuxProps {
  children?: (group: IGroup) => ReactNode;
  addGroupsOption?: boolean;
  className?: ClassNames;
}

const Group = ({ children, addGroupsOption, className }: AuxProps) => {
  const { DigitalGroups } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const [groups, setGroups] = useState(DigitalGroups.groups);
  const [openedAcordion, setOpenedAcordion] = useState<number | null>(
    groups[0].id || 1
  );

  const ref = useRef<any>(null);

  const {
    Settings,
    toggle,
    isRenaming,
    handleKeyPress,
    handleRenameChange,
    handleRenameCancel
  } = useGroupSettings();

  useEffect(() => {
    dispatch(LoadAgeRequest({}));
    dispatch(LoadLocationRequest());
    dispatch(LoadGenderRequest({}));
    dispatch(LoadInterestRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = useCallback(
    (evt: any) => {
      if (ref.current && !ref?.current?.contains(evt.target)) {
        handleRenameCancel();
      }
    },
    [handleRenameCancel]
  );

  const handleAddGroup = () => {
    dispatch(AddGroup());
  };

  const togleAccordion = (group: IGroup) => {
    const a = openedAcordion !== group.id ? group.id : null;
    setOpenedAcordion(a);
    dispatch(SaveGroupsFormData({ ...group, submited: false }));
  };
  const validPlanning = (group: IGroup) => {
    if (group.planningSubmited === true && group.planningValidated === false) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    setGroups(DigitalGroups.groups);
    SetSimulation({
      groups: DigitalGroups.groups
    });
  }, [DigitalGroups.groups]);

  return (
    <>
      <div className="d-flex flex-column gy-2">
        {groups.map((g: IGroup) => (
          <div className={className?.group} key={g.id}>
            <Accordion
              className={className?.accordion}
              expanded={
                openedAcordion === g.id ||
                (g.submited && !g.validated) ||
                validPlanning(g)
              }
            >
              <AccordionSummary
                expandIcon={<img src={ExpandMore} alt="Icone de expandir" />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={className?.summary}
                onClick={() => togleAccordion(g)}
              >
                {isRenaming === g.id ? (
                  <TextField
                    ref={ref}
                    variant="standard"
                    defaultValue={g.name}
                    autoFocus
                    onChange={(e) => handleRenameChange(e.target.value)}
                    onKeyDown={(e) => handleKeyPress(e, g)}
                    className={`${className?.summary}__details ${className?.summary}__title`}
                    InputProps={{
                      sx: {
                        textAlign: 'center'
                      },
                      inputProps: {
                        style: { textAlign: 'center' }
                      }
                    }}
                  />
                ) : (
                  <div
                    className={`${className?.summary}__details ${className?.summary}__title`}
                    role="presentation"
                  >
                    {g.name}
                  </div>
                )}
              </AccordionSummary>
              <AccordionDetails
                className={`${className?.accordion}__details`}
                sx={{ p: '24px' }}
              >
                {children ? children(g) : <SegmentationFilters group={g} />}
              </AccordionDetails>
            </Accordion>
            <button
              className="group__options"
              onClick={() => toggle(g.id)}
              type="button"
            >
              <img src={Dots} alt="Botões editar" />
              {Settings(g)}
            </button>
          </div>
        ))}
      </div>
      {addGroupsOption && groups.length <= 2 && (
        <Button
          className={`buttonAdd ${classesGTM.simulation.digital.novoGrupoSegmentacao}`}
          onClick={() => handleAddGroup()}
        >
          <img src={PlusIcon} className="iconAdd" alt="Botão adicionar Grupo" />
          Novo grupo de segmentação
        </Button>
      )}
    </>
  );
};

Group.defaultProps = {
  addGroupsOption: true,
  className: {
    accordion: 'groupAccordion',
    summary: 'groupAccordion__summary',
    group: 'group'
  },
  children: null
};

export default Group;
