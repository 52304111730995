import { useCallback, useMemo, useRef, useState } from 'react';
import Badge from '@mui/material/Badge';
import { useLocation, useNavigate } from 'react-router-dom';
import omitProps from 'lodash/omit';
import IconLanderboard from '../../assets/images/landerboard-gradient.svg';
import IconFilter from '../../assets/images/filters.svg';
import IconCalendar from '../../assets/images/calendar.svg';
import IconSummary from '../../assets/images/summary.svg';
import { ItemProps } from '../Menu/Nav/Item/types';
import { getUuid } from '../../helpers/UUID';
import paths from '../../routes/paths';
import {
  SaveSceneryPath,
  SetSimulation
} from '../../store/modules/Simulation/Simulation.actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import getJson from '../../services/Vault/Vault.service';
import { Simulation } from '../../store/modules/Simulations/Simulations.types';
import { SimulationScenery } from '../../store/modules/Simulation/Simulation.types';
import { filtersLocalStorage } from '../Filters';
import scenariosLocalStorage from '../Scenarios/Scenarios.LocalStorage';
import usePlanningRedux from '../Planning/usePlanningRedux';
import { useScenarios } from '../Scenarios';
import classesGTM from '../../config';
import { SaveSimulation } from '../../store/modules/Simulations/Simulations.actions';
import { SaveGroups } from '../../store/modules/Digital/Groups/Groups.actions';

const useSimulation = () => {
  const { filters, simulation } = useAppSelector((state) => state);

  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [progressDownload, setProgressDownload] = useState(0);
  const [finished, setFinished] = useState(false);
  const [errorLoad, setErrorLoad] = useState(false);

  const dispatch = useAppDispatch();

  const planning = usePlanningRedux().currentPlanning;

  const { setFiltersAndPlanningOnScenery } = useScenarios();

  const location = useLocation();

  const { path, childrens } = paths.home.childrens.simulation;

  const { path: digitalPath, childrens: digitalChildrens } =
    paths.home.childrens.digitalSimulation;

  const scenariosStore = useRef(scenariosLocalStorage());

  const navigate = useNavigate();

  const updateProgress = (event: ProgressEvent) => {
    const total = event.total || event.loaded;
    const progress = Math.round(event.loaded / total) * 100;
    setProgressDownload(progress);
  };

  enum Enum_Types_Filter {
    default = 1,
    dai = 2
  }

  const DEFAULT_PATH_FILTERS = `${path}/0/${childrens.filters}`;
  const DAI_PATH_FILTERS = `${digitalPath}/${digitalChildrens.segment}`;

  const loadSimulation = useCallback(
    async (row: Simulation) => {
      dispatch(SetSimulation({ loading: true }));
      setOpenModalInfo(true);
      setErrorLoad(false);
      try {
        const simulationData = await getJson(row.dataUrl, updateProgress);

        scenariosStore.current.currentScenery.remove();
        scenariosStore.current.currentSceneryId.remove();
        scenariosStore.current.scenarios.remove();

        const scenarios = simulationData?.scenarios?.reduce<
          SimulationScenery[]
        >((accScenarios, sceneryId) => {
          accScenarios.push({
            ...omitProps(sceneryId, ['filters', 'planning']),
            lastPath: childrens.filters
          });

          const filtersStore = filtersLocalStorage(sceneryId?.uuid);
          filtersStore.remove();

          return accScenarios;
        }, []);

        const groups = simulationData?.groups?.reduce<any[]>(
          (accGroups, groupId) => {
            accGroups.push({
              ...omitProps(groupId, ['filters', 'planning']),
              lastPath: childrens.filters
            });

            const filtersStore = filtersLocalStorage(groupId?.uuid);
            filtersStore.remove();

            return accGroups;
          },
          []
        );

        const scenariosPromise = simulationData?.scenarios?.map(
          setFiltersAndPlanningOnScenery
        );

        scenariosPromise && (await Promise.all(scenariosPromise));

        /*         const groupsPromise = simulationData?.groups?.map(
          setFiltersAndPlanningOnGroup
        );

        groupsPromise && (await Promise.all(groupsPromise));
 */

        dispatch(
          SetSimulation({
            ...row,
            scenarios,
            currentScenery: 0,
            currentSceneryId: scenarios?.[0]?.uuid,
            loading: false,
            groups
          })
        );

        if (groups && groups?.length && groups?.length >= 1) {
          dispatch(SaveGroups(groups));
        }

        if (simulationData?.DigitalDates) {
          dispatch(
            SetSimulation({
              ...simulation,
              DigitalDates: simulationData?.DigitalDates
            })
          );
        }

        setFinished(true);
        setTimeout(() => {
          setOpenModalInfo(false);

          if (row.simulationTypeId === Enum_Types_Filter.dai) {
            navigate(DAI_PATH_FILTERS, {
              state: {
                typeTV: simulationData?.scenarios?.[0]?.filters?.typeTV
              }
            });
          } else {
            navigate(DEFAULT_PATH_FILTERS, {
              state: {
                typeTV: simulationData?.scenarios?.[0]?.filters?.typeTV
              }
            });
          }
        }, 500);
      } catch {
        setErrorLoad(true);
        setTimeout(() => {
          setOpenModalInfo(false);
        }, 2000);
      }
    },
    [
      dispatch,
      simulation,
      setFiltersAndPlanningOnScenery,
      childrens.filters,
      Enum_Types_Filter.dai,
      navigate,
      DAI_PATH_FILTERS,
      DEFAULT_PATH_FILTERS
    ]
  );

  const duplicateSimulation = useCallback(
    async (row: Simulation, nameSimulation: string) => {
      dispatch(SetSimulation({ loading: true }));
      setOpenModalInfo(true);
      setErrorLoad(false);
      try {
        const simulationData = await getJson(row.dataUrl, updateProgress);

        scenariosStore.current.currentScenery.remove();
        scenariosStore.current.currentSceneryId.remove();
        scenariosStore.current.scenarios.remove();

        setTimeout(() => {
          setOpenModalInfo(false);

          if (row.simulationTypeId === Enum_Types_Filter.default) {
            const copiedSimulation = {
              ...simulationData,
              uuid: getUuid(),
              name: nameSimulation ? `${nameSimulation}` : `${row.name}_Copy`,
              simulation_type_id: row.simulationTypeId,
              scenarios: simulationData?.scenarios?.map((scenery) => ({
                ...scenery,
                uuid: getUuid()
              }))
            };

            dispatch(SaveSimulation(copiedSimulation, true));
          } else {
            const copiedSimulation = {
              ...simulationData,
              uuid: getUuid(),
              name: nameSimulation ? `${nameSimulation}` : `${row.name}_Copy`,
              simulation_type_id: row.simulationTypeId,
              groups: simulationData?.groups?.map((group) => ({
                ...group,
                uuid: getUuid()
              }))
            };

            dispatch(SaveSimulation(copiedSimulation, false, true));
          }
        }, 10);

        setFinished(true);
      } catch {
        setErrorLoad(true);
        setTimeout(() => {
          setOpenModalInfo(false);
        }, 2000);
      }
    },
    [dispatch, Enum_Types_Filter.default]
  );

  const currentFilters = useMemo(
    () =>
      filters.forms.find((f) => f.sceneryId === simulation.currentSceneryId),
    [filters.forms, simulation.currentSceneryId]
  );

  const hasInsertion = useMemo(() => {
    return !!planning.planningPrograms.filter((p) => p.insertions > 0).length;
  }, [planning.planningPrograms]);

  const items = useMemo(
    (): Readonly<ItemProps>[] => [
      {
        itemId: 0,
        icon: IconFilter,
        title: 'Filtros',
        path: `${path}/${simulation.currentScenery}/${childrens.filters}`,
        stateRoute: { typeTV: currentFilters?.data?.typeTV },
        onClick: () => dispatch(SaveSceneryPath(childrens.filters)),
        selected: true,
        visibled: true,
        disabled: false,
        classes: { tagManager: classesGTM.tabs.filters }
      },
      {
        itemId: 1,
        icon: IconLanderboard,
        title: 'Ranking',
        path: `${path}/${simulation.currentScenery}/${childrens.ranking}`,
        onClick: () => dispatch(SaveSceneryPath(childrens.ranking)),
        selected: false,
        visibled: true,
        disabled: currentFilters?.error || !currentFilters?.data,
        classes: { tagManager: classesGTM.tabs.ranking }
      },
      {
        itemId: 2,
        icon: IconCalendar,
        title: 'Planejamento',
        path: `${path}/${simulation.currentScenery}/${childrens.planning}`,
        onClick: () => dispatch(SaveSceneryPath(childrens.planning)),
        disabled:
          !planning.planningPrograms.length &&
          location.pathname !== `${path}/${childrens.planning}`,
        selected: false,
        visibled: true,
        badge: (
          <Badge
            badgeContent={planning.planningPrograms.length}
            classes={{ badge: 'menu--badge no-pointer-events' }}
            className="main-menu__badge no-pointer-events"
            color="primary"
          />
        ),
        classes: { tagManager: classesGTM.tabs.planning }
      },
      {
        itemId: 3,
        icon: IconSummary,
        title: 'Resumo',
        path: `${path}/${simulation.currentScenery}/${childrens.summary}`,
        onClick: () => dispatch(SaveSceneryPath(childrens.summary)),
        selected: false,
        visibled: true,
        disabled: !planning.planningPrograms.length || !hasInsertion,
        classes: { tagManager: classesGTM.tabs.summary }
      }
    ],
    [
      planning.planningPrograms,
      childrens.filters,
      childrens.planning,
      childrens.ranking,
      childrens.summary,
      path,
      currentFilters,
      location.pathname,
      hasInsertion,
      simulation.currentScenery,
      dispatch
    ]
  );

  return {
    items,
    loadSimulation,
    duplicateSimulation,
    openModalInfo,
    progressDownload,
    finished,
    errorLoad
  };
};

export default useSimulation;
