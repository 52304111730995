import './Simulation.scss';
import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { formatRelative } from 'date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import { snakeCase } from 'lodash';
import pickProps from 'lodash/pick';
import hasProp from 'lodash/has';
import { toast } from 'react-toastify';
import SubHeader from '../SubHeader';
import SubHeaderSimulation from '../SubHeaderSimulation';
import ModalSimulation from './ModalSimulation';
import ModalRedirectToAds from './ModalRedirectToAds';
import paths from '../../routes/paths';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useFilters from '../Filters/useFilters';
import ContainerWrapper from '../ContainerWrapper';
import MenuNav from '../Menu';
import { SceneryPanel, useScenarios } from '../Scenarios';
import useSimulation from './useSimulation';
import { useRankingRedux } from '../Ranking';
import BottomBar from '../BottomBar';
import {
  SaveSimulation,
  UpdateSimulation
} from '../../store/modules/Simulations/Simulations.actions';
import {
  SimulationData,
  SimulationDataScenery
} from '../../store/modules/Simulation/Simulation.types';
import simualtionLocalStorage from './Simulation.LocalStorage';
import { Simulation } from '../../store/modules/Simulations/Simulations.types';
import ModalLoadSimulation from './ModalLoadSimulation';
import LoadFormatsRequest from '../../store/modules/Formats/Formats.actions';
import LoadSecondaryRequest from '../../store/modules/Secondaries/Secondaries.actions';
import Univers from '../UniversInfo/Univers';
import classesGTM from '../../config';
import { TypeTV } from '../../services/shared/Api.service';
import { SavePartialFormData } from '../../store/modules/Filters/Filters.actions';
import { exportToGAds } from '../../helpers/Export';
import { removeSpecialChars } from '../../helpers/Utils';
import usePlanningGAdsPvi from '../Planning/usePlanningGAdsPvi';
import { Roles } from '../../store/modules/Users/Users.types';

const SimulationWrapper = () => {
  const {
    items,
    loadSimulation,
    openModalInfo,
    progressDownload,
    finished,
    errorLoad
  } = useSimulation();
  const { channels, markets, targets } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const programs = useRankingRedux().currentPrograms;
  const { getCurrentFilters, submitCurrentFiltersForm } = useFilters();
  const { transformPlanning } = usePlanningGAdsPvi();
  const { getCopyScenery } = useScenarios();
  const { simulation, simulations, auth } = useAppSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const [openAdsModal, setOpenAdsModal] = useState(false);
  const [readyToAutoSave, setReadyToAutoSave] = useState(false);
  const [previousSimulation, setPreviousSimulation] = useState<string>(
    JSON.stringify(
      simulation?.scenarios?.reduce<SimulationData>(
        (scenariosData, scenery) => {
          scenariosData?.scenarios?.push(getCopyScenery(scenery));

          return scenariosData;
        },
        { scenarios: [], name: simulation?.name, uuid: simulation?.uuid }
      )
    )
  );
  const { path, childrens } = paths.home.childrens.simulation;

  const dispatch = useAppDispatch();
  const simulationStore = useRef(simualtionLocalStorage());

  const handleClickInitSimulation = () => {
    setOpenModal(false);
  };

  // essa função verifica se o completeSimulation foi totalmente carregado, pois quando o formulario é montado pela primeira vez, ele é carregado com valores vazios
  const shouldUpdateSimulation = useCallback(
    (completeSimulation: SimulationData): boolean => {
      return completeSimulation.scenarios.every((scenario, index) => {
        const previousScenario = JSON.parse(previousSimulation).scenarios[
          index
        ] as SimulationDataScenery;

        return !(
          (scenario?.filters?.typeTV === ('' as TypeTV) &&
            ((previousScenario?.filters?.typeTV !== undefined &&
              previousScenario?.filters?.typeTV !== ('' as TypeTV)) ||
              previousScenario?.filters?.typeTV === undefined)) ||
          (scenario?.filters?.targets?.length === 0 &&
            previousScenario?.filters?.targets !== undefined &&
            previousScenario?.filters?.targets?.length !== 0) ||
          scenario?.filters === undefined
        );
      });
    },
    [previousSimulation]
  );

  useEffect(() => {
    const completeSimulation = simulation.scenarios.reduce<SimulationData>(
      (scenariosData, scenery) => {
        scenariosData.scenarios.push(getCopyScenery(scenery));

        return scenariosData;
      },
      { scenarios: [], name: simulation.name, uuid: simulation.uuid }
    );

    if (
      JSON.stringify(completeSimulation) !== previousSimulation &&
      shouldUpdateSimulation(completeSimulation) &&
      !channels?.closedTv?.loading &&
      !channels?.openedTv?.loading &&
      (channels?.openedTv?.data?.length > 0 ||
        channels?.closedTv?.data?.length > 0) &&
      !markets?.closedTv?.loading &&
      !markets?.openedTv?.loading &&
      (markets?.openedTv?.data?.length > 0 ||
        markets?.closedTv?.data?.length > 0) &&
      !targets?.closedTv?.loading &&
      !targets?.openedTv?.loading &&
      (targets?.openedTv?.data?.length > 0 ||
        targets?.closedTv?.data?.length > 0) &&
      readyToAutoSave &&
      simulation.id
    ) {
      setPreviousSimulation(JSON.stringify(completeSimulation));
      dispatch(UpdateSimulation(completeSimulation, simulation.id, true));
      dispatch(
        SavePartialFormData(
          simulation?.currentSceneryId,
          completeSimulation?.scenarios?.find(
            (scenery) => scenery.uuid === simulation.currentSceneryId
          )?.filters
        )
      );
    }
  }, [
    simulation,
    dispatch,
    getCopyScenery,
    previousSimulation,
    shouldUpdateSimulation,
    channels?.closedTv?.data?.length,
    channels?.closedTv?.loading,
    channels?.openedTv?.data?.length,
    channels?.openedTv?.loading,
    markets?.closedTv?.data?.length,
    markets?.closedTv?.loading,
    markets?.openedTv?.data?.length,
    markets?.openedTv?.loading,
    readyToAutoSave,
    targets?.closedTv?.data?.length,
    targets?.closedTv?.loading,
    targets?.openedTv?.data?.length,
    targets?.openedTv?.loading
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReadyToAutoSave(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  const pathFilters = useMemo(() => {
    return `${path}/${simulation.currentScenery}/${childrens.filters}`;
  }, [simulation.currentScenery, childrens.filters, path]);

  const pathPlanning = useMemo(() => {
    return `${path}/${simulation.currentScenery}/${childrens.planning}`;
  }, [simulation.currentScenery, childrens.planning, path]);

  useEffect(() => {
    if (auth.loggedIn) {
      dispatch(LoadFormatsRequest());
      dispatch(LoadSecondaryRequest());
    }
  }, [dispatch, auth.loggedIn]);

  const isLoadingSimulation = useRef(false);
  useEffect(() => {
    async function load() {
      const props = [
        'id',
        'uuid',
        'name',
        'dataUrl',
        'fileName',
        'userCreatedId',
        'userUpdatedId',
        'createdAt',
        'updatedAt'
      ];
      const simulationProps = pickProps(
        simulationStore.current.get(),
        props
      ) as Simulation;

      if (props.some((prop) => hasProp(simulationProps, prop))) {
        await loadSimulation(simulationProps);
      }
      isLoadingSimulation.current = false;
    }

    if (
      simulationStore.current.get()?.id &&
      !simulation?.id &&
      !isLoadingSimulation.current
    ) {
      isLoadingSimulation.current = true;
      load();
    }
  }, [loadSimulation, simulation, pathFilters, navigate]);

  // useEffect(
  //   () => {
  //     const currentFilters = getCurrentFilters();
  //     if (
  //       (currentFilters?.error || !currentFilters?.data) &&
  //       simulation.uuid &&
  //       location.pathname !== pathFilters
  //     ) {
  //       const typeTV =
  //         currentFilters?.data?.typeTV || currentFilters?.partialData?.typeTV;

  //       navigate(pathFilters, {
  //         state: typeTV ? { typeTV } : null
  //       });
  //     }
  //   },
  //   [
  //     // navigate
  //     // simulation.uuid
  //     // getCurrentFilters,
  //     // location.pathname
  //     // pathFilters
  //   ]
  // );

  const handleClick = (id: number) => {
    const item = items.find((el) => el.itemId === id);

    if (item && item.onClick) {
      item.onClick();
    }
  };

  const handleExportPvi = () => {
    const pvi = transformPlanning(
      simulation.name,
      simulation?.me_filial_agencia,
      simulation?.nu_cgc_cpf_agencia,
      String(simulation?.oi_agencia),
      String(simulation?.oi_cliente),
      simulation?.me_cliente,
      simulation?.cpf_cnpj_cliente
    );

    if (!pvi?.length) {
      toast.error(
        'É necessário adicionar ao menos uma inserção para exportar o arquivo.'
      );
      return;
    }

    exportToGAds(removeSpecialChars(snakeCase(simulation.name)), pvi)
      .then(() => {
        setOpenAdsModal(true);
      })
      .catch(() => {
        toast.error('Erro ao exportar o arquivo.');
      });
  };

  const handleSaveOrUpdateSimulation = () => {
    const data = simulation.scenarios.reduce<SimulationData>(
      (scenariosData, scenery) => {
        scenariosData.scenarios.push(getCopyScenery(scenery));

        return scenariosData;
      },
      { scenarios: [], name: simulation.name, uuid: simulation.uuid }
    );

    // UPDATE
    if (simulation?.id) {
      dispatch(UpdateSimulation(data, simulation.id));
      return;
    }
    // SAVE
    dispatch(SaveSimulation(data, false));
  };

  const saveOrUpdateInfo = useMemo(() => {
    if (simulation?.updatedAt) {
      const date =
        simulation.updatedAt instanceof Date
          ? simulation.updatedAt
          : new Date(simulation.updatedAt);

      return simulation.updatedAt
        ? `salvo ${formatRelative(date, new Date(), { locale: brLocale })}`
        : '';
    }
    return '';
  }, [simulation]);

  return (
    <>
      <ModalLoadSimulation
        error={errorLoad}
        finished={finished}
        open={openModalInfo}
        progressDownload={progressDownload}
      />
      <ModalSimulation
        dataModal={simulation}
        onClickCancel={() => setOpenModal(false)}
        onClickInitSimulation={handleClickInitSimulation}
        open={openModal}
      />
      <ModalRedirectToAds
        onClickCancel={() => setOpenAdsModal(false)}
        onClickOk={() => {
          setOpenAdsModal(false);
          window.open(`${process.env.REACT_APP_GLOBOADS_URL}/upload`, '_self');
        }}
        open={openAdsModal}
      />
      <SubHeader>
        <SubHeaderSimulation
          onClickEdit={() => setOpenModal(true)}
          title={simulation?.name || 'Novo Planejamento'}
        />
      </SubHeader>
      {simulation?.scenarios &&
        simulation?.scenarios?.length > 0 &&
        simulation?.scenarios?.map((scenery) => (
          <SceneryPanel
            key={scenery.uuid}
            index={scenery.sceneryNumber}
            value={simulation.currentScenery}
          >
            <ContainerWrapper>
              <div className="top-content" />
              <div className="content-divisor">
                <div className="table-content">
                  <MenuNav
                    classes={{
                      nav: 'sub-menu mb-4',
                      item: {
                        li: 'sub-menu__item',
                        selected: 'sub-menu--selected',
                        icon: 'sub-menu__icon',
                        button: 'sub-menu__item__button'
                      }
                    }}
                    itemsMenu={items}
                    onClick={handleClick}
                  />
                  <Outlet />
                </div>
              </div>
            </ContainerWrapper>
          </SceneryPanel>
        ))}
      <Univers />
      <BottomBar
        buttons={[
          {
            id: 1,
            title: 'buscar e rankear',
            disabled: getCurrentFilters()?.error,
            loading: programs.loading,
            info: getCurrentFilters()?.error
              ? 'Existem campos obrigatórios não preenchidos ou inválidos.'
              : '',
            alwaysOpenInfo: true,
            classes: {
              button: 'btn--blue',
              loading: 'plim-white',
              tagManager: classesGTM.filters.searchAndRank
            },
            visible: location.pathname === pathFilters,
            onClick: submitCurrentFiltersForm
          },
          {
            id: 2,
            classes: {
              button: 'mx-2',
              loading: 'plim-gray5',
              tagManager:
                classesGTM.simulation[simulation?.id ? 'update' : 'save']
            },
            title:
              simulation?.id &&
              simulation?.dataUrl &&
              simulation?.dataUrl !== simulation?.draftUrl
                ? 'atualizar'
                : 'salvar',
            loading: simulation?.mustBeLoading && simulations.loading,
            info: saveOrUpdateInfo,
            visible: true,
            onClick: handleSaveOrUpdateSimulation
          },
          {
            id: 3,
            title: 'exportar mapa para compra',
            disabled: !simulation?.cpf_cnpj_cliente || !simulation?.me_cliente,
            loading: false,
            info:
              !simulation?.cpf_cnpj_cliente || !simulation?.me_cliente
                ? 'Para exportar o Mapa para Compra é obrigatório fornecer os dados do anunciante e o CNPJ.'
                : '',
            infoVisibleOnHover: true,
            classes: {
              button: 'btn--blue btn--full',
              loading: 'plim-white',
              tagManager: classesGTM.simulation.export
            },
            visible:
              location.pathname === pathPlanning &&
              auth?.profile?.role === Roles.AGENCY,
            onClick: handleExportPvi
          }
        ]}
      />
    </>
  );
};

export default SimulationWrapper;
