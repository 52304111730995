import { toast } from 'react-toastify';
import { GenderAction, GendersResponse, GendersTypes } from './Genders.types';
import getGenders from '../../../../services/MediaSimulator/Digital/Genders.service';
import { AppThunk } from '../../..';

const LoadGenderSuccess = (response: GendersResponse) => {
  const payload: GenderAction = {
    type: GendersTypes.LOAD_GENDERS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadGenderError = (error: string) => {
  const payload: GenderAction = {
    type: GendersTypes.LOAD_GENDERS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingGenders = () => ({
  type: GendersTypes.LOAD_GENDERS_REQUEST
});

const LoadGenderRequest =
  (props: any): AppThunk =>
  async (dispatch: (arg0: { type: any }) => void) => {
    try {
      dispatch(LoadingGenders());
      const response = await getGenders({ ...props });
      dispatch(LoadGenderSuccess(response));
    } catch (error) {
      dispatch(LoadGenderError(error instanceof Error ? error.message : ''));
      toast.error('Ops, houve um problema ao tentar carregar as Generos.');
    }
  };

export default LoadGenderRequest;
