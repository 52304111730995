import { toast } from 'react-toastify';
import { TargetAction, TargetsResponse, TargetsTypes } from './Targets.types';
import getTargets from '../../../services/MediaSimulator/Targets.service';
import { TypeTV } from '../../../services/shared/Api.service';
import { AppThunk } from '../..';

const LoadTargetSuccess = (
  response: TargetsResponse,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: TargetAction = {
    type: TargetsTypes.LOAD_TARGETS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg,
      typeTV
    }
  };

  return payload;
};

const LoadTargetError = (
  error: string,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: TargetAction = {
    type: TargetsTypes.LOAD_TARGETS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error,
      typeTV
    }
  };

  return payload;
};

const LoadingTargets = (typeTV: TypeTV.OPEN | TypeTV.CLOSED) => ({
  type: TargetsTypes.LOAD_TARGETS_REQUEST,
  payload: { typeTV }
});

const LoadTargetRequest =
  (typeTV: TypeTV.OPEN | TypeTV.CLOSED): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingTargets(typeTV));

      const response = await getTargets(typeTV);

      dispatch(LoadTargetSuccess(response, typeTV));
    } catch (error) {
      dispatch(
        LoadTargetError(error instanceof Error ? error.message : '', typeTV)
      );
      toast.error('Ops, houve um problema ao tentar carregar os targets.');
    }
  };

export default LoadTargetRequest;
